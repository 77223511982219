import { call, delay, put, takeLatest } from 'redux-saga/effects'
import { FilterCondition, groupActions } from './groupSlice'
import organizationApi from 'src/api/organizationApi'
import { PayloadAction } from '@reduxjs/toolkit'

function* fetchMyGroup(action: PayloadAction<FilterCondition>): any {
    try {
        const response = yield call(organizationApi.getGroups, action.payload)
        yield put(groupActions.fetchMyGroupSuccess(response))

    } catch (err) {
        console.log('[groupSaga.ts] Fetch group failed: ', err)
    }
}

function* createMyGroup(action: any): any {
    try {
        const data = {
            ...action.payload,
        }
        const response = yield call(organizationApi.createMyGroup, data)
        if (response.success === false) {
            console.log('[groupSaga.ts] Create group failed: ', response.message)
            yield put(groupActions.createGroupFailed())

            // hide alert after 3 seconds
            yield delay(3000)
            yield put(groupActions.cancelHideAlert())
        } else {
            yield put(groupActions.createGroupSuccess(response))

            // hide alert after 4 seconds
            yield delay(4000)
            yield put(groupActions.setMyGroupNewUpdate())
        }
    } catch (err) {
        console.log('[groupSaga.ts] Create group failed: ', err)
        yield put(groupActions.createGroupFailed())

        // hide alert after 3 seconds
        yield delay(3000)
        yield put(groupActions.cancelHideAlert())
    }
}

function* updateMyGroup(action: any): any {
    try {
        const data = {
            name: action.payload.name,
            user_ids: action.payload.user_ids
        }
        const groupId = action.payload.id;

        const response = yield call(organizationApi.updateGroup, groupId, data)
        if (response.success === false) {
            console.log('[groupSaga.ts] Update group failed: ', response.message)
            yield put(groupActions.updateMyGroupFailed())

            // hide alert after 3 seconds
            yield delay(3000)
            yield put(groupActions.cancelHideAlert())
        } else {
            yield put(groupActions.updateMyGroupSuccess(response))

            // hide alert after 4 seconds
            yield delay(4000)
            yield put(groupActions.setMyGroupNewUpdate())
        }
    } catch (err) {
        console.log('[groupSaga.ts] update group failed: ', err)
        yield put(groupActions.updateMyGroupFailed())

        // hide alert after 3 seconds
        yield delay(3000)
        yield put(groupActions.cancelHideAlert())
    }
}

function* fetchGroupById(action: any): any {
    try {        
        const gid = action.payload

        const response = yield call(organizationApi.getById, gid)
        yield put(groupActions.fetchGroupByIdSuccess(response))
    } catch (err) {
        console.log('[groupSaga.ts] Fetch group for edit failed: ', err)
    }
}

export default function* groupSaga() {
    yield takeLatest(groupActions.fetchMyGroup, fetchMyGroup)
    yield takeLatest(groupActions.createMyGroup, createMyGroup)
    yield takeLatest(groupActions.updateMyGroup, updateMyGroup)
    yield takeLatest(groupActions.fetchGroupById, fetchGroupById)
}
