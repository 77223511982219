import * as React from 'react'

const ChevronDownIcon = (props: any) => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.63086 4.5127L6.00586 7.88769L9.38086 4.5127"
                stroke="#A0AEC0"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
                {...props}
            />
        </svg>
    )
}

export default ChevronDownIcon
