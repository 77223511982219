import React, { createContext, useContext, useEffect, useState } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Slide, Snackbar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

type SnackBarContextActions = {
  showSnackBar: (isOpen: boolean, text: string, typeColor: any) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [typeColor, setTypeColor] = React.useState<any>('');

  const showSnackBar = (isOpen: boolean, text: string, color: any) => {
    setMessage(text);
    setTypeColor(color);
    setOpen(isOpen);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const listIcon = (typeColor: any) => {
    switch (typeColor) {
      case 'success':
        return <CheckCircleIcon sx={{color: '#01B574'}} fontSize="inherit"  />;
      case 'error':
        return <ErrorIcon sx={{color: '#E31A1A'}} fontSize="inherit" />;
      case 'warning':
        return <WarningIcon sx={{color: '#FFB547'}} fontSize="inherit" />;
      case 'info':
        return <InfoIcon sx={{color: '#2A69AC'}} fontSize="inherit" />;
      default:
        return <InfoIcon sx={{color: '#2A69AC'}} fontSize="inherit" />;
    }
  };

  const listAlertColor = (typeColor: any) => {
    switch (typeColor) {
      case 'success':
        return '#C9FBD5';
      case 'error':
        return '#FED7D7';
      case 'warning':
        return '#FFF6DA';
      case 'info':
        return '#CEEDFF';
      default:
        return '#CEEDFF';
    }
  };

  const listTitle = (typeColor: any) => {
    switch (typeColor) {
      case 'success':
        return '成功';
      case 'error':
        return 'エラー';
      case 'warning':
        return '警告';
      case 'info':
        return '情報';
      default:
        return '情報';
    }
  };

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Snackbar
        open={open}
        autoHideDuration={5300}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleClose}
        TransitionComponent={Slide}
      >
        <Alert
          icon={listIcon(typeColor)}
          sx={{
            width: '100%',
            bgcolor: listAlertColor(typeColor),
            color: '#000000',
            animation: 'fadeinout 5.5s',
          }}
          onClose={handleClose}
          severity={typeColor}
        >
          <strong>{listTitle(typeColor)}</strong> <br />
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
};

const useSnackBar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error('useSnackBar must be used within an SnackBarProvider');
  }

  return context;
};

export { SnackBarProvider, useSnackBar };