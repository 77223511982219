import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormHelperText,
    Typography,
    Stack,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import externalUserApi from 'src/api/externalUserApi'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import LightFieldByPoint from 'src/components/Common/LightFieldPoint'
import { TextInput } from 'src/components/TextInput'
import { CONSTANTS } from 'src/config/constants'
import { labelOfInput, tableBodyText, tableRowAlign, tableHeaderText } from 'src/theme'
import ScheduleConfirmFooter from 'src/components/Common/ScheduleConfirmFooter'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import { DyskeLogo, GPLogo, NewDyskeLogo } from 'src/components/Icons'
import { LoadingButton } from '@mui/lab'
import scheduleApi from 'src/api/scheduleApi'
import adjustmentApi from 'src/api/adjustmentApi'
import { limitCharacter } from '../../helpers/string'
import { replaceGaroonName } from '../../helpers/helpers'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import ScrollHint from 'scroll-hint'

type adjustmentType = {
    id: number
    schedule_id: number
    start_time: string
    duration: number
    adjustmentParticipant?: any
}

type adjustmentListType = {
    adjustment_id: number
    status: 0 | 3 | 5
}

const ScheduleConfirm = () => {
    const navigate = useNavigate()

    const { hash } = useParams()

    const [subject, setSubject] = useState<string>('')
    const [adjustments, setAdjustments] = useState<any>([])
    const [adjustmentList, setAdjustmentList] = useState<adjustmentListType[]>([])
    const [participantId, setParticipantId] = useState<number>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isExpired, setIsExpired] = useState<boolean>(false)
    const [scheduleId, setScheduleId] = useState<number>()
    const [scheduleData, setScheduleData] = useState<any>(null)
    const { showSnackBar } = useSnackBar()
    const { height, width } = useWindowDimensions();
    const [isInvalidSchedule, setIsInvalidSchedule] = useState<boolean>(false)
    const tableRef = useRef<HTMLTableElement>(null);
    const [isSharedUrl, setIsSharedUrl] = useState<boolean>(false)

    const schema = yup.object({
        name: yup.string().required('必ず入力してください。').max(50, '50文字以内で入力してください'),
        comment: yup.string().max(500, '500文字以内で入力してください'),
        email: yup
        .string()
        .email('必ず入力してください。')
        .test('validate_email', '必ず入力してください。', function (value) {
            return !isSharedUrl || !!value;
        }),
    })
    const {
        control,
        handleSubmit,
        getValues,
        setError,
        setValue,
        reset,
        formState: { errors, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            comment: '',
            email: '',
        },
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        if (hash !== '' && hash !== undefined) {
            validateHash(hash)
        } else {
            setIsInvalidSchedule(true)
        }
    }, [hash])

    useLayoutEffect(() => {
        if (adjustments.length > 0) {
            let scrollAble = false;
            let currentTableWidth = tableRef.current?.offsetWidth ? tableRef.current?.offsetWidth : 0;

            if (width > 800) {
                if (width - ((width - 720) / 2 + 20) < currentTableWidth) {
                    scrollAble = true;
                }
            } else if (width > 599) {
                if (width - 60 < currentTableWidth) {
                    scrollAble = true;
                }
            } else {
                if (width - 20 < currentTableWidth) {
                    scrollAble = true;
                }
            }

            if (scrollAble) {
                new ScrollHint('.js-scrollable', {
                    i18n: {
                        scrollable: 'スクロールできます'
                    }
                })
            }
        }
    }, [adjustments, width])

    const validateHash = (hash: string) => {
        externalUserApi
            .validateHash(hash)
            .then((res: any) => {
                if (res?.success === false) {
                    setIsInvalidSchedule(true)
                    return;
                }
                const data = res.data
                if (
                    data.schedule.is_cancelled ||
                    data.schedule.status !== 'ADJUSTING' ||
                    data.schedule.adjustment_end_date < dayjs().add(3, 'hour').toISOString()
                ) {
                    setIsExpired(true);
                    return;
                }

                if (data.isSharedUrl) {
                    setIsSharedUrl(true)
                }
                setSubject(data.schedule.subject)
                if (data.participant?.id) {
                    setParticipantId(data.participant.id)
                }
                setScheduleData(data.schedule)
                setAdjustments(data.adjustment_garoon.candidate_time)
                reset({
                    name: data?.user?.name ?? '',
                    comment: data?.participant?.comment ?? '',
                })

                const adjm = data.adjustments
                let adjmArray: adjustmentListType[] = []
                if (adjm.length > 0) {
                    adjm.forEach((element: adjustmentType) => {
                        let status: 0 | 3 | 5 = 5
                        if (
                            element.adjustmentParticipant &&
                            element.adjustmentParticipant.length > 0 &&
                            data.participant?.id
                        ) {
                            let adjustmentParticipant =  element.adjustmentParticipant.find((el: any) => el.participant_id === data.participant.id)
                            if (adjustmentParticipant) {
                                status = adjustmentParticipant?.status === 0 ||
                                    adjustmentParticipant?.status === 3 ||
                                    adjustmentParticipant?.status === 5 ? adjustmentParticipant?.status : 5
                            }
                        }
                        adjmArray.push({ adjustment_id: element.id, status: status })
                    })
                }
                setAdjustmentList(adjmArray)
            })
            .catch((err: any) => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getStatus = (id: number) => {
        let adjm = adjustmentList.find((x) => x.adjustment_id === id)
        return adjm?.status
    }

    const selectStatus = (id: number, status: 0 | 3 | 5) => {
        let adjmList = [...adjustmentList]
        let index = adjmList.findIndex((x) => x.adjustment_id === id)
        adjmList[index].status = status
        setAdjustmentList(adjmList)
    }

    const statusList = [5, 3, 0]
    const confirm = (data: any) => {
        if (isSubmitting) {
            return
        }
        setIsSubmitting(true)
        const dataSubmit = {
            participant_id: participantId,
            adjustments: adjustmentList,
            name: data.name,
            comment: data.comment,
        }
        if (hash !== '' && hash !== undefined) {
            if (!isSharedUrl) {
                externalUserApi
                .confirm(hash, dataSubmit)
                .then((res: any) => {
                    setIsSubmitting(false)
                    if (res?.success === false) {
                        if(res.data && res.data?.status !== 'ADJUSTING') {
                            const message = res.data?.is_cancelled ? 'スケジュールがキャンセルされたため、回答を変更することはできません。' : '予定がすでに確定しているため、回答の変更はできません。';
                            showSnackBar(true, message, 'error')
                            return;
                        }

                        showSnackBar(true, `メールの送信に失敗しました`, 'error')
                        return;
                    }
                    setIsSuccess(true)
                })
                .catch((err: any) => {
                    setIsSubmitting(false)
                })
            } else {
                const input = {
                    adjustments: adjustmentList,
                    name: data.name,
                    comment: data.comment,
                    email: data.email,
                }

                externalUserApi
                .sharedUrlConfirm(hash, input)
                .then((res: any) => {
                    setIsSubmitting(false)
                    if (res?.success === false) {
                        if(res.data && res.data?.status !== 'ADJUSTING') {
                            const message = res.data?.is_cancelled ? 'スケジュールがキャンセルされたため、回答を変更することはできません。' : '予定がすでに確定しているため、回答の変更はできません。';
                            showSnackBar(true, message, 'error')
                            return;
                        }

                        showSnackBar(true, `メールの送信に失敗しました`, 'error')
                        return;
                    }
                    setIsSuccess(true)
                })
            }
            
        } else {
            setIsSubmitting(false)
            showSnackBar(true, `エラーが発生しました。管理者にお問合せください。`, 'error')
        }
    }

    const setDimensionLogo = () => {
        if (width > 600) {
            return 140;
        }
        
        return 120;
    }

    //Change padding top from 60px to 0px
    const thanksPageComponent = () => {
        return (
            <Box sx={{padding: { xs: '0 50px 50px 50px', sm: '0px 40px 60px 40px' }}}>
                <Stack spacing={1} direction={'column'}>
                    <Typography sx={styles.thanksText}>日程調整のご協力ありがとうございます。</Typography>
                    <Typography sx={styles.thanksText}>予定の日時が確定するとメールが届きますので、</Typography>
                    <Typography sx={styles.thanksText}>今しばらくお待ちください。</Typography>
                    <Typography sx={styles.thanksText}>引き続き、よろしくお願い致します。</Typography>
                </Stack>
            </Box>
        )
    }

    return (
        !isLoading && (
            <Box sx={{position: 'relative', minHeight: '100vh'}}>
                {/* <Header /> */}
                <Box>
                    <Typography sx={styles.titleText}><NewDyskeLogo width={setDimensionLogo()}/></Typography>
                    {/* <Typography sx={styles.descriptionText}>予定調整システム</Typography> */}
                    {
                        (() => {
                            if (isInvalidSchedule) {
                                return (
                                    <Box sx={styles.thanksContainer}>
                                        <Stack spacing={1} direction={'column'}>
                                            <Typography sx={styles.thanksText}>予定が無効です。</Typography>
                                        </Stack>
                                    </Box>
                                )
                            } else if (isExpired) {
                                return (
                                    <Box sx={styles.thanksContainer}>
                                        <Stack spacing={1} direction={'column'}>
                                            <Typography sx={styles.thanksText}>回答期限が切れました。</Typography>
                                        </Stack>
                                    </Box>
                                )
                            } else if (isSuccess) {
                                return thanksPageComponent()
                            } else {
                                return (
                                    <Box component="form" noValidate sx={styles.form}>
                                        <Grid container spacing={5} sx={styles.container}>
                                            <Grid xs={12} sx={[styles.invitees, {paddingTop: {xs: '0px', sm: '20px' }}]}>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: '16px',
                                                            sm: '18px',
                                                        },
                                                        letterSpacing: '0rem',
                                                        lineHeight: 1.8,
                                                        fontWeight: '400',
                                                    }}
                                                >
                                                        下記より、予定「
                                                        <b>{subject}</b>
                                                        」への参加が可能な日時をご回答頂けますと幸いです。
                                                </Typography>
                                            </Grid>
                                            <Grid xs={12} sm={8} md={5.5} sx={[{paddingTop: {xs: '0px', sm: '20px' }}]}>
                                                <Stack spacing={1} direction={'column'}>
                                                    <Typography sx={[styles.labelConfirm]}>回答者</Typography>
                                                    <Controller
                                                        name="name"
                                                        control={control}
                                                        defaultValue={getValues('name')}
                                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                            <>
                                                                <TextInput
                                                                    placeholder="例）GP宮永"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    customsx={{
                                                                        '& ::placeholder': {
                                                                            fontSize: {
                                                                                xs: '16px',
                                                                                sm: '18px',
                                                                            },
                                                                            fontWeight: '400',
                                                                        },
                                                                        input: {
                                                                            color: '#000000',
                                                                            fontSize: {
                                                                                xs: '16px',
                                                                                sm: '18px',
                                                                            },
                                                                            fontWeight: '400',
                                                                        },
                                                                    }}
                                                                />
                                                                {error ? (
                                                                    <FormHelperText error> {error.message} </FormHelperText>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <Typography sx={styles.noticeInputName}>
                                                        ※社名略称とお名前を入力してください
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            {isSharedUrl && (
                                                <Grid xs={12} sm={8} md={6.5} sx={[{paddingTop: {xs: '0px', sm: '20px' }}]}>
                                                    <Stack spacing={1} direction={'column'}>
                                                        <Typography sx={[styles.labelConfirm]}>メール</Typography>
                                                        <Controller
                                                            name="email"
                                                            control={control}
                                                            defaultValue={getValues('email')}
                                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                                <>
                                                                    <TextInput
                                                                        placeholder="例）taro.yamada@example.com"
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        customsx={{
                                                                            '& ::placeholder': {
                                                                                fontSize: {
                                                                                    xs: '16px',
                                                                                    sm: '18px',
                                                                                },
                                                                                fontWeight: '400',
                                                                            },
                                                                            input: {
                                                                                color: '#000000',
                                                                                fontSize: {
                                                                                    xs: '16px',
                                                                                    sm: '18px',
                                                                                },
                                                                                fontWeight: '400',
                                                                            },
                                                                        }}
                                                                    />
                                                                    {error ? (
                                                                        <FormHelperText error> {error.message} </FormHelperText>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            )}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            )}
                                            <Grid
                                                xs={12}
                                                sx={[
                                                    styles.tableGrid,
                                                    {
                                                        paddingTop: {xs: '0px', sm: '20px' },
                                                        marginLeft: {
                                                            xs: '-0px',
                                                            sm: width <= 800 ? '-40px' : 'calc(0px - ((100vw - 720px) / 2))',
                                                        },
                                                        marginRight: {
                                                            xs: '-0px',
                                                            sm: width <= 800 ? '-40px' : 'calc(0px - ((100vw - 720px) / 2))',
                                                        },
                                                    },
                                                    adjustments.length <= 1 && {
                                                        pb: {
                                                            xs: 0,
                                                            sm: '10px'
                                                        },
                                                    }
                                                ]}
                                            >
                                                <TableContainer
                                                    sx={[
                                                        styles.tableContainer,
                                                        {
                                                            paddingLeft: {
                                                                xs: '20px',
                                                                sm: width <= 800 ? '60px' : 'calc(((100vw - 720px) / 2) + 20px)',
                                                            },
                                                            paddingRight: {
                                                                xs: '0px',
                                                                sm: width <= 800 ? '60px' : 'calc(((100vw - 720px) / 2) + 20px)',
                                                            }
                                                        }, 
                                                        adjustments.length <= 1 && {
                                                            pb: {
                                                                xs: '40px',
                                                                sm: '30px'
                                                            },
                                                            '& .scroll-hint-icon-wrap.is-active': {
                                                                top: {
                                                                    xs: '-10px',
                                                                    sm: 0
                                                                }
                                                            }
                                                        }
                                                        
                                                    ]}
                                                    className='js-scrollable scroll-hint'
                                                >
                                                    {adjustments && adjustments.length > 0 && (
                                                        <Table sx={styles.table} ref={tableRef}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <Typography sx={[styles.labelConfirm]}>
                                                                            候補日時
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    {scheduleData.participant.map((e: any, index: any) => {
                                                                        if (e.id == participantId) {
                                                                            return;
                                                                        }
                                                                        return (
                                                                            <TableCell key={e.id} align="center" sx={{position: 'relative', backgroundColor: "#f5f5f5"}}>
                                                                                {limitCharacter(replaceGaroonName(e.user, width), 5)}
                                                                            </TableCell>
                                                                        )
                                                                    })}
                                                                    {width < 600 &&
                                                                        <TableCell 
                                                                            sx={{
                                                                                paddingLeft: {
                                                                                    xs: '10px !important',
                                                                                },
                                                                                paddingRight: {
                                                                                    xs: '10px !important',
                                                                                },
                                                                                width: 0,
                                                                                borderBottom: '1px solid transparent !important'
                                                                            }}
                                                                        />
                                                                    }
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {adjustments.map((row: any) => {
                                                                    return (
                                                                        <TableRow key={row.id} hover>
                                                                            <TableCell>
                                                                                <Typography sx={styles.tableBodyTextCustom}>
                                                                                    { row.duration !== 1439 
                                                                                        ? (<>
                                                                                            {dayjs(row.start_time).format(
                                                                                                `YYYY/M/D(${
                                                                                                    CONSTANTS.DAY_OF_WEEK[
                                                                                                        dayjs(row.start_time).day()
                                                                                                    ]
                                                                                                }) H:mm`,
                                                                                            )}
                                                                                            {` 〜 `}
                                                                                            {row.duration !== 1439
                                                                                                ? `(${row.duration}分)`
                                                                                                : '23:59'}
                                                                                        </>)
                                                                                        : (<>
                                                                                            {dayjs(row.start_time).format(
                                                                                                `YYYY/M/D(${
                                                                                                    CONSTANTS.DAY_OF_WEEK[
                                                                                                        dayjs(row.start_time).day()
                                                                                                    ]
                                                                                                })`,
                                                                                            )}
                                                                                        </>)
                                                                                    }
                                                                                </Typography>
                                                                            </TableCell>
                                                                            {statusList.map((status, index) => {
                                                                                return (
                                                                                    <TableCell
                                                                                        key={index}
                                                                                        sx={{
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            selectStatus(
                                                                                                row.id,
                                                                                                status === 0 ||
                                                                                                    status === 3 ||
                                                                                                    status === 5
                                                                                                    ? status
                                                                                                    : 5,
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <Box
                                                                                            sx={[
                                                                                                styles.lightField,
                                                                                                {
                                                                                                    bgcolor:
                                                                                                        getStatus(row.id) === status
                                                                                                            ? '#0075FF'
                                                                                                            : 'none',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            {getStatus(row.id) === status
                                                                                                ? (
                                                                                                    <LightFieldByPoint
                                                                                                        point={status}
                                                                                                        fill='#FFFFFF'
                                                                                                        isConfirmPage
                                                                                                    />
                                                                                                ) 
                                                                                                : (
                                                                                                    <LightFieldByPoint
                                                                                                        point={status}
                                                                                                        fill='#000000'
                                                                                                        isConfirmPage
                                                                                                    />
                                                                                                )
                                                                                            }
                                                                                        </Box>
                                                                                    </TableCell>
                                                                                )
                                                                            })}
                                                                            {scheduleData.participant.map((el: any, i: number) => {
                                                                                if (el.id == participantId) {
                                                                                    return;
                                                                                }
                                                                                const adjustment = row.adjustmentParticipant.find((e: any) => {
                                                                                    return e.participant.user_id === el.user_id
                                                                                })

                                                                                if (adjustment?.status >= 0) {
                                                                                    return (
                                                                                        <TableCell
                                                                                            key={el.user_id}
                                                                                            sx={{
                                                                                                fontSize: '24px',
                                                                                                position: 'relative',
                                                                                                padding: {
                                                                                                    sm: '8px 16px !important',
                                                                                                    xs: '0px 6px !important',
                                                                                                },
                                                                                                backgroundColor: "#f5f5f5"
                                                                                            }}
                                                                                        >
                                                                                            <LightFieldByPoint
                                                                                                point={adjustment.status}
                                                                                                fill="black"
                                                                                            />
                                                                                        </TableCell>
                                                                                    )
                                                                                } 
                                                                                return (
                                                                                    <TableCell
                                                                                        key={el.user_id}
                                                                                        align="center"
                                                                                        sx={{
                                                                                            fontSize: '24px',
                                                                                            alignItems: 'center',
                                                                                            position: 'relative',
                                                                                            backgroundColor: "#f5f5f5"
                                                                                        }}
                                                                                    >
                                                                                        <LightFieldByPoint point={-1} fill="#000" />
                                                                                    </TableCell>
                                                                                )
                                                                            })}
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    )}
                                                </TableContainer>
                                            </Grid>
                                            <Grid xs={12} sx={[styles.invitees, adjustments.length <= 1 && {paddingTop: 0}]}>
                                                <Stack sx={{width: '100%'}} direction={'column'} spacing={1}>
                                                    <Typography sx={[styles.labelConfirm]}>備考</Typography>
                                                    <Controller
                                                        name="comment"
                                                        control={control}
                                                        defaultValue={getValues('comment')}
                                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                            <>
                                                                <TextInput
                                                                    placeholder="補足があれば入力してください。"
                                                                    multiline
                                                                    rows={4}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    customsx={{
                                                                        '& ::placeholder': {
                                                                            fontSize: {
                                                                                xs: '16px',
                                                                                sm: '18px',
                                                                            },
                                                                            fontWeight: '400',
                                                                            lineHeight: 1.5,
                                                                        },
                                                                        input: {
                                                                            color: '#000000',
                                                                            fontSize: {
                                                                                xs: '16px',
                                                                                sm: '18px',
                                                                            },
                                                                            fontWeight: '400',
                                                                        },
                                                                    }}
                                                                />
                                                                {error && <FormHelperText error> {error.message} </FormHelperText>}
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid xs={12}>
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                                    <LoadingButton loading={isSubmitting}  disabled={isSubmitting} variant="outlined" onClick={handleSubmit(confirm)} sx={isSubmitting ? styles.buttonFooterModalSubmit : styles.buttonFooterModal}>
                                                        回答する
                                                    </LoadingButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                        })()
                    }
                </Box>
                <ScheduleConfirmFooter />
            </Box>
        )
    )
}

export default ScheduleConfirm

const Header = () => {
    return (
        <Box sx={styles.header}>
            <Typography sx={styles.headerText}><GPLogo /></Typography>
        </Box>
    )
}

const styles = {
    header: {
        bgcolor: '#000',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: {
            xs: '56px',
            sm: '76px',
        },
    },
    headerText: {
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: 1,
        letterSpacing: '0rem',
        color: '#fff',
        alignSelf: 'center',
        textAlign: 'center',
    },

    titleText: {
        fontSize: '32px',
        color: '#000',
        fontWeight: 'bold',
        lineHeight: 1,
        letterSpacing: '0.1rem',
        textAlign: 'center',
        marginTop: '80px',
        marginBottom: '80px',
        '@media (max-width: 600px)': {
            marginTop: '20px',
            marginBottom: '20px',
        },
    },
    descriptionText: {
        color: '#000',
        fontSize: '15px',
        fontWeight: 'bold',
        lineHeight: 1.5,
        letterSpacing: '0rem',
        textAlign: 'center',
    },

    thanksContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: { xs: '0px 20px 20px 20px', sm: '0px 40px 40px 40px' },
    },
    thanksText: {
        fontWeight: '400',
        fontSize: {
            xs: '16px',
            sm: '18px',
        },
        lineHeight: 1.8,
        letterSpacing: '0rem',
        textAlign: 'center',
    },
    form: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        position: 'relative',
        padding: { xs: '0px', sm: '0px 40px 40px 40px' },
    },
    container: {
        maxWidth: '720px',
        margin: 0,
        borderRadius: '20px',
        background: 'rgba(255, 255, 255, 0.9)',
        mb: '100px',
    },
    invitees: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    footerModal: { display: 'flex', flexDirection: 'row' },
    buttonFooterModal: {
        borderRadius: '15px',
        color: '#FFFFFF',
        fontWeight: '500',
        bgcolor: '#0075FF',
        fontSize: '16px',
        lineHeight: 1.5,
        letterSpacing: '0rem',
        p: '12.5px 39px',
        ':hover': {
            bgcolor: '#0075FF',
        },
    },
    buttonFooterModalSubmit: {
        borderRadius: '15px',
        color: '#FFFFFF',
        fontWeight: '500',
        bgcolor: 'rgba(0, 0, 0, 0.12)',
        fontSize: '16px',
        lineHeight: 1.5,
        letterSpacing: '0rem',
        p: '12.5px 39px',
        ':hover': {
            bgcolor: 'rgba(0, 0, 0, 0.12)',
        },
    },
    inputName: {
        borderRadius: '10px',
        input: {
            color: 'black !important',
        },
    },
    noticeInputName: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#747E8B',
        letterSpacing: '0rem',
    },
    tableGrid: {
        overflowX: 'auto',
        width: '100vw',
        paddingLeft: 0,
        paddingRight: 0,
        margin: 0 ,
    },
    tableContainer: {
        pt: {
            xs: 0,
            sm: "20px"
        },
        margin: {
            xs: 0,
            sm: '0 calc(50% - 50vw)'
        },
        boxSizing: 'border-box',
        '::-webkit-scrollbar' : {
            display: 'none'
        },
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        display: 'flex',
    },
    table: {
        minWidth: {
            xs: 'calc(100vw - 40px)',
            sm: '680px'
        },
        th: {
            ...tableHeaderText,
            padding: {
                xs: '8px',
                sm: '8px 16px'
            },
            borderBottom: '1px solid #666666',
        },
        'tbody > tr': {
            cursor: 'pointer',
        },
        td: {
            ...tableBodyText,
            padding: {
                xs: '8px',
                sm: '8px 16px'
            },
            whiteSpace: 'nowrap',
            borderBottom: '1px solid #56577A',
        },
        marginBottom: 0,
    },
    tableBodyTextCustom: {
        fontSize: {
            xs: '14px',
            sm: '18px',
        },
        fontWeight: '500',
        lineHeight: '1.4',
        letterSpacing: '0rem',
    },
    lightField: {
        width: '40px',
        height: '40px',
        position: 'relative',
        borderRadius: '12px',
    },
    labelConfirm: {
        fontSize: {
            xs: '14px',
            sm: '15px',
        },
        lineHeight: '1.5',
        fontWeight: '500',
        letterSpacing: '0rem',
    },
}
