import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import authReducer from 'src/pages/Auth/authSlice'
import rootSaga from './rootSaga'
import topReducer from 'src/pages/Top/topSlice'
import adjustmentReducer from 'src/pages/adjustment/adjustmentSlice'
import groupReducer from 'src/pages/Group/groupSlice'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: {
        auth: authReducer,
        top: topReducer,
        adjustment: adjustmentReducer,
        group: groupReducer,
    },
    middleware: (getDefaultMiddleware: any) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            sagaMiddleware,
        ),
})

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
