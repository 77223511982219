export const isJsonString = (str: string) => {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export const limitCharacter = (text: string, limit: number = 6) => {
    if (!text) return text

    if (text.length > limit) {
        return text.slice(0, limit) + '...'
    } else {
        return text
    }
}
