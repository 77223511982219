export interface PaginationParams {
    _limit: number
    _page: number
    _total: number
}

export interface ListResponse<T> {
    data: T[]
    pagination: PaginationParams
}

export interface ListParams {
    _page: number
    _limit: number
    _sort: string
    _order: 'asc' | 'desc'

    [key: string]: any
}

export interface TimeZone {
    dateTime: Date
    timeZone: string
}

export interface TimeRanges {
    start: Date
    end: Date
}

export enum VisibilityType {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
}

export enum Order {
    ASC = 'asc',
    DESC = 'desc',
}

export enum Status {
    ADJUSTING = 'ADJUSTING',
    FINALIZED = 'FINALIZED',
    DRAFT = 'DRAFT',
    PAST = 'PAST',
}

export interface Meta {
    pageIndex: number
    pageSize: number
    totalItems: number
    totalPage: number
    hasPreviousPage: boolean
    hasNextPage: boolean
}
