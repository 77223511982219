import { Box, Stack, Typography } from '@mui/material'

const Footer = () => {
    return (
        <Box sx={{ 
            marginTop: {
                xs: '30px',
                sm: '50px',
                md: '120px',
            }, 
            textAlign: { xs: 'center', sm: 'left' } }}>
            <Stack
                sx={{
                    ml: { xs: '0', md: '24px' },
                    lineHeight: 1.5,
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '14px',
                    }}
                >
                    ©︎
                </Typography>
                <Typography
                    sx={{
                        fontSize: '12px',
                        mt: '2px',
                        mr: '2px',
                    }}
                >
                    2024.
                </Typography>
                <Typography
                    sx={{
                        fontSize: '14px',
                    }}
                >
                    GLOBAL PRODUCE
                </Typography>
            </Stack>
        </Box>
    )
}

export default Footer
