import { Button } from '@mui/material'
import { buttonStyle, buttonSubmitStyle, smallButtonStyle } from 'src/theme'
import { useState, useEffect } from 'react'

const ButtonCustom = (props: any) => {
    const { child, onClick = () => {}, sx = {}, endIcon, isSubmit = false, onMouseDown = () => {}, disabled = false } = props
    const [width, setWidth] = useState<number>(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return (
        <Button
            variant={'contained'}
            onClick={onClick}
            sx={[{ ...buttonStyle, ...sx }, isSubmit && buttonSubmitStyle, width < 450 && smallButtonStyle]}
            endIcon={endIcon}
            onMouseDown={onMouseDown}
            disabled={disabled}
        >
            {child}
        </Button>
    )
}

export default ButtonCustom
