import {
    Box,
    Grid,
    Pagination,
    Typography,
    TableBody,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { formatDate } from '../../../helpers/formatDate'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { Order } from 'src/models'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import ModalConfirm from 'src/components/Common/ModalConfirm'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { tableHeaderText, tableRowAlign, tableBodyText } from 'src/theme'
import ChevronDownIcon from 'src/components/Icons/ChevronDownIcon'
import LinearProgressWithLabel from 'src/components/Common/LinearProgressWithLabel'
import { groupActions, selectFilterMyGroup, selectGroupNewUpdate, selectLoadingFetchMyGroup, selectMyGroups } from '../groupSlice'
import organizationApi from 'src/api/organizationApi'
import { replaceGaroonName } from '../../../helpers/helpers'
import { useNavigate } from 'react-router-dom'

function GroupDataTable() {
    const dispatch = useAppDispatch()
    const { showSnackBar } = useSnackBar()
    const [openModalConfirm, setOpenModalConfirm] = useState(false)
    const [action, setAction] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [titleModalConfirm, setTitleModalConfirm] = useState('')
    const [messageModalConfirm, setMessageModalConfirm] = useState('')
    const [groupDelete, setGroupDelete] = useState<any>(null)
    const [width, setWidth] = useState<number>(window.innerWidth)
    const groupList = useAppSelector(selectMyGroups)
    const filterGroup = useAppSelector(selectFilterMyGroup)
    const [order, setOrder] = useState<any>({
        id: 'asc',
    })
    const loadingGroup = useAppSelector(selectLoadingFetchMyGroup)
    const navigate = useNavigate()
    const deleteRef = useRef<boolean>(false)
    const groupNewUpdate = useAppSelector(selectGroupNewUpdate)

    useEffect(() => {
        dispatch(groupActions.fetchMyGroup(filterGroup));
    }, [filterGroup])

    const handleSortBy = (key: string) => {
        setOrder({
            ...order,
            [key]: order[key] === 'desc' ? 'asc' : 'desc',
        })

        dispatch(
            groupActions.setFilter({
                ...filterGroup,
                orderBy: key,
                order: filterGroup.order === Order.DESC ? Order.ASC : Order.DESC,
            }),
        )
    }

    const handleConfirmDelete = (group: any) => {
        setGroupDelete(group)
        setAction('DELETE')
        setTitleModalConfirm('削除する')
        setMessageModalConfirm('このグループを削除しますか')
        setOpenModalConfirm(true)
        deleteRef.current = true
    }

    const handleGroupDelete = () => {
        if (groupDelete.id) {
            setIsLoading(true)
            organizationApi
                .deleteGroup(groupDelete.id)
                .then((res: any) => {
                    if (res?.success) {
                        dispatch(
                            groupActions.setFilter({
                                ...filterGroup,
                            }),
                        )
                        showSnackBar(true, `グループ「${groupDelete.name}」を削除しました。`, 'success')
                    } else {
                        showSnackBar(true, `グループ「${groupDelete.name}」削除に失敗しました`, 'error')
                        
                    }
                    closeModalConfirm()
                    setIsLoading(false)
                })
                .catch(() => {
                    showSnackBar(true, `グループ「${groupDelete.name}」削除に失敗しました`, 'error')
                    closeModalConfirm()
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const closeModalConfirm = () => {
        setGroupDelete(null)
        setOpenModalConfirm(false)
        setTitleModalConfirm('')
        setMessageModalConfirm('')
        deleteRef.current = false
    }

    const [progress, setProgress] = useState(0);
    useEffect(() => {
        // set progress loading
        if (loadingGroup) {
            setProgress(1);
            dispatch(groupActions.setGroupList([]))
            return;
        } 
        let i = 1;
        let total = groupList.length > 0 ? groupList.length : 1
        const timer = setInterval(() => {
            if (i > total) {
                clearInterval(timer);
                setProgress(0);
                return;
            }
            setProgress((i / total) * 100)
            i++;
        }, 100);

        return () => clearInterval(timer);
    }, [loadingGroup]);

    const getMessageNoRecord = () => {
        let message = 'グループは作成されていません';
        return message;
    }

    const handleGoToEditGroup = (group: any) => {
        navigate(`/group/member-manage/${group.id}`)
    }

    return (
        <Box sx={styles.container}>
            <TableContainer sx={{ overflowX: progress <= 0 ? 'auto' : 'hidden' }}>
                <Table sx={styles.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                onClick={() => handleSortBy('id')}
                                sx={{ position: 'relative', cursor: 'pointer' }}
                            >
                                No
                                <Box sx={{ position: 'absolute', top: '9px', left: '38px' }}>
                                    <OrderComponent order={order.id} orderThis={filterGroup.orderBy === 'id'} />
                                </Box>
                            </TableCell>

                            <TableCell sx={{ position: 'relative', cursor: 'pointer' }}>
                                グループ名
                            </TableCell>

                            <TableCell sx={{ width: '132px', position: 'relative', cursor: 'pointer' }}>
                                メンバー（人数）
                            </TableCell>

                            <TableCell sx={{ width: '160px' }}>登録日時</TableCell>
                            <TableCell sx={{ width: '90px' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        (() => {
                            if (progress <= 0) {
                                return (
                                    <TableBody>
                                        {
                                            (() => {
                                                if (groupList.length > 0) {
                                                    return (
                                                        groupList.length > 0 && groupList.map((group: any, index: number) => {
                                                            let memberName: any = [];
                                                            let numberOfMembers: number = 0;
                                                            if (group.organizationUser && group.organizationUser.length > 0) {
                                                                numberOfMembers = group.organizationUser.length;
                                                                group.organizationUser.map((user: any) => {
                                                                    if (user.user.is_employee) {
                                                                        memberName.push(replaceGaroonName(user.user));
                                                                    }
                                                                })
                                                            }
                                                            let nameDisplay = '';
                                                            if (memberName.length >= 6) {
                                                                memberName = memberName.slice(0, 5);
                                                                nameDisplay = memberName.join('、') + ' ...';
                                                            } else {
                                                                nameDisplay = memberName.join('、');
                                                            }

                                                            return (
                                                                <TableRow
                                                                    key={group.id}
                                                                    tabIndex={0}
                                                                    hover
                                                                    sx={[
                                                                        {
                                                                            cursor: 'pointer',
                                                                            transition: '0.25s linear',
                                                                        },
                                                                        (group.id === groupNewUpdate?.id) && {
                                                                                animation: 'fadeout 4s',
                                                                        },
                                                                        width > 960 && {
                                                                            '& > :last-child': {
                                                                                opacity: 0,
                                                                            },
                                                                            ':hover > :last-child': {
                                                                                animation: `fadeInOpacity 0.25s linear`,
                                                                                opacity: 1,
                                                                            },
                                                                            ':not(:hover) > :last-child': {
                                                                                opacity: 0,
                                                                            },
                                                                        },
                                                                    ]}
                                                                    onClick={() => {
                                                                        if (!deleteRef.current) {
                                                                            handleGoToEditGroup(group)
                                                                        }
                                                                    }}
                                                                >
                                                                    <TableCell>{group.id}</TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styles.overlayItem}>
                                                                            <Typography
                                                                                sx={{
                                                                                    textDecoration: 'none'
                                                                                }}
                                                                            >
                                                                                {group.name}
                                                                            </Typography>
                                                                        </Box>
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <Typography sx={{ whiteSpace: 'nowrap' }}>
                                                                            {`${nameDisplay}`}
                                                                            {`  (${numberOfMembers}人)`}
                                                                        </Typography>
                                                                    </TableCell>

                                                                    <TableCell>{formatDate(new Date(group.created_at))}</TableCell>

                                                                    <TableCell>
                                                                        <Grid sx={styles.overlay}>
                                                                            <Box
                                                                                sx={styles.overlayItem}
                                                                                onClick={() => handleConfirmDelete(group)}
                                                                            >
                                                                                <DeleteComponent />
                                                                            </Box>
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    )
                                                } else {
                                                    return (
                                                        <TableRow>
                                                            <TableCell sx={{borderBottom: 'none !important'}} colSpan={8}>
                                                                <Typography>{getMessageNoRecord()}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            })()
                                        }
                                    </TableBody>
                                )
                            }
                        })()
                    }
                </Table>
            </TableContainer>       
            {
                (() => {
                    // let countPage = Math.floor((metaSchedules - 1) / filter.limit) + 1
                    if (progress > 0) {
                        return (
                            <Box sx={{ width: '200px', margin: '0 auto' }}>
                                <LinearProgressWithLabel value={progress} />
                            </Box>
                        )
                    }

                    //Disable pagination
                    // } else if ( groupList.length > 0 && countPage > 1) {
                    //     return (
                    //         <>
                    //             <Pagination
                    //                 count={countPage}
                    //                 page={filter.page}
                    //                 showFirstButton={false}
                    //                 showLastButton={false}
                    //                 hidePrevButton={filter.page === 1}
                    //                 hideNextButton={filter.page === countPage}
                    //                 onChange={(event, value) => {
                    //                     dispatch(
                    //                         topActions.setFilter({
                    //                             ...filter,
                    //                             page: value,
                    //                         }),
                    //                     )
                    //                 }}
                    //                 sx={styles.pagination}
                    //                 siblingCount={0}
                    //                 boundaryCount={1}
                    //             />
                    //         </>
                    //     )
                    // }
                })()
            }

            <ModalConfirm
                openModalConfirm={openModalConfirm}
                isLoading={isLoading}
                closeModalConfirm={closeModalConfirm}
                onConfirm={() => {
                    handleGroupDelete()
                }}
                title={titleModalConfirm}
                message={messageModalConfirm}
            />
        </Box>
    )
}

const DeleteComponent = () => {
    return (
        <>
            <DeleteOutlinedIcon sx={styles.boxIcon} />
            <Box sx={styles.actionText}>削除</Box>
        </>
    )
}

const OrderComponent = ({ order, orderThis }: any) => {
    return (
        <Box
            sx={{
                transform: order === Order.DESC ? 'rotate(180deg)' : 'rotate(0)',
            }}
        >
            <ChevronDownIcon stroke={orderThis ? '#000' : '#A0AEC0'} />
        </Box>
    )
}

export default GroupDataTable

const styles = {
    container: { width: '100%', display: 'table', tableLayout: 'fixed' },
    table: {
        marginBottom: 0,
        th: {
            ...tableHeaderText,
            ...tableRowAlign,
            borderBottom: '1px solid #666666',
        },
        tr: {
            verticalAlign: 'top',
        },
        td: {
            ...tableBodyText,
            ...tableRowAlign,
            borderBottom: '1px solid #56577A',
        },
    },
    overlay: {
        cursor: 'pointer',
    },
    overlayItem: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: '5px',
    },
    boxIcon: {
        marginRight: '10px',
        fontSize: '18px',
    },
    footerModal: { display: 'flex', flexDirection: 'row' },
    buttonFooterModal: {
        border: '1px solid #C4C4C4',
        borderRadius: '12px',
        color: '#000000',
        fontWeight: 'bold',
        padding: '10px 20px',
    },
    actionText: {
        whiteSpace: 'nowrap',
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'left',
    },
    pagination: {
        '& ul': {
            justifyContent: 'end',
        },
        '& li': {
            width: '36px',
            height: '36px',
            mr: '5px',
        },
        'button:hover': {
            border: 'none',
            bgcolor: '#FFFF00 !important',
        },
        '.Mui-selected': {
            border: 'none',
            color: '#000',
            bgcolor: '#FFFF00 !important',
        },
        button: {
            width: '100%',
            height: '100%',
            border: '1px solid #666666',
            borderRadius: '50%',
            color: '#000000',
        },
        mt: 3,
    },
}
