import { store } from 'src/app/store'
import { NotificationError } from '../../src/helpers/alert'
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig, AxiosResponse } from 'axios'
import { authActions } from 'src/pages/Auth/authSlice'
import Cookies from 'universal-cookie';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_END_POINT,
    headers: {
        'Content-Type': 'application/json',
    },
})
const configAxios = (config?:AxiosRequestConfig) => {
    const cookies = new Cookies();
    return config = {
        ...config,
        headers: {
            ...config?.headers,
            'XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
        },
        withCredentials: true,
    };
}

// Add a request interceptor
axiosClient.interceptors.request.use(
    async function (config: InternalAxiosRequestConfig) {
        // Do something before request is sent
        const token = await localStorage.getItem('access_token')
        const token2 = await sessionStorage.getItem('access_token')
        if (token) {
            config.headers!.Authorization = `${token}`
        } else if (token2) {
            config.headers!.Authorization = `${token2}`
        }
        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    },
)

// Add a response interceptor
axiosClient.interceptors.response.use(
    function (response: AxiosResponse) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    },
)

export async function get(url: string, config?: AxiosRequestConfig) {
    return await axiosClient
        .get(url, {
            ...configAxios(config),
        })
        .then((response) => response)
        .catch((error) => {
            handleError(error)
            throw new Error(`[ Cat ] ApiService ${error}`)
        })
}

export async function query_params_string(url: string, params: any, config?: AxiosRequestConfig) {
    return await axiosClient
        .get(`${url}?${new URLSearchParams(params).toString()}`, {
            ...configAxios(config),
        })
        .then((response) => response.data)
        .catch((error) => {
            handleError(error)
            throw new Error(`[ Cat ] ApiService ${error}`)
        })
}

export async function post(url: string, data: any, config?: AxiosRequestConfig) {
    return await axiosClient
        .post(url, data, {
            ...configAxios(config),
        })
        .then((response) => response)
        .catch((error) => {
            handleError(error)
            throw new Error(`[ Cat ] ApiService ${error}`)
        })
}

export async function put(url: string, data: any, config?: AxiosRequestConfig) {
    return await axiosClient
        .put(url, data, {
            ...configAxios(config),
        })
        .then((response) => response)
        .catch((error) => {
            handleError(error)
            throw new Error(`[ Cat ] ApiService ${error}`)
        })
}

export async function del(url: string, config?: AxiosRequestConfig) {
    return await axiosClient
        .delete(url, {
            ...configAxios(config),
        })
        .then((response) => response)
        .catch((error) => {
            handleError(error)
            throw new Error(`[ Cat ] ApiService ${error}`)
        })
}

function logout() {
  store.dispatch(authActions.logout())
}

function setErrorGlobal(error: any) {
    store.dispatch(authActions.setErrorGlobal(error))
}

function hidenError() {
    store.dispatch(authActions.cancelHideAlert())
}

function handleError(error: any) {
    if (error?.response?.status >= 400) {
        // setErrorGlobal
        setErrorGlobal(error)

        // hide alert after 3 seconds
        setTimeout(() => {
            hidenError()
        }, 3000)
    }

    if (error?.response?.status === 401) {
        logout();
        return
    }
    if (error?.response?.status === 403) {
        logout();
        return
    }
}
