import * as React from "react"
const CircleIcon = ({ fill = "#FFFFFF" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill={fill}
        d="M19.875 10a9.376 9.376 0 1 0-18.751 0 9.376 9.376 0 0 0 18.751 0ZM.5 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={fill} d="M.5 0h20v20H.5z" />
      </clipPath>
    </defs>
  </svg>
)
export default CircleIcon
