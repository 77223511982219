import { all } from "redux-saga/effects";
import authSaga from "src/pages/Auth/authSaga";
import groupSaga from "src/pages/Group/groupSaga";
import topSaga from "src/pages/Top/topSaga";
import adjustmentSaga from 'src/pages/adjustment/adjustmentSaga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    topSaga(),
    adjustmentSaga(),
    groupSaga(),
  ]);
}
