import { Checkbox } from "@mui/material"

export const CheckBoxCustom = ({ checked, onChange, sx={}, disabled = false }: any) => {
    return (
        <Checkbox
            disabled={disabled}
            sx={[sx, 
                {
                    color: '#000000',
                }
            ]}
            checked={checked}
            onChange={onChange}
        ></Checkbox>
    )
}