import React, { useEffect, useState } from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import CustomBreadcrumbs from '../components/Breadcrumbs'
import UserHeaderMenu from '../components/UserHeaderMenu'
import ListItemSideMenu from '../components/ListItemSideMenu'
import Footer from '../components/Footer'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import userApi from 'src/api/userApi'
import { authActions, selectIsLoggedIn } from 'src/pages/Auth/authSlice'
import { useSnackBar } from 'src/components/Common/SnackBarContext'

const drawerWidth = 264

const drawerBgcolor = 'linear-gradient(to bottom, rgba(0, 0, 0, 0.94) 40%, rgba(0, 0, 0, 0.69))'

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth + 10}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(!open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: window.innerWidth > 959 ? '64px' : '0px',
        width: window.innerWidth > 959 ? `calc(100% - 64px)` : '100%',
    }),
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
    zIndex: theme.zIndex.drawer + 2,
}))

export default function MiniDrawer() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isLoggin = useAppSelector(selectIsLoggedIn)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    const theme = useTheme()
    const { pathname } = useLocation()
    const [open, setOpen] = useState(true)

    const [mobileOpen, setMobileOpen] = useState(false)

        

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth
            setWindowWidth(width)
            if (width < 1200) {
                setOpen(false)
            } else {
                setOpen(true)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY
            if (offset > 40) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        const token = localStorage.getItem('access_token')
        const token2 = sessionStorage.getItem('access_token')
        if (!token && !token2) {
            navigate('/signin')
        } else {
            userApi
                .me()
                .then((res) => {
                    if (res?.data) {
                        dispatch(authActions.setCurrentUser(res.data))
                    }
                })
                .catch((err) => {
                    dispatch(authActions.logout())
                    navigate('/signin')
                    return
                })
        }
    }, [isLoggin])

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    // Error handling global
    const showAlertGlobal = useAppSelector((state) => state.auth.showAlert);
    const alertMessageGlobal = useAppSelector((state) => state.auth.alertMessage);
    const alertSeverityGlobal = useAppSelector((state) => state.auth.alertSeverity);

    const { showSnackBar } = useSnackBar()

    useEffect(() => {
        if (showAlertGlobal && alertMessageGlobal !== '') {
            showSnackBar(true, alertMessageGlobal, alertSeverityGlobal)
        }
    }, [showAlertGlobal])

    return (
        isLoggin &&
        <Box
            sx={{
                display: 'flex',
                background: `rgba(255, 255, 255, 0.8)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '100vh',
            }}
        >
            <CssBaseline />
            <AppBar
                position="fixed"
                open={open}
                sx={{
                    backgroundColor: scrolled ? 'rgba(245, 245, 245, 1.0)' : 'transparent',
                    boxShadow: 'none',
                    display: { xs: 'none', sm: 'none', md: 'block' },
                    color: '#000000',
                    paddingTop: '10px',
                }}
            >
                <Toolbar>
                    <CustomBreadcrumbs windowWidth={windowWidth} />
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            marginLeft: 5,
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <UserHeaderMenu windowWidth={windowWidth} />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    '.MuiPaper-root': {
                        backdropFilter: open ? 'blur(15px)' : 'none',
                        borderRadius: '20px',
                        marginTop: '10px',
                        marginLeft: '10px',
                        background: drawerBgcolor,
                        height: `calc(${windowHeight}px - 20px)`,
                    },
                    display: { xs: 'none', sm: 'none', md: 'block' },
                }}
            >
                <DrawerHeader>
                    {open == true && (
                        <Typography
                            sx={{
                                textAlign: 'center',
                                // fontFamily: 'verlag',
                                fontSize: '20px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                letterSpacing: '0.18rem',
                            }}
                            noWrap
                            color={'#fff'}
                        >
                            D Y S K E
                        </Typography>
                    )}
                </DrawerHeader>
                {open ? (
                    <Box
                        sx={{
                            height: '1px',
                            backgroundColor: '#000',
                            background:
                                'linear-gradient(to right, rgba(0, 117, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
                        }}
                    />
                ) : (
                    null
                )}
                <ListItemSideMenu open={open} />
            </Drawer>

            {/* Mobile Drawer */}
            <AppBar
                position="fixed"
                open={mobileOpen}
                sx={{
                    backgroundColor: !mobileOpen ? 'rgba(255, 255, 255, 0.9)' : 'transparent',
                    boxShadow: 'none',
                    display: { xs: 'block', sm: 'block', md: 'none' },
                    color: '#000000',
                }}
            >
                {!mobileOpen ? (
                    <Toolbar>
                        <CustomBreadcrumbs />
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setMobileOpen(!mobileOpen)}
                            edge="start"
                            sx={{
                                marginRight: 0,
                                marginLeft: 0,
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <UserHeaderMenu />
                    </Toolbar>
                ) : (
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setMobileOpen(!mobileOpen)}
                            edge="start"
                            sx={{
                                marginLeft: 1,
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                )}
            </AppBar>
            <MuiDrawer
                variant="temporary"
                open={mobileOpen}
                onClose={() => setMobileOpen(false)}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    '.MuiPaper-root': {
                        backgroundImage: drawerBgcolor,
                        borderRadius: '20px',
                        marginTop: '10px',
                        marginLeft: '10px',
                        width: drawerWidth,
                        height: `calc(${windowHeight}px - 20px)`
                    },
                    display: { xs: 'block', sm: 'block', md: 'none' },
                }}
            >
                <DrawerHeader>
                    {mobileOpen == true && (
                        <Typography
                            sx={{ textAlign: 'center', fontWeight: '700' }}
                            variant="h6"
                            noWrap
                            component="div"
                            color={'#fff'}
                        >
                            DYSKE
                        </Typography>
                    )}
                </DrawerHeader>
                {mobileOpen ? (
                    <Divider
                        sx={{
                            borderWidth: 0,
                            borderStyle: 'solid',
                            borderColor: 'rgb(0,0,0,0.08)',
                            height: '0.0625rem',
                            margin: '0',
                            borderBottom: 'none',
                            opacity: 0.25,
                            backgroundColor: 'transparent',
                            backgroundImage:
                                'linear-gradient(to right, rgba(0, 117, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))!important',
                        }}
                    />
                ) : (
                    <Divider />
                )}
                <ListItemSideMenu open={mobileOpen} setOpenMobile={setMobileOpen} />
            </MuiDrawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: { xs: '24px 20px', sm: '24px 30px', md: 3 }, ml: { sm: 0, md: '10px' } }}
            >
                <DrawerHeader />
                <Box sx={{
                    minHeight: {
                        xs: 'calc(100vh - 160px)',
                        sm: 'calc(100vh - 190px)',
                        md: 'calc(100vh - 260px)',

                    },
                }}>
                    <Outlet />
                </Box>
                <Footer />
            </Box>
        </Box>
    )
}
