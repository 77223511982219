import { Box, Tab, Typography, Tabs, Container } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import TopSearch from './components/TopSearch'
import TopDataTable from './components/TopDataTable'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { 
    selectFilterSchedules, 
    topActions, 
    selectShowAlert, 
    selectAlertMessage, 
    selectAlertSeverity,
    selectScheduleNewCancel,
} from './topSlice'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import { CONSTANTS } from 'src/config/constants'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import { selectCurrentUser } from 'src/pages/Auth/authSlice'
import scheduleApi from 'src/api/scheduleApi'

const ScheduleList = () => {
    const location = useLocation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [locationState] = useState(location?.state || {});
    const filterSchedules = useAppSelector(selectFilterSchedules)
    const showAlert = useAppSelector(selectShowAlert)
    const alertMessage = useAppSelector(selectAlertMessage)
    const alertSeverity = useAppSelector(selectAlertSeverity)
    const { height, width } = useWindowDimensions();
    const [pdTab, setPdTab] = useState<number>(16)
    const currentUser = useAppSelector(selectCurrentUser)

    const scheduleNewCancel = useAppSelector(selectScheduleNewCancel)

    const { showSnackBar } = useSnackBar()
    const [selectedTab, setSelectedTab] = useState<string>('ADJUSTING')

    const handleChangeTab = (event: React.SyntheticEvent, value: string) => {
        setSelectedTab(value)
        dispatch(
            topActions.setFilter({
                ...filterSchedules,
                page: 1,
                status: value,
            }),
        )
    }

    useEffect(() => {
        navigate({}, { replace: true });
    }, [navigate]);

    useEffect(() => {
        if (width < 960 && width >=  600) {
            let cal = (width - 600) / 12;
            if (cal > 24) {
                cal = 24;
            }
            setPdTab(16 + cal)
        }
    },[width])

    useEffect(() => {
        // change tab to 'CANCELLED' when schedule is cancelled
        if (scheduleNewCancel) {
            setSelectedTab('CANCELLED')
            dispatch(
                topActions.setFilter({
                    ...filterSchedules,
                    page: 1,
                    status: 'CANCELLED',
                    orderBy: 'updated_at',
                    order: 'desc',
                }),
            )
        }
    }, [scheduleNewCancel])

    useEffect( () => {
        scheduleApi.getFilterSchedules({}).then((res) => {
            let filterSchedulesCondition = {
                ...filterSchedules,
                page: 1,
                status: selectedTab,
                admin_id: currentUser?.id || 0,
                firstView: false,
            }

            if (res.data) {
                filterSchedulesCondition = {
                    ...filterSchedulesCondition,
                    admin_id: res.data.creator_id ? res.data.creator_id : 0,
                    participants: res.data.participant_id ? [res.data.participant_id] : [],
                    subject: res.data.subject ? res.data.subject : '',
                }
            }

            if (CONSTANTS.SCHEDULE_STATUS.some( (status) => status.value == locationState?.selectTab)) {
                setSelectedTab(locationState?.selectTab)
                dispatch(
                    topActions.setFilter({
                        ...filterSchedulesCondition,
                        status: locationState?.selectTab,
                    }),
                )
            } else {
                if (locationState) {
                    filterSchedulesCondition = {
                        ...filterSchedulesCondition,
                        status: locationState?.status || selectedTab,
                        admin_id: locationState?.admin_id || locationState?.admin_id == 0 ? locationState?.admin_id : filterSchedulesCondition.admin_id,
                        participants: locationState?.participants ? locationState?.participants : filterSchedulesCondition.participants,
                    }
                }

                dispatch(
                    topActions.setFilter(filterSchedulesCondition),
                )
            }
        }).catch((err) => {
            console.log(err)
        })
    },[locationState, currentUser])

    useEffect(() => {
        dispatch(topActions.setSchedulesList([]))
    }, [])

    useEffect(() => {
        dispatch(topActions.fetchSchedules(filterSchedules))
    }, [filterSchedules])

    return (
        <Container sx={{ p: '0 !important' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: '4px',
                }}
            >
                <Box
                    sx={{
                        typography: 'body1',
                        borderRadius: '20px',
                        padding: { xs: '20px', sm: '30px', md: '40px' },
                        background: 'rgba(255, 255, 255, 0.9)',
                        boxShadow: '0px 4px 120px rgba(0, 0, 0, 0.25)',
                        alignSelf: 'center',
                    }}
                >
                    {showAlert && showSnackBar(showAlert, alertMessage, alertSeverity)}
                    <TopSearch />
                    <Box sx={{ mt: { xs: '15px', sm: '40px' } }}>
                        <TabContext value={filterSchedules?.status}>
                            <Box 
                                sx={[
                                    styles.tabList, 
                                    {button: {
                                        fontWeight: '500',
                                        height: '35px',
                                        minHeight: '35px',
                                        minWidth: '70px',
                                        padding: {
                                            xs: '0 20px',
                                            sm: '0px ' + pdTab + 'px',
                                            md: '0px 40px',
                                        },
                                        position: 'relative',
                                    }}
                                ]}
                            >
                                <Tabs onChange={handleChangeTab} value={selectedTab}>
                                    {CONSTANTS.SCHEDULE_STATUS.map((scheduleStatus: { value: string; label: string }) => {
                                        return (
                                            <Tab
                                                key={scheduleStatus.value}
                                                sx={styles.title}
                                                label={<Typography sx={styles.text1}>{scheduleStatus.label}</Typography>}
                                                value={scheduleStatus.value}
                                            />
                                        )
                                    })}
                                </Tabs>
                            </Box>
                            {CONSTANTS.SCHEDULE_STATUS.map((scheduleStatus: { value: string; label: string }) => {
                                return (
                                    <TabPanel key={scheduleStatus.value} value={scheduleStatus.value} sx={styles.table}>
                                        <TopDataTable tabLabel={scheduleStatus.label}/>
                                    </TabPanel>
                                )
                            })}
                        </TabContext>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

const styles = {
    title: {
        position: 'relative',
        color: '#000000',
        fontSize: '15px',
        fontWeight: '700',
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    table: { p: 0 },
    tabList: {
        justifyContent: {
            xs: "center"
        },
        '.Mui-selected': {
            bgcolor: '#FFFF00',
            borderRadius: '12px',
            color: 'black !important',
        },
        '.MuiTabs-indicator': {
            display: 'none',
        },
        mb: '7px',
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
        },
        pt: {
            xs: '0px',
            sm: '20px',
        },
        pb: '10px',
        '.MuiTabs-flexContainer' : {
            justifyContent: {
                xs: "center",
                sm: "unset"
            },
            "button": {
                marginBottom: {
                    xs: "10px",
                    md: "0"
                }
            }
        },
    },
    text1: {
        fontWeight: '600',
        lineHeight: 1.5,
        fontSize: '15px',
        ':hover': {
            transform: 'scale(1.071)',
        },
    },
}

export default ScheduleList
