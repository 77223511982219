
import React from 'react'
import AppRoutes from './AppRoutes'
import { ThemeProvider } from '@mui/system'
import theme from './theme'
import { SnackBarProvider } from './components/Common/SnackBarContext'
import { store } from './app/store'
import { Provider } from 'react-redux'
import "./App.css"
function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SnackBarProvider>
                    <div className="App">
                        <AppRoutes />
                    </div>
                </SnackBarProvider>
            </ThemeProvider>
        </Provider>
    )
}

export default App
