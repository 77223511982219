import { ListParams, ListResponse, User } from '../models'
import { get, post, put, del } from './axiosClient'

const userApi = {
    me() {
        const url = '/users/me'
        return get(url)
    },
    getAll(params: any = {}): any {
        const url = '/users/'
        return get(url, { params })
    },
    getInternalUsers(params: any = {}) {
        const url = '/users/internal'
        return get(url, { params })
    },
    getExternalUsers(params: any = {}) {
        const url = '/users/external'
        return get(url, { params })
    },
    getSuggestedInvitee(params: any = {}) {
        const url = '/external-user/suggested-invitee'
        return get(url, { params })
    }
}

export default userApi
