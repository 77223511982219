import { Stack, Typography } from '@mui/material'

const ScheduleConfirmFooter = (props: any) => {
    return (
        <Stack
            sx={{
                position: 'absolute',
                bottom: '10px',
                fontFamily: 'Noto Sans JP',
                lineHeight: '21px',
                color: '#000',
                display: 'flex',
                flexDirection: 'row',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Typography
                sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    letterSpacing: '0rem',
                }}
            >
                ©︎
            </Typography>
            <Typography
                sx={{
                    fontSize: '12px',
                    mt: '2px',
                    mr: '2px',
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    letterSpacing: '0rem',
                }}
            >
                2024.
            </Typography>
            <Typography
                sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    letterSpacing: '0rem',
                }}
            >
                GLOBAL PRODUCE
            </Typography>
        </Stack>
    )
}

export default ScheduleConfirmFooter
