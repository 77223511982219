import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Outlet, useNavigate } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";
import { color } from "src/config/color";
import { useAppSelector } from "src/app/hooks";
import { useSnackBar } from "src/components/Common/SnackBarContext";
import { NewDyskeLogo } from "src/components/Icons";

const mdTheme = createTheme();

function AuthContent() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={[styles.background, { display: "flex", height: windowHeight }]}>
        <Box
          component="main"
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Grid item sx={styles.backgroundLogo}>
            <Box sx={styles.center}>
              <Typography 
                component={'div'}
                sx={{
                  width: '240px',
                }}
              >
                {/* <img
                  src={`${process.env.PUBLIC_URL}/images/new-dyske-logo.png`} 
                  alt="DYSKE" 
                  style={{ width: '240px', height: 'auto' }}
                /> */}
                <NewDyskeLogo width={240}/>
              </Typography>
            </Box>
          </Grid>
          <Grid item sx={styles.backgroundLogin}>
            <Outlet />
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const styles = {
  title: {
    whiteSpace: "nowrap",
    textAlign: "center",
    color: "#000000",
    letterSpacing: "0.18em",
    fontFamily: 'Noto Sans JP',
  },
  background: {
    width: "100%",
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("${process.env.PUBLIC_URL}/images/background-cover-auth-signin-new.png")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100%",
    position: "relative",
    overflowX: 'hidden',
  },
  backgroundLogo: {
    display: { xs: "none", lg: "block" },
    width: '50%',
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "25%",
    transform: "translate(-50%, -50%)",
  },
  backgroundLogin: {
    background: 'rgba(255, 255, 255, 0.9)',
    position: "relative",
    height: "100%",
    width: { xs: "100%", lg: "50%" },
    '@media (max-width: 1199px)': {
      height: '600px',
      margin: 'auto',
      maxWidth: '500px',
    },
    '@media (max-width: 960px)': {
      height: '480px',
      margin: 'auto',
      maxWidth: '480px',
    },
    '@media (max-width: 600px)': {
      maxHeight: '480px',
      width:'calc(100vw - 40px)',
      maxWidth: '400px',
    },
    '@media (max-width: 400px)': {
      height: '420px',
    },
  }
};

export function Auth() {
  const navigate = useNavigate();
  useEffect(() => {
    const isLoggedIn = Boolean(localStorage.getItem("access_token"));
    const isLoggedIn2 = Boolean(sessionStorage.getItem("access_token"));
    if (isLoggedIn || isLoggedIn2) {
      navigate("/top");
    }
    return () => {
      // check login
    };
  }, []);

    // Error handling global
    const showAlertGlobal = useAppSelector((state) => state.auth.showAlert);
    const alertMessageGlobal = useAppSelector((state) => state.auth.alertMessage);
    const alertSeverityGlobal = useAppSelector((state) => state.auth.alertSeverity);

    const { showSnackBar } = useSnackBar()

    useEffect(() => {
        if (showAlertGlobal && alertMessageGlobal !== '') {
            showSnackBar(true, alertMessageGlobal, alertSeverityGlobal)
        }
    }, [showAlertGlobal])

  return <AuthContent />;
}
