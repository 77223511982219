import { TextField } from '@mui/material'
export const TextInput = (props: any) => {
    const { error = false, multiline = false, rows = 1, customsx={} } = props

    return (
        <TextField
            InputProps={{
                rows: rows,
                multiline: multiline,
                inputComponent: multiline ? 'textarea' : '',
            }}
            fullWidth
            focused
            sx={[
                {
                    fieldset: {
                        border: error ? '1px solid red !important' : '1px solid #666 !important',
                        borderRadius: '15px',
                    },
                    '& ::placeholder': {
                        fontSize: '16px',
                        fontWeight: '400',
                    },
                    input: {
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '400',
                    },
                    textarea: {
                        color: '#000000',
                    },
                    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
                    {
                        transition: 'all 0s 50000s',
                    },
                    ...customsx,
                },
                !props?.multiline && {
                    '.MuiInputBase-root': {
                        height: '48px',
                    },
                },
            ]}
            {...props}
        />
    )
}
