import { Box, Tab, Typography, Container } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import {
    topActions,
    selectFilterSchedulesAttendee,
    selectShowAlert,
    selectAlertMessage,
    selectAlertSeverity,
} from './topSlice'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import { selectCurrentUser } from '../Auth/authSlice'
import AdminTable from './components/AdminTable'
import AttendeeTable from './components/AttendeeTable'
import { replaceGaroonName } from '../../helpers/helpers'
import useWindowDimensions from 'src/hook/useWindowDimensions'

const TopPage = () => {
    const dispatch = useAppDispatch()
    const showAlert = useAppSelector(selectShowAlert)
    const alertMessage = useAppSelector(selectAlertMessage)
    const alertSeverity = useAppSelector(selectAlertSeverity)
    const { showSnackBar } = useSnackBar()
    const { height, width } = useWindowDimensions();

    const filterSchedulesAttendee = useAppSelector(selectFilterSchedulesAttendee)

    const currentUser = useAppSelector(selectCurrentUser)
    const handleChangeTabAttendee = (event: React.SyntheticEvent, value: string) => {
        dispatch(
            topActions.setFilterSchedulesAttendee({
                ...filterSchedulesAttendee,
                page: 1,
                status: value,
            }),
        )
    }

    return (
        <Container sx={{ p: '0 !important' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: '4px',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: {
                            xs: "18px",
                            sm: "21px",
                            md:"24px"
                        },
                        width: '100%',
                        alignSelf: 'center',
                        mb: '28px',
                    }}
                >
                    {replaceGaroonName(currentUser, width)}さんが調整中の予定
                </Typography>
                <Box sx={styles.container}>
                    {showAlert && showSnackBar(showAlert, alertMessage, alertSeverity)}
                    <AdminTable />
                </Box>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: {
                            xs: "18px",
                            sm: "21px",
                            md:"24px"
                        },
                        width: '100%',
                        alignSelf: 'center',
                        mb: '28px',
                        mt: '60px',
                    }}
                >
                    {replaceGaroonName(currentUser, width)}さんが参加する予定
                </Typography>
                <Box sx={styles.container}>
                    <Box>
                        <TabContext value={filterSchedulesAttendee.status}>
                            <TabListBox onChange={handleChangeTabAttendee} />
                            <TabPanel value="ADJUSTING" sx={styles.table}>
                                <AttendeeTable />
                            </TabPanel>
                            <TabPanel value="FINALIZED" sx={styles.table}>
                                <AttendeeTable />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

const TabListBox = ({ onChange }: any) => {
    return (
        <Box sx={styles.tabListBox}>
            <TabList onChange={onChange}>
                <Tab sx={styles.title} label={<Typography sx={styles.text1}>調整中</Typography>} value="ADJUSTING" />
                <Tab sx={styles.title} label={<Typography sx={styles.text1}>調整済み</Typography>} value="FINALIZED" />
            </TabList>
        </Box>
    )
}

const styles = {
    container: {
        width: '100%',
        typography: 'body1',
        borderRadius: '20px',
        padding: { xs: '20px', sm: '30px', md: 5 },
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 4px 120px rgba(0, 0, 0, 0.25)',
        alignSelf: 'center',
    },
    title: {
        position: 'relative',
        color: '#000000',
        fontSize: '15px',
        fontWeight: '700',
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    table: { p: 0 },
    text1: {
        fontWeight: '700',
        lineHeight: 1.5,
        fontSize: '15px',
        ':hover': {
            transform: 'scale(1.071)',
        },
    },
    tabListBox: {
        '.Mui-selected': {
            bgcolor: '#FFFF00',
            borderRadius: '12px',
            color: 'black !important',
        },
        button: {
            fontWeight: '500',
            height: '35px',
            minHeight: '35px',
            minWidth: '70px',
            padding: {
                xs: '0 20px',
                sm: '0px 40px',
            },
            position: 'relative',
        },
        '.MuiTabs-indicator': {
            display: 'none',
        },
        mb: '7px',
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
        },
    },
}

export default TopPage
