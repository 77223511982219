import { Box } from '@mui/material'
import { CircleIcon, TriangleIcon, XIcon } from '../Icons'
import ClearIcon from '@mui/icons-material/Clear'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

const LightFieldByPoint = ({ point = 0, fill = '#FFFFFF', sx = {}, isConfirmPage = false }) => {
    const renderSwitch = () => {
        if (isConfirmPage) {
            switch (point) {
                case 5:
                    return (
                        <Box sx={sx}>
                            <PanoramaFishEyeIcon sx={[styles.circleIcon, { color: fill }]} />
                        </Box>
                    )
                case 3:
                    return (
                        <Box sx={{ mt: '-3px' }}>
                            <ChangeHistoryIcon sx={[styles.triangleIcon, { color: fill }]} />
                        </Box>
                    )
                case 0:
                    return (
                        <Box sx={{ mt: '6px' }}>
                            <ClearIcon sx={[styles.xIcon, { color: fill }]} />
                        </Box>
                    )
                // case 5:
                //     return (
                //         <Box sx={{ ml: '2px', mt: '8px' }}>
                //             <CircleIcon fill={fill} />
                //         </Box>
                //     )
                // case 3:
                //     return (
                //         <Box sx={{ mt: '2px' }}>
                //             <TriangleIcon fill={fill} />
                //         </Box>
                //     )
                // case 0:
                //     return (
                //         <Box sx={{ mt: '5px' }}>
                //             <XIcon fill={fill} />
                //         </Box>
                //     )
            }
        }

        switch (point) {
            case 5:
                return (
                    <Box sx={sx}>
                        <PanoramaFishEyeIcon sx={[styles.circleIcon, { color: fill }]} />
                    </Box>
                )
            case 3:
                return (
                    <Box sx={{ mt: '-3px' }}>
                        <ChangeHistoryIcon sx={[styles.triangleIcon, { color: fill }]} />
                    </Box>
                )
            case 0:
                return (
                    <Box sx={{ mt: '6px' }}>
                        <ClearIcon sx={[styles.xIcon, { color: fill }]} />
                    </Box>
                )
            case -1:
                return (
                    <Box sx={{ mt: '6px' }}>
                        <HorizontalRuleIcon sx={[styles.xIcon, { color: fill }]} />
                    </Box>
                )
        }
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            {renderSwitch()}
        </Box>
    )
}

const styles = {
    xIcon: {
        fontSize: '30px',
    },
    triangleIcon: {
        fontSize: '30px',
        verticalAlign: 'middle',
    },
    circleIcon: {
        fontSize: '26px',
        verticalAlign: 'middle',
    },
}
export default LightFieldByPoint
