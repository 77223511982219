import { unset } from 'lodash'
import { del, get, post, put } from './axiosClient'

const facilityApi = {
    getAll() {
        const url = '/facility'
        return get(url)
    },
    getFacilitiesByGroup(params: any = {}) {
        const url = '/facility/' + params.groupId + '/facilities'
        unset(params, 'groupId')
        return get(url, { params })
    },
    getFacilitiesInUsed(params: any = {}) {
        const url = '/facility/get-facilities-in-used'
        return post(url, params)
    },
}

export default facilityApi
