const HomeIconComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill || '#000'}
      d="M.004 7.284c0 .513.417.914.89.914h.888l-.02 4.562c0 .077.006.154.015.23v.459c0 .629.497 1.139 1.11 1.139h.445c.03 0 .061 0 .092-.003.039.003.078.003.117.003H5.11c.614 0 1.111-.51 1.111-1.14v-2.505a.9.9 0 0 1 .89-.91h1.777a.9.9 0 0 1 .89.91V13.45c0 .629.496 1.139 1.11 1.139h1.553c.042 0 .084-.003.125-.006.034.003.067.006.1.006h.445c.614 0 1.111-.51 1.111-1.14V10.26c0-.026 0-.054-.003-.08V8.198h.892c.5 0 .889-.398.889-.914a.937.937 0 0 0-.278-.683L8.6.238C8.405.038 8.183.01 7.99.01a.908.908 0 0 0-.584.2L.31 6.6c-.222.2-.334.427-.306.683Z"
    />
  </svg>
)
export default HomeIconComponent
