import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Collapse } from '@mui/material'
import { checkRoute } from '../../helpers/checkRouteName'

const ListItemCustom = ({ listItem, open, setOpenMobile, handlenavigate }: any) => {
    const { pathname } = useLocation()
    const [openPage, setOpenPage] = useState(false)
    return (
        <>
            {listItem.map((item: any, index: any) => {
                return (
                    <ListItem
                        key={item.name + index}
                        disablePadding
                        sx={{
                            display: 'block',
                            width: open ? '90%' : '',
                            borderRadius: '12px',
                            backgroundColor: 'transparent',
                        }}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2,
                            }}
                            onClick={() => setOpenPage(!openPage)}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                    marginLeft: open ? 0 : '-24px',
                                    padding: open ? '10px' : '0px',
                                    borderRadius: open ? '12px' : '0px',
                                    // backgroundColor: open ? 'rgba(245,245,245, 0.2)' : 'transparent',
                                    backgroundColor: open
                                        ? item.listSubItem.some((el: any) => el.name === checkRoute(pathname))
                                            ? '#ffff00'
                                            : 'rgba(245,245,245, 0.2)'
                                        : 'transparent',
                                    svg: {
                                        fill:
                                            open && item.listSubItem.some((el: any) => el.name === checkRoute(pathname))
                                                ? '#000'
                                                : '#fff',
                                    },
                                }}
                            >
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={item.name}
                                sx={{
                                    opacity: open ? 1 : 0,
                                    color: '#999',
                                    span: { fontSize: '15px', fontWeight: 'medium', lineHeight: 1 },
                                }}
                            />
                            {item.chevron
                                ? openPage
                                    ? open && <ExpandLessIcon fontSize="medium" style={{ color: '#fff' }} />
                                    : open && <ExpandMoreIcon fontSize="medium" style={{ color: '#fff' }} />
                                : ''}
                        </ListItemButton>
                        <Collapse in={openPage}>
                            {openPage &&
                                open &&
                                item.listSubItem.map((subItem: any, index2: any) => {
                                    return (
                                        <ListItemButton
                                            key={index2}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 3.5,
                                                borderRadius: '15px',
                                                background:
                                                    open && checkRoute(pathname) === subItem.name
                                                        ? 'linear-gradient(90deg, #444444 0%, rgba(68, 68, 68, 0) 99.77%)'
                                                        : 'transparent',
                                                filter: open
                                                    ? 'drop-shadow(0px 3.5px 5.5px rgba(0, 0, 0, 0.02))'
                                                    : 'none',
                                            }}
                                            onClick={() => {
                                                setOpenMobile && setOpenMobile(!open)
                                                handlenavigate(subItem.path)
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                    circle: {
                                                        color:
                                                            checkRoute(pathname) === subItem.name ? '#ffff00' : '#fff',
                                                    },
                                                }}
                                            >
                                                {subItem.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'∟ ' + subItem.name}
                                                sx={{
                                                    opacity: open ? 1 : 0,
                                                    color: '#fff',
                                                    ml: 3,
                                                    span: { fontSize: '14px' },
                                                }}
                                            />
                                        </ListItemButton>
                                    )
                                })}
                        </Collapse>
                    </ListItem>
                )
            })}
        </>
    )
}

export default ListItemCustom
