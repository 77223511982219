import { Grid, MenuItem, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { selectFilterSchedules, selectFilterInternalUsers, selectInternalUsers, topActions } from '../topSlice'
import { TextInput } from 'src/components/TextInput'
import SelectCustom from 'src/components/SelectCustom'
import { labelOfInput, labelOfInputAlign } from 'src/theme'
import { replaceGaroonName } from '../../../helpers/helpers'
import { selectCurrentUser } from 'src/pages/Auth/authSlice'
import useWindowDimensions from 'src/hook/useWindowDimensions'

const TopSearch = () => {
    const dispatch = useAppDispatch()
    const filterSchedules = useAppSelector(selectFilterSchedules)

    const filterInternalUsers = useAppSelector(selectFilterInternalUsers)
    const internalUsers = useAppSelector(selectInternalUsers)
    const { height, width } = useWindowDimensions();

    const currentUser = useAppSelector(selectCurrentUser)
    

    useEffect(() => {
        dispatch(topActions.setInternalUsers([]))
        dispatch(topActions.fetchInternalUser())
    }, [])

    useEffect(() => {
        dispatch(topActions.fetchInternalUser(filterInternalUsers))
    }, [filterInternalUsers])

    const handleChangeSelectedUser = (event: any) => {
        const value = event.target.value

        if (value === 0) {
            dispatch(
                topActions.setFilter({
                    ...filterSchedules,
                    page: 1,
                    participants: [],
                    firstView: false,
                }),
            )
            return
        }
        dispatch(
            topActions.setFilter({
                ...filterSchedules,
                page: 1,
                participants: [value],
                firstView: false,
            }),
        )
    }

    const handleChangeSelectedAdmin = (event: any) => {
        const value = event.target.value
        dispatch(
            topActions.setFilter({
                ...filterSchedules,
                page: 1,
                admin_id: value,
                firstView: false,
            }),
        )
    }

    const onChangeText = (text: string) => {
        dispatch(
            topActions.setFilter({
                ...filterSchedules,
                page: 1,
                subject: text,
                firstView: false,
            }),
        )
    }

    return (
        <Grid container item sx={styles.container}>
            <Grid
                item
                sx={{
                    '@media (max-width: 650px)': {
                        width: '100%',
                    },
                    '@media (min-width: 650px) and (max-width: 900px)': {
                        width: '35%',
                    },
                    '@media (min-width: 900px) and (max-width: 1300px)': {
                        width: '40%',
                        maxWidth: '400px',
                    },
                    '@media (min-width: 1300px)': {
                        width: '30%',
                        maxWidth: '400px',
                    },
                }}
            >
                <Stack spacing={1}>
                    <Typography sx={{ ...labelOfInput, ...labelOfInputAlign }}>予定タイトル</Typography>
                    <TextInput
                        value={filterSchedules.subject}
                        onChange={(event: any) => {
                            onChangeText(event.target.value)
                        }}
                    />
                </Stack>
            </Grid>
            <Grid
                sx={{
                    display: 'flex',
                    columnGap: {
                        xs: "20px",
                        sm: "36px"
                    },
                    rowGap: '20px',
                    '@media (max-width: 650px)': {
                        width: '100%',
                    },
                    '@media (min-width: 650px) and (max-width: 900px)': {
                        width: '55%',
                    },
                    '@media (min-width: 900px) and (max-width: 1300px)': {
                        width: '40%',
                        maxWidth: '400px',
                    },
                    '@media (min-width: 1300px)': {
                        width: '40%',
                        maxWidth: '400px',
                    },
                }}
            >
                <Grid item xs={6} sm={6}>
                    <Stack spacing={1}>
                        <Typography sx={{ ...labelOfInput, ...labelOfInputAlign }}>参加者</Typography>
                        <SelectCustom
                            value={filterSchedules.participants.length > 0 ? filterSchedules.participants[0] : 0}
                            onChange={handleChangeSelectedUser}
                        >
                            <MenuItem value={0}>全員</MenuItem>
                            {internalUsers.length > 0 &&
                                internalUsers.map((user: any, index: any) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {replaceGaroonName(user, width)}
                                    </MenuItem>
                                ))}
                        </SelectCustom>
                    </Stack>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Stack spacing={1}>
                        <Typography sx={{ ...labelOfInput, ...labelOfInputAlign }}>調整者</Typography>
                        <SelectCustom value={filterSchedules.admin_id} onChange={handleChangeSelectedAdmin}>
                            <MenuItem value={0}>全員</MenuItem>
                            {internalUsers.length > 0 &&
                                internalUsers.map((user: any, index: any) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {replaceGaroonName(user, width)}
                                    </MenuItem>
                                ))}
                        </SelectCustom>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TopSearch

const styles = {
    container: {
        mb: 4,
        ml: '0px',
        columnGap: '36px',
        rowGap: '20px',
        // flexWrap: { xs: 'wrap', md: 'nowrap' },
        flexWrap: 'wrap'
    },
}
