export function formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: false, // Use 24-hour format
    }
    const formattedDate: string = date.toLocaleString('ja-JP', options)
    // Adjust the order of the date components and remove the comma
    const [year, month, dayAndTime] = formattedDate.split('/')
    const [day, time] = dayAndTime.split(' ')
    
    const formattedResult: string = `${year.trim()}/${month.trim()}/${day.trim()} ${time.trim()}`

    return formattedResult
}

export function formatTargetDatePeriod(startTimeString: any, endTimeString: any) {
    const startTime = new Date(startTimeString)
    const endTime = new Date(endTimeString)
    const startDateString = `${startTime.getFullYear()}/${(startTime.getMonth() + 1)
        .toString()
        }/${startTime.getDate().toString()}`

    const endDateString = `${endTime.getFullYear()}/${(endTime.getMonth() + 1).toString()}/${endTime
        .getDate()
        .toString()
        }`

    // if (startDateString === endDateString) {
    //     formattedTimeRange = [
    //         `${startDateString}`,
    //         `${startTime.getHours().toString().padStart(2, '0')}:${startTime
    //             .getMinutes()
    //             .toString()
    //             .padStart(2, '0')} ~ ${endTime.getHours().toString().padStart(2, '0')}:${endTime
    //             .getMinutes()
    //             .toString()
    //             .padStart(2, '0')}`,
    //     ]
    // } else {
        // formattedTimeRange = [`${startDateString}`, `~ ${endDateString}`]
    // }
    const formattedTimeRange = `${startDateString}〜 ${endDateString}`

    return formattedTimeRange
}

export function formatTargetDatePeriod2(startTimeString: any, endTimeString: any) {
    const startTime = new Date(startTimeString)
    const endTime = new Date(endTimeString)
    const startDateString = `${startTime.getFullYear()}/${(startTime.getMonth() + 1)
        .toString()
        }/${startTime.getDate().toString()}`

    const endDateString = `${endTime.getFullYear()}/${(endTime.getMonth() + 1).toString()}/${endTime
        .getDate()
        .toString()
        }`

    if (startDateString == endDateString) {
        return startDateString;
    }

    const formattedTimeRange = `${startDateString}〜 ${endDateString}`

    return formattedTimeRange
}