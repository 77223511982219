import { createTheme } from '@mui/material/styles';
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    xsm: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#63a4ff',
      dark: '#004ba0',
      contrastText: '#000000',
    },
    secondary: {
      main: '#ffff00',
      light: '#ff5983',
      dark: '#c51162',
      contrastText: '#000000',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#000000',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    text: {
      primary: '#000000',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  },
  typography: {
    fontFamily: '"Noto Sans JP","Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Noto Sans JP","Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '6rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    // Các kiểu chữ khác...
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      xsm: 400,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
});

export const labelOfInput = {
  lineHeight: 1.4,
  fontSize: '15px',
  fontWeight: '500',
}


export const labelOfInputAlign = { 
  marginLeft: '7px !important',
}

export const inputField = {
  fontSize: '16px',
  fontWeight: '400'
}

export const tableHeaderText = {
  fontSize: '15px',
  fontWeight: '500',
  whiteSpace: 'nowrap',
}

export const tableBodyText = {
  fontSize: '16px',
  fontWeight: '400'
}

export const buttonStyle = {
  height: '48px',
  minWidth: '120px',
  padding: '0 20px',
  fontSize: '15px',
  fontWeight: '500',
  border: '1px solid #000000',
  borderRadius: '12px',
  color: '#000000',
  bgcolor: 'inherit',
  boxShadow: 'none',
  ':hover': {
    bgcolor: 'inherit',
  },
}

export const smallButtonStyle = {
  minWidth: '100px',
  maxWidth: '120px',
  padding: '0 12px',
  height: 'auto',
  minHeight: '48px',
}

export const buttonSubmitStyle = {
  border: 'none',
  background: theme.palette.secondary.main,
  ':hover': {
      bgcolor: theme.palette.secondary.main,
  },
}

export const tableRowAlign = {
  padding: '8px 16px',
}

export default theme;