import { ListParams, ListResponse, User } from '../models'
import { get, post, put, del } from './axiosClient'

const authApi = {
    login(data: any = {}): any {
        const url = '/auth/signin'
        return post(url, data)
    },
}

export default authApi
