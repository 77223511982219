import { checkRoute } from '../../helpers/checkRouteName'
import { Box, Link, Typography } from '@mui/material'
import { useLocation, Link as RouterLink, useNavigate } from 'react-router-dom'

function CustomBreadcrumbs({ windowWidth }: any) {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    return (
        <Box sx={styles.container}>
            <Link underline="hover" color={'#0075FF'} sx={{ fontSize: '14px', fontWeight: '400', cursor: "pointer" }} onClick={() => {navigate('/top')}}>
                TOP
            </Link>
            {pathname !== '/top' && <Typography sx={{ fontSize: '14px' }}>/</Typography>}
            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{checkRoute(pathname)}</Typography>
        </Box>
    )
}

const styles = {
    container: { display: 'flex', gap: '4px' },
}

export default CustomBreadcrumbs
