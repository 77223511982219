import { Box, IconButton, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { authActions, selectCurrentUser } from 'src/pages/Auth/authSlice'
import PersonIcon from '@mui/icons-material/Person'
import { useNavigate } from 'react-router-dom'
import { replaceGaroonName } from '../../helpers/helpers'

const UserHeaderMenu = ({ windowWidth }: any) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const user = useAppSelector(selectCurrentUser)
    const [openMenu, setOpenMenu] = useState(false)
    const menuRef = useRef<any>()

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setOpenMenu(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleOpenMenu = (event: any) => {
        setOpenMenu(!openMenu)
    }

    const logout = () => {
        setOpenMenu(false)
        dispatch(authActions.logout())
        navigate('/')
    }
    return (
        <Box
            sx={{
                display: { xs: 'flex' },
                justifyContent: 'flex-end',
                flexGrow: 1,
                alignItems: 'center',
                pr: {
                    xs: 0,
                    md: "16px"
                },
            }}
            ref={menuRef}
        >
            <Box sx={{ position: 'relative', display: 'flex' }}>
                <IconButton onClick={(event) => handleOpenMenu(event)} sx={{ p: 0, color: '#444444' }}>
                    <PersonIcon />
                    <Typography
                        sx={{
                            marginLeft: {
                                xs: 0,
                                md: "5px"
                            },
                            fontSize: '15px',
                            fontWeight: 'regular',
                            lineHeight: 1.5,
                        }}
                        color={'#444444'}
                    >
                        {windowWidth >= 800 && replaceGaroonName(user)}
                    </Typography>
                </IconButton>
                {openMenu && (
                    <Box
                        sx={{
                            '@media (max-width: 600px)': {
                                ml: '-27px',
                            },
                            position: 'absolute',
                            top: '30px',
                            bgcolor: '#FFFFFF',
                            alignSelf: 'center',
                            left: '50%',
                            borderRadius: '8px',
                            transform: 'translate(-50%, 0%)',
                            padding: '8px 16px',
                            border: '0.5px solid black',
                        }}
                    >
                        <Typography onClick={logout} sx={{ fontSize: '14px', cursor: 'pointer', whiteSpace: 'nowrap' }}>
                            ログアウト
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default UserHeaderMenu
