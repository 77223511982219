import { Select, Box } from '@mui/material'
import { useState } from 'react'

const SelectCustom = ({ value, onChange, children, error = false, sx = {}, disabled = false }: any) => {
    const [open, setOpen] = useState<boolean>(false)
    return (
        <Select
            disabled={disabled}
            open={open}
            value={value}
            onChange={onChange}
            fullWidth
            sx={[
                sx,
                {
                    borderRadius: '15px',
                },
                disabled && {
                    cursor: "not-allowed !important",
                    backgroundColor: "#eaeaea",
                    '& .MuiInputBase-input': {
                        color: '#070707',
                        cursor: "not-allowed",
                        WebkitTextFillColor: '#070707'
                    },
                    '& img': {
                        cursor: "not-allowed",
                    }
                },
                {
                    '.MuiInputBase-input': {
                        fontSize: '16px',
                        lineHeight: 1.5,
                        fontWeight: '400',
                    },
                    '& > div': {
                        pr: '7px !important',
                    },
                    height: '48px',
                },
                error
                    ? {
                          fieldset: {
                              border: '1px solid red !important',
                              borderRadius: '15px',
                          },
                      }
                    : {
                          fieldset: {
                              border: '1px solid #666 !important',
                              borderRadius: '15px',
                          },
                      },
            ]}
            IconComponent={() => (
                <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/images/icons/DownIcon.svg`}
                    onClick={() => setOpen(!open)}
                    sx={[
                        {
                            width: '30px',
                            mr: '12px',
                            transform: open ? 'rotate(180deg)' : 'none',
                            cursor: 'pointer',
                            marginTop: 0.5,
                        },
                    ]}
                />
            )}
            MenuProps={{
                style: {
                    maxHeight: '500px',
                },
                sx: {
                    '.MuiMenuItem-root': {
                        minHeight: { xs: 'auto' },
                    },
                    '.MuiPaper-root': {
                        borderRadius: '12px',
                        transformOrigin: '50% 0',
                        maxHeight: 'calc(100% + 58px)',
                        li: {
                            fontSize: '16px',
                            lineHeight: 1.5,
                            fontWeight: '400',
                        },
                    },
                    '.MuiList-root': {
                        color: '#000000',
                    },
                    '.Mui-selected': {
                        backgroundColor: 'transparent !important',
                    },
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(255, 236, 139, 0.3)',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 236, 139, 0.5)',
                        },
                    },
                },
            }}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
        >
            {children}
        </Select>
    )
}

export default SelectCustom
