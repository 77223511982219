import { useEffect, useRef } from 'react'
import { Button, FormHelperText, Stack, Switch, TextField } from '@mui/material'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { authActions, selectCurrentUser, selectError } from './authSlice'
import { color } from 'src/config/color'

// const EMAIL_REGX =
//     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const schema = yup.object().shape({
    user_name: yup
        .string()
        // .matches(EMAIL_REGX, '有効なメールアドレスを入力してください。')
        .required('必ず入力してください。'),
    password: yup.string().required('必ず入力してください。'),
    remember: yup.boolean(),
})

export default function SigninPage() {
    const dispatch = useAppDispatch()
    let navigate = useNavigate()

    const {
        control,
        handleSubmit,
        getValues,
        reset,
        formState: { isSubmitting, errors, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            user_name: '',
            password: '',
            remember: true,
        },
        resolver: yupResolver(schema),
    })

    const currentUser = useAppSelector(selectCurrentUser)
    const errorFromServer = useAppSelector(selectError)

    useEffect(() => {
        if (currentUser && (localStorage.getItem('access_token') || sessionStorage.getItem('access_token'))) {
            navigate('/top')
        }
    }, [currentUser])

    const handleFormSubmit = async (data: any) => {
        try {
            dispatch(authActions.login(data))
        } catch (error) {
            if (error instanceof Error) {
                console.log(error)
            } else {
                console.log('Unexpected error', error)
            }
        }
    }

    return (
        <Box 
            sx={styles.container}
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleFormSubmit)}
        >
            <Box sx={{ mb: '28px' }}>
                <Typography sx={styles.title}>LOGIN</Typography>
            </Box>

            <Box sx={styles.userName}>
                <Controller
                    name="user_name"
                    control={control}
                    defaultValue={getValues('user_name')}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Box>
                            <Typography sx={styles.label}>サイボウズID</Typography>
                            <TextField
                                placeholder="サイボウズIDを入力"
                                sx={styles.input}
                                onChange={(event: any) => {
                                    onChange(event.target.value.toString())
                                    dispatch(authActions.setError(''))
                                }}
                            />
                            {error ? (
                                <FormHelperText sx={styles.error} error>
                                    {error.message}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </Box>
                    )}
                />
            </Box>
            <Box sx={styles.password}>
                <Controller
                    name="password"
                    control={control}
                    defaultValue={getValues('password')}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                            <Typography sx={styles.label}>パスワード</Typography>
                            <TextField
                                type="password"
                                placeholder="パスワードを入力"
                                sx={styles.input}
                                onChange={(event: any) => {
                                    onChange(event.target.value.toString())
                                    dispatch(authActions.setError(''))
                                }}
                            />
                            {error && (
                                <FormHelperText sx={styles.error} error>
                                    {error.message}
                                </FormHelperText>
                            )}
                            {errorFromServer !== '' && (
                                <FormHelperText sx={styles.error} error>
                                    {errorFromServer}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Box>
            <Box sx={{ display: 'flex', m: '18px 0' }}>
                <Controller
                    name="remember"
                    control={control}
                    defaultValue={getValues('remember')}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                            <Switch 
                                checked={value} 
                                onChange={() => {
                                    onChange(!value)
                                }} 
                                sx={styles.remember}
                            />
                            <Typography sx={styles.rememberText}>ログインを維持する</Typography>
                        </>
                    )}
                />
            </Box>

            <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                <Button type='submit' sx={styles.buttonSubmit} onClick={handleSubmit(handleFormSubmit)}>
                    <Typography sx={[styles.center, styles.textSubmit]}>ログイン</Typography>
                </Button>
            </Stack>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'absolute',
        top: '50%',
        left: { xs: '50%', lg: '0' },
        transform: { xs: 'translate(-50%, -50%)', lg: 'translate(0, -50%)' },
        ml: { xs: '0px', lg: '100px' },
        width: '70%',
        '@media (min-width: 1200px)': {
            width: '50%',
        },
        '@media (max-width: 600px)': {
            width: 'calc(100% - 60px)',
        },
    },
    title: {
        color: '#000000',
        fontSize: {
            xs: '24px',
            sm: '28px',
            md: '32px',
        },
        fontWeight: '700',
        lineHeight: '39px',
        letterSpacing: '0em',
        textAlign: 'left',
        fontFamily: 'Noto Sans JP'
    },
    userName: {
        position: 'relative',
        width: '100%',
        mb: '24px',
    },
    password: {
        width: '100%',
        position: 'relative',
    },
    inputPassword: {
        bgcolor: '#e8f0fe',
        width: '246px',
        borderLeft: '2px solid rgb(224,232,232)',
    },
    buttonSubmit: {
        width: '100%',
        height: '45px',
        position: 'relative',
        borderRadius: '12px',
        cursor: 'pointer',
        bgcolor: '#0075FF',
        '&: hover': {
            transform: 'scale(1.02)',
            bgcolor: '#0075FF',
            WebkitTransition: 'transform 0.3s ease-in-out',
        },
    },
    textSubmit: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontFamily: 'Noto Sans JP',
        fontSize: {
            xs: '15px',
            md: '16px',
        },
        fontWeight: '700',
        lineHeight: '21px',
        letterSpacing: '0em',
    },
    center: {
        position: 'absolute',
        margin: 'auto',
    },
    error: { position: 'absolute', bottom: '-25px', left: '5px', width: '100%' },
    input: {
        ml: '0px',
        mt: '5px',
        input: {
            color: '#000000',
            fontSize: {
                xs: '15px',
                sm: '16px',
            },
            fontWeight: '500',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            ml: '0px',
            fontFamily: 'Noto Sans JP',
        },
        '.MuiInputBase-root ': {
            height: '48px',
        },
        'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
            {
                transition: 'all 0s 50000s',
            },
        fieldset: {
            border: 'none',
        },
        width: '100%',
        height: '48px !important',
        '& ::placeholder': {
            opacity: 1,
            color: '#A0AEC0',
        },

        background: '#FFFFFF',
        backgroundClip: 'padding-box',
        border: 'solid $border transparent',
        borderRadius: '20px',
        '&:before': {
            content: "''",
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: -1,
            margin: '-2px',
            borderRadius: 'inherit',
            background:
                'radial-gradient(54.8% 53% at 50% 50%, #999999 0%, rgba(21, 21, 21, 0) 100%), radial-gradient(60% 51.57% at 50% 50%, #999999 0%, rgba(88, 44, 255, 0) 100%), linear-gradient(117.45deg, rgba(255, 255, 255, 0) -3.91%, rgba(255, 255, 255, 0.039) 75.27%), radial-gradient(69.43% 69.43% at 50% 50%, #404040 0%, rgba(255, 255, 255, 0) 100%);',
        },
    },
    remember: {
        transform: 'scale(0.8)',
        '& .MuiSwitch-track': {
            borderRadius: '22px',
        },
        p: '6px',
        ml: '-10px',
        '& .MuiSwitch-switchBase': {
            '& + .MuiSwitch-track': {
                opacity: 1,
                bgcolor: 'rgb(45, 55, 72)',
            },
            '&.Mui-checked': {
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    bgcolor: 'rgba(0, 117, 255, 1) !important',
                },
            },
        },
    },
    rememberText: {
        padding: '10px 0 10px 5px',
        writingMode: 'vertical',
        textOrientation: 'mixed',
        fontFamily: 'Noto Sans JP',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#000000',
    },
    label: {
        color: '#000000',
        ml: '5px',
        fontSize: {
            xs: '14px',
            sm: '15px',
        },
        lineHeight: '20px',
        fontFamily: 'Noto Sans JP'
    },
}
