import { Box, Container } from '@mui/material'
import { useAppSelector } from 'src/app/hooks'
import { 
    selectShowAlert, 
    selectAlertMessage, 
    selectAlertSeverity,
} from '../../Top/topSlice'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import GroupDataTable from '../components/GroupTable'

const GroupList = () => {
    const showAlert = useAppSelector(selectShowAlert)
    const alertMessage = useAppSelector(selectAlertMessage)
    const alertSeverity = useAppSelector(selectAlertSeverity)
    const { showSnackBar } = useSnackBar()

    return (
        <Container sx={{ p: '0 !important' }}>
            <Box sx={styles.tableContainer} >
                <Box sx={styles.tableBox} >
                    {showAlert && showSnackBar(showAlert, alertMessage, alertSeverity)}
                    <Box sx={styles.tableContent} >
                        <GroupDataTable />
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

const styles = {
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        mt: '4px',
    },
    tableBox: {
        typography: 'body1',
        borderRadius: '20px',
        padding: { xs: '20px', sm: '30px', md: '40px' },
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 4px 120px rgba(0, 0, 0, 0.25)',
        alignSelf: 'center',
    },
    tableContent: {
        // mt: { xs: '15px', sm: '40px' }
    }
}

export default GroupList
