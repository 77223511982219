import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
    <BrowserRouter basename={process.env.REACT_APP_BASE_URI ?? '/'}>
        <CssBaseline />
        <App />
    </BrowserRouter>,
)
