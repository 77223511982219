import { get, post, put, del, query_params_string } from './axiosClient'

const adjustmentApi = {
    create(data: any = {}): any {
        const url = '/adjustment/create'
        return post(url, data)
    },

    getListAdjustmentByScheduleId(sid: any) {
        const url = `/adjustment/${sid}`
        return get(url)
    },

    getListHolidays(params: any) {
        const url = `/holidays/`
        return query_params_string(url, params)
    },

    getListScheduleTags() {
        const url = `/schedule-tag`
        return get(url)
    },
    
    getAdjustmentTimeByScheduleId(sid: any) {
        const url = `/adjustment/get-time/${sid}`
        return get(url)
    },
}

export default adjustmentApi
