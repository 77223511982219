const ArrowLeftIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={52} height={51} fill="none" {...props}>
        <g filter="url(#a)">
            <path
                fill="#0F1535"
                d="M6 14C6 7.373 11.373 2 18 2h16c6.627 0 12 5.373 12 12v16c0 6.627-5.373 12-12 12H18c-6.627 0-12-5.373-12-12V14Z"
            />
            <path
                stroke="#E2E8F0"
                strokeOpacity={0.3}
                strokeWidth={0.5}
                d="M6.25 14C6.25 7.51 11.51 2.25 18 2.25h16c6.49 0 11.75 5.26 11.75 11.75v16c0 6.49-5.26 11.75-11.75 11.75H18c-6.49 0-11.75-5.26-11.75-11.75V14Z"
            />
        </g>
        <path
            fill="#fff"
            d="M16.419 23.645a1.447 1.447 0 0 1 0-2.035l7.143-7.189a1.424 1.424 0 0 1 2.022 0 1.446 1.446 0 0 1 0 2.036l-4.71 4.735h13.697c.79 0 1.429.643 1.429 1.438 0 .795-.638 1.438-1.429 1.438H20.88l4.7 4.735a1.447 1.447 0 0 1 0 2.036 1.425 1.425 0 0 1-2.022 0l-7.143-7.19.005-.004Z"
        />
        <defs>
            <filter
                id="a"
                width={51}
                height={51}
                x={0.5}
                y={0}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy={3.5} />
                <feGaussianBlur stdDeviation={2.75} />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_580_2363" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_580_2363" result="shape" />
            </filter>
        </defs>
    </svg>
)
export default ArrowLeftIcon
