export const CONSTANTS = {
    DEMO_USERS: [
        {
            id: '3',
            name: 'Ngoc Nguyen Bao',
            code: 'ngoc@saiko-software.com',
        },
        {
            id: '5',
            name: 'Hung',
            code: 'hung.nguyen@saiko-software.com',
        },
        {
            id: '6',
            name: 'Quang',
            code: 'quanganh.nguyendinh@saiko-software.com',
        },
    ],
    DEMO_GROUP: [
        {
            value: 0,
            label: '全社員',
        },
        {
            value: 1,
            label: '組織一覧',
        },
        {
            value: 2,
            label: 'Saiko',
        },
        {
            value: 3,
            label: 'Web',
        },
    ],
    ROWS: [
        {
            id: 0,
            candidate_date_time: '2023/12/8(水) 10:00~(45分)',
            user: [
                {
                    id: '1',
                    level: 0,
                },
                {
                    id: '2',
                    level: 3,
                },
                {
                    id: '3',
                    level: 5,
                },
                {
                    id: '4',
                    level: 3,
                },
                {
                    id: '5',
                    level: 3,
                },
                {
                    id: '6',
                    level: 0,
                },
            ],
            point: 14,
        },
        {
            id: 1,
            candidate_date_time: '2023/12/8(水) 11:00~(45分)',
            user: [
                {
                    id: '1',
                    level: 0,
                },
                {
                    id: '2',
                    level: 3,
                },
                {
                    id: '3',
                    level: 5,
                },
                {
                    id: '4',
                    level: 3,
                },
                {
                    id: '5',
                    level: 3,
                },
                {
                    id: '6',
                    level: 0,
                },
            ],
            point: 14,
        },
        {
            id: 3,
            candidate_date_time: '2023/12/8(水) 13:00~(45分)',
            user: [
                {
                    id: '1',
                    level: 3,
                },
                {
                    id: '2',
                    level: 3,
                },
                {
                    id: '3',
                    level: 0,
                },
                {
                    id: '4',
                    level: 5,
                },
                {
                    id: '5',
                    level: 5,
                },
                {
                    id: '6',
                    level: 5,
                },
            ],
            point: 21,
        },
        {
            id: 2,
            candidate_date_time: '2023/12/8(水) 12:00~(45分)',
            user: [
                {
                    id: '1',
                    level: 3,
                },
                {
                    id: '2',
                    level: 0,
                },
                {
                    id: '3',
                    level: 5,
                },
                {
                    id: '4',
                    level: 3,
                },
                {
                    id: '5',
                    level: 0,
                },
                {
                    id: '6',
                    level: 5,
                },
            ],
            point: 16,
        },
        {
            id: 4,
            candidate_date_time: '2023/12/8(水) 13:00~(45分)',
            user: [
                {
                    id: '1',
                    level: 3,
                },
                {
                    id: '2',
                    level: 3,
                },
                {
                    id: '3',
                    level: 0,
                },
                {
                    id: '4',
                    level: 5,
                },
                {
                    id: '5',
                    level: 5,
                },
                {
                    id: '6',
                    level: 5,
                },
            ],
            point: 21,
        },
    ],
    SCHEDULE_TYPE: [
        {
            // null
            key: 0,
            label: '---',
            score: 3,
        },
        {
            //meeting
            key: 1,
            label: '打合',
            score: 0,
        },
        {
            //visit
            key: 2,
            label: '来訪',
            score: 0,
        },
        {
            key: 3,
            label: '往訪',
            score: 0,
        },
        {
            //business trip
            key: 4,
            label: '出張',
            score: 0,
        },
        {
            //holiday
            key: 5,
            label: '休み',
            value: 'HOLIDAY',
            score: 3,
        },
    ],
    SCHEDULED_TIME: [
        {
            value: 15,
            label: '15分',
        },
        {
            value: 30,
            label: '30分',
        },
        {
            value: 45,
            label: '45分',
        },
        {
            value: 60,
            label: '60分',
        },
        {
            value: 90,
            label: '90分',
        },
        {
            value: 120,
            label: '120分',
        },
        {
            value: 150,
            label: '150分',
        },
        {
            value: 180,
            label: '180分',
        },
        {
            value: 1439,
            label: '終日',
        },
    ],
    MARGIN_TIME: [
        {
            value: 0,
            label: '0分',
        },
        {
            value: 15,
            label: '15分',
        },
        {
            value: 30,
            label: '30分',
        },
        {
            value: 45,
            label: '45分',
        },
        {
            value: 60,
            label: '60分',
        },
        {
            value: 90,
            label: '90分',
        },
        {
            value: 120,
            label: '120分',
        },
    ],
    TIME_FRAME: [
        {
            value: 15,
            label: '15分刻み',
        },
        {
            value: 30,
            label: '30分刻み',
        },
        {
            value: 60,
            label: '60分刻み',
        },
    ],
    MENU_PROPS: {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    },
    FONT_SIZE: {
        TITLE: '18px',
    },
    EVENT_TYPE: {
        ALL_DAY: 'ALL_DAY',
        REGULAR: 'REGULAR',
    },
    DAY_OF_WEEK: ['日', '月', '火', '水', '木', '金', '土'],
    SCHEDULE_STATUS: [
        { value: 'ADJUSTING', label: '調整中' },
        { value: 'FINALIZED', label: '調整済み' },
        { value: 'DRAFT', label: '下書き' },
        { value: 'PAST', label: '過去の履歴' },
        { value: 'CANCELLED', label: 'キャンセル' },
    ],
    SCHEDULE_TAG_COLOR: {
        RED: 'rgb(240,47,50)',
        ORANGE: 'rgb(234,127,8)',
    },
    ORGANIZATION_ALL: {
        NAME: '全社',
    }
}

export enum CandidateTime {
    ITEM_PER_PAGE = 20,
}

export const CANDIDATE_TIME_SESSION_KEY = 'candidate_time_selected'
