import { PayloadAction } from '@reduxjs/toolkit'
import { call, fork, take, put, takeLatest } from 'redux-saga/effects'
import { authActions, LoginPayload } from './authSlice'
import authApi from 'src/api/authApi'

function* handleLogin(action: any): any {
    try {
        if (!action.payload) {
            return
        }

        const data = {
            ...action.payload,
        }
        const response = yield call(authApi.login, data)
        if (response.success) {
            if (action.payload?.remember) {
                localStorage.setItem('access_token', response.user.access_token)
            } else {
                sessionStorage.setItem('access_token', response.user.access_token)
            }
            yield put(authActions.loginSuccess(response))
        } else {
            yield put(authActions.loginFailed(response))
        }
    } catch (error) {
        console.log(error)
        yield put(authActions.logout())
    }
}

function* handleLogout() {
    console.log('handle logout')
    localStorage.removeItem('access_token')
    sessionStorage.removeItem('access_token')
}

function* watchLoginFlow() {
    while (true) {
        const isLoggedIn = Boolean(localStorage.getItem('access_token'))
        const isLoggedIn2 = Boolean(sessionStorage.getItem('access_token'))
        if (!isLoggedIn && !isLoggedIn2) {
            const action: PayloadAction<LoginPayload> = yield take(authActions.login.type)
            yield fork(handleLogin, action.payload)
        }

        yield take(authActions.logout.type)
        yield call(handleLogout)
    }
}

export default function* authSaga() {
    yield fork(watchLoginFlow)
    yield takeLatest(authActions.login, handleLogin)
}
