import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Order, Status, User } from '../../models'

export interface SchedulePayload {
    data: TopState
    meta: {} | null
}

export interface FilterTopPage {
    subject?: string
    participants?: User[]
    admin_id?: number
    status?: Status
    page?: number
    limit?: number
    order?: Order
    orderBy?: string
    flag?: boolean
    showAll?: number
    firstView?: boolean
}

export interface TopState {
    internalUsers: any[]
    filterInternalUsers: {
        page: number
        limit: number
    }
    loadingInternalUsers: boolean
    metaInternalUsers: {}

    loadingSchedules: boolean
    schedules: any[]
    filterSchedules: FilterTopPage
    metaSchedules: {}

    filterSchedulesAdmin: FilterTopPage
    schedulesAdmin: any[]
    metaSchedulesAdmin: {}
    loadingSchedulesAdmin: boolean

    filterSchedulesAttendee: FilterTopPage
    schedulesAttendee: any[]
    metaSchedulesAttendee: {}
    loadingSchedulesAttendee: boolean

    submitSuccess: boolean
    showAlert: boolean
    alertMessage: string
    alertSeverity: string

    scheduleNewUpdate: any
    scheduleFinalized: any

    // Highlight new cancel schedule
    scheduleNewCancel: any
}

const initialState: TopState = {
    loadingInternalUsers: false,
    filterInternalUsers: {
        page: 1,
        limit: 10,
    },
    internalUsers: [],
    metaInternalUsers: {},

    loadingSchedules: false,
    schedules: [],
    filterSchedules: {
        subject: '',
        participants: [],
        admin_id: 0,
        status: Status.ADJUSTING,
        page: 1,
        limit: 10,
        order: Order.DESC,
        orderBy: 'id',
        flag: false,
        showAll: 2,
        firstView: true,
    },
    schedulesAdmin: [],
    metaSchedules: {},

    loadingSchedulesAdmin: false,
    filterSchedulesAdmin: {
        status: Status.ADJUSTING,
        page: 1,
        limit: 10,
        order: Order.DESC,
        orderBy: 'id',
        flag: false,
        showAll: 0,
    },
    schedulesAttendee: [],
    metaSchedulesAdmin: {},
    loadingSchedulesAttendee: false,

    filterSchedulesAttendee: {
        status: Status.ADJUSTING,
        page: 1,
        limit: 10,
        order: Order.DESC,
        orderBy: 'id',
        flag: false,
        showAll: 1,
    },
    metaSchedulesAttendee: {},
    submitSuccess: false,
    showAlert: false,
    alertMessage: '',
    alertSeverity: 'success',

    scheduleNewUpdate: undefined,
    scheduleFinalized: undefined,

    // Highlight new cancel schedule
    scheduleNewCancel: undefined,
}

const topSlice = createSlice({
    name: 'top',
    initialState,
    reducers: {
        // fetch data top page
        fetchSchedules(state, action) {
            state.loadingSchedules = true
        },
        fetchSchedulesSuccess(state: any, action: PayloadAction<any>) {
            // if (state.filterSchedules.page === 1) {
            //     state.schedules = [...action.payload.data]
            // } else {
            //     state.schedules = [...state.schedules, ...action.payload.data]
            // }
            let currentData = [...action.payload.data]
            if (state.scheduleNewUpdate && state.filterSchedules.status === Status.ADJUSTING) {
                currentData = currentData.filter((el: any) => el.id !== state.scheduleNewUpdate.id)
                currentData.unshift(state.scheduleNewUpdate)
            }
            state.metaSchedules = action.payload.count
            state.schedules = [...currentData]
            state.loadingSchedules = false
        },
        fetchSchedulesFailed(state) {
            state.loadingSchedules = false
        },
        setSchedulesList(state, action) {
            state.schedules = action.payload
        },

        setFilter(state, action) {
            state.filterSchedules = action.payload
        },

        fetchInternalUser(state) {
            state.loadingInternalUsers = true
        },
        fetchInternalUserSuccess(state: any, action: PayloadAction<any>) {
            // if (state.filterInternalUsers.page === 1) {
            //     state.internalUsers = [...action.payload.data]
            // } else {
            //     state.internalUsers = [...state.internalUsers, ...action.payload.data]
            // }
            // state.metaInternalUsers = action.payload.meta
            state.internalUsers = [...action.payload.data]
            state.loadingInternalUsers = false
        },
        fetchInternalUserFailed(state) {
            state.loadingInternalUsers = false
        },
        setInternalUsers(state, action) {
            state.internalUsers = action.payload
        },
        setFilterInternalUsers(state, action) {
            state.filterInternalUsers = action.payload
        },

        // delete schedule
        deleteSchedule(state, action) {
            state.loadingSchedules = true
        },
        deleteScheduleSuccess(state, action) {
            state.loadingSchedules = false
            state.showAlert = true
            if (action.payload.success) {
                state.filterSchedules.flag = !state.filterSchedules.flag
                state.alertSeverity = 'success'
                state.alertMessage = '予定の削除に成功しました。'
            } else {
                state.alertSeverity = 'error'
                state.alertMessage = '予定の削除に失敗しました。'
            }
        },
        hideAlert(state) {
            state.showAlert = false
            state.alertMessage = ''
        },
        deleteScheduleFailed(state) {
            state.loadingSchedules = false
        },

        // cancel schedule
        cancelSchedule(state, action) {
            state.loadingSchedules = true

            // set new cancel schedule
            state.scheduleNewCancel = undefined
        },
        cancelScheduleSuccess(state, action) {
            // state.filterSchedules = {
            //     ...state.filterSchedules,
            //     flag: !state.filterSchedules.flag,
            // }

            // set new cancel schedule
            state.scheduleNewCancel = action.payload

            state.loadingSchedules = false
            state.showAlert = true
            state.alertSeverity = 'success'
            state.alertMessage = '予定のキャンセルに成功しました。'
        },
        cancelHideAlert(state) {
            state.showAlert = false
            state.alertMessage = ''
        },
        cancelScheduleFailed(state) {
            state.loadingSchedules = false
            state.alertMessage = '予定のキャンセルに失敗しました。'

            // set new cancel schedule
            state.scheduleNewCancel = undefined
        },

        setScheduleNewUpdate(state, action) {
            state.scheduleNewUpdate = action.payload
        },

        setScheduleFinalized(state, action) {
            state.scheduleFinalized = action.payload
        },

        setScheduleNewCancel(state, action) {
            state.scheduleNewCancel = action.payload
        },

        // Top
        setFilterSchedulesAdmin(state, action) {
            state.filterSchedulesAdmin = action.payload
        },
        setSchedulesAdmin(state, action) {
            state.schedulesAdmin = action.payload
        },
        fetchSchedulesAdmin(state, action) {
            state.loadingSchedulesAdmin = true
        },
        fetchSchedulesAdminSuccess(state: any, action: PayloadAction<any>) {
            state.metaSchedulesAdmin = action.payload.count
            if (action.payload.success) {
                state.schedulesAdmin = [...action.payload.data]
            } else {
                state.schedulesAdmin = []
            }
            state.loadingSchedulesAdmin = false
        },
        fetchSchedulesAdminFailed(state, action) {
            state.loadingSchedulesAdmin = false
        },

        setFilterSchedulesAttendee(state, action) {
            state.filterSchedulesAttendee = action.payload
        },
        setSchedulesAttendee(state, action) {
            state.schedulesAttendee = action.payload
        },
        fetchSchedulesAttendee(state, action) {
            state.loadingSchedulesAttendee = true
        },
        fetchSchedulesAttendeeSuccess(state: any, action: PayloadAction<any>) {
            state.metaSchedulesAttendee = action.payload.count
            if (action.payload.success) {
                state.schedulesAttendee = [...action.payload.data]
            } else {
                state.schedulesAttendee = []
            }
            state.loadingSchedulesAttendee = false
        },
        fetchSchedulesAttendeeFailed(state, action) {
            state.loadingSchedulesAttendee = false
        },
    },
})

// actions
export const topActions = topSlice.actions

// selectors
export const selectFilterSchedules = (state: any) => state.top.filterSchedules
export const selectSchedules = (state: any) => state.top.schedules
export const selectMetaSchedules = (state: any) => state.top.metaSchedules
export const selectLoadingSchedules = (state: any) => state.top.loadingSchedules

export const selectInternalUsers = (state: any) => state.top.internalUsers
export const selectFilterInternalUsers = (state: any) => state.top.filterInternalUsers
export const selectLoadingInternalUsers = (state: any) => state.top.loadingInternalUsers
export const selectMetaInternalUsers = (state: any) => state.top.metaInternalUsers

export const selectAlertMessage = (state: any) => state.top.alertMessage
export const selectAlertSeverity = (state: any) => state.top.alertSeverity
export const selectShowAlert = (state: any) => state.top.showAlert

export const selectScheduleNewUpdate = (state: any) => state.top.scheduleNewUpdate
export const selectScheduleFinalized = (state: any) => state.top.scheduleFinalized

export const selectScheduleNewCancel = (state: any) => state.top.scheduleNewCancel

export const selectFilterSchedulesAdmin = (state: any) => state.top.filterSchedulesAdmin
export const selectSchedulesAdmin = (state: any) => state.top.schedulesAdmin
export const selectMetaSchedulesAdmin = (state: any) => state.top.metaSchedulesAdmin
export const selectLoadingSchedulesAdmin = (state: any) => state.top.loadingSchedulesAdmin

export const selectFilterSchedulesAttendee = (state: any) => state.top.filterSchedulesAttendee
export const selectSchedulesAttendee = (state: any) => state.top.schedulesAttendee
export const selectMetaSchedulesAttendee = (state: any) => state.top.metaSchedulesAttendee
export const selectLoadingSchedulesAttendee = (state: any) => state.top.loadingSchedulesAttendee
// reducer
const topReducer = topSlice.reducer
export default topReducer
