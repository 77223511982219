import { PayloadAction } from '@reduxjs/toolkit'
import { call, delay, fork, take, put, takeLatest } from 'redux-saga/effects'
import { FilterTopPage, topActions } from './topSlice'
import scheduleApi from 'src/api/scheduleApi'
import userApi from 'src/api/userApi'

function* fetchSchedules(action: PayloadAction<FilterTopPage>): any {
    try {
        const response = yield call(scheduleApi.getAll, action.payload)
        yield put(topActions.fetchSchedulesSuccess(response))
    } catch (err) {
        console.log('[topSaga.ts] Fetch schedules failed: ', err)
        yield put(topActions.fetchSchedulesFailed())
    }
}

function* fetchSchedulesAdmin(action: PayloadAction<FilterTopPage>): any {
    try {
        const response = yield call(scheduleApi.getAll, action.payload)
        yield put(topActions.fetchSchedulesAdminSuccess(response))
    } catch (err) {
        console.log('[topSaga.ts] Fetch schedules failed: ', err)
        yield put(topActions.fetchSchedulesAdminFailed(err))
    }
}

function* fetchSchedulesAttendee(action: PayloadAction<FilterTopPage>): any {
    try {
        const response = yield call(scheduleApi.getAll, action.payload)
        yield put(topActions.fetchSchedulesAttendeeSuccess(response))
    } catch (err) {
        console.log('[topSaga.ts] Fetch schedules failed: ', err)
        yield put(topActions.fetchSchedulesAttendeeFailed(err))
    }
}

function* deleteSchedule(action: any): any {
    try {
        const params = {
            id: action.payload,
        }
        const response = yield call(scheduleApi.delete, params)
        yield put(topActions.deleteScheduleSuccess(response))
        // hide alert after 3 seconds
        yield delay(3000)
        yield put(topActions.hideAlert())
    } catch (err) {
        console.log('[topSaga.ts] Delete schedule failed: ', err)
        yield put(topActions.deleteScheduleFailed())
    }
}

function* cancelSchedule(action: any): any {
    try {
        const params = {
            id: action.payload.id,
            email_subject: action.payload.email_subject,
            email_body: action.payload.email_body,
            is_cc_internal_participants: action.payload.is_cc_internal_participants,
        }
        const response = yield call(scheduleApi.cancel, params)
        yield put(topActions.cancelScheduleSuccess(response.data))

        // hide alert after 3 seconds
        yield delay(3000)
        yield put(topActions.cancelHideAlert())

        // 4s set schedule new update to undefined
        yield put(topActions.setScheduleNewCancel(undefined))
    } catch (err) {
        console.log('[topSaga.ts] Cancel schedule failed: ', err)
        yield put(topActions.cancelScheduleFailed())
    }
}

function* fetchInternalUsers(): any {
    try {
        const response = yield call(userApi.getInternalUsers)
        yield put(topActions.fetchInternalUserSuccess(response))
    } catch (err) {
        console.log('[topSaga.ts] Fetch internal users failed: ', err)
        yield put(topActions.fetchInternalUserFailed())
    }
}

function* setScheduleNewUpdate(action: any): any {
    if (action.payload) {
        yield delay(4000)
        yield put(topActions.setScheduleNewUpdate(undefined))
    }
}

function* setScheduleFinalized(action: any): any {
    if (action.payload) {
        yield delay(4000)
        yield put(topActions.setScheduleFinalized(undefined))
    }
}

export default function* topSaga() {
    yield takeLatest(topActions.fetchSchedules, fetchSchedules)
    yield takeLatest(topActions.deleteSchedule, deleteSchedule)
    yield takeLatest(topActions.cancelSchedule, cancelSchedule)
    yield takeLatest(topActions.fetchInternalUser, fetchInternalUsers)
    yield takeLatest(topActions.setScheduleNewUpdate, setScheduleNewUpdate)
    yield takeLatest(topActions.setScheduleFinalized, setScheduleFinalized)

    yield takeLatest(topActions.fetchSchedulesAdmin, fetchSchedulesAdmin)
    yield takeLatest(topActions.fetchSchedulesAttendee, fetchSchedulesAttendee)
}
