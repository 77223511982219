import { useEffect, useState } from 'react'
import { CONSTANTS } from 'src/config/constants'
import {
    Box,
    useTheme,
    Container,
    Paper,
    Typography,
    Stack,
    MenuItem,
    FormHelperText,
    ListItemText,
    Select,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Grid from '@mui/material/Unstable_Grid2'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import 'dayjs/locale/ja'
import 'moment/locale/ja'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import {
    adjustmentActions,
    selectFilterInternalUsers,
    selectInternalUsers,
    selectOrganizations,
} from '../../adjustment/adjustmentSlice'
import SelectCustom from 'src/components/SelectCustom'
import { TextInput } from 'src/components/TextInput'
import { ArrowLeftIcon } from 'src/components/Icons'
import { labelOfInput, labelOfInputAlign, inputField } from 'src/theme'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { replaceGaroonName } from '../../../helpers/helpers'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import { getSelectorsByUserAgent } from 'react-device-detect'
import { groupActions, selectAlertMessage, selectAlertSeverity, selectFilterMyGroup, selectInfoGroup, selectLoadingCreateMyGroup, selectLoadingFetchGroupById, selectLoadingUpdateGroup, selectMyGroups, selectShowAlert, selectLoadingMyGroup } from '../groupSlice'

const GroupAdd = () => {
    const { gid } = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const userAgent = window.navigator.userAgent
    const {isMobile}  = getSelectorsByUserAgent(userAgent)
    const organizationsList = useAppSelector(selectOrganizations)
    const internalUsers = useAppSelector(selectInternalUsers)
    const filterInternalUsers = useAppSelector(selectFilterInternalUsers)
    const [leftDataSelected, setLeftDataSelected] = useState<any[]>([])
    const [rightDataSelected, setRightDataSelected] = useState<any[]>([])
    const [errorBoxLeft, setErrorBoxLeft] = useState<boolean>(false)
    const showAlert = useAppSelector(selectShowAlert)
    const alertMessage = useAppSelector(selectAlertMessage)
    const alertSeverity = useAppSelector(selectAlertSeverity)
    const { height, width } = useWindowDimensions();
    const [updateOrganizationsList, setUpdateOrganizationsList] = useState<any>(organizationsList)
    const { showSnackBar } = useSnackBar()
    const groupList = useAppSelector(selectMyGroups)
    const filterGroup = useAppSelector(selectFilterMyGroup)
    const loadingCreateMyGroup = useAppSelector(selectLoadingCreateMyGroup)
    const [groupCreateData, setGroupCreateData] = useState<any>(null)
    const loadingUpdateGroup = useAppSelector(selectLoadingUpdateGroup)
    const infoGroup = useAppSelector(selectInfoGroup)
    const loadingFetchGroupById = useAppSelector(selectLoadingFetchGroupById)
    const loadingMyGroup = useAppSelector(selectLoadingMyGroup)

    useEffect(() => {
        dispatch(groupActions.fetchMyGroup(filterGroup));
    }, [filterGroup])

    useEffect(() => {
        const checkAllOrganization = organizationsList.filter((organization: any) => organization.name === CONSTANTS.ORGANIZATION_ALL.NAME);
        const newGroupList = Array.isArray(groupList) ? groupList : [];
        if (checkAllOrganization.length > 0) {
            const data = [];
            for (let i = 1; i < organizationsList.length; i++) {
                data.push({
                    id: organizationsList[i].id,
                    name: organizationsList[i].name,
                })
            }
            setUpdateOrganizationsList([...data, ...newGroupList]);
        } else {
            setUpdateOrganizationsList([...organizationsList, ...newGroupList]);
        }
    },[organizationsList])

    const handleArrow = (to: string) => {
        if (to === 'left') {
            if (rightDataSelected.length === 0) {
                return
            }

            //HANDLE RIGHT SELECTED
            const rightData = rightDataSelected.map((obj: any) => {
                return {
                    user: obj
                }
            })

            dispatch(
                groupActions.setInfoGroup({
                    ...infoGroup,
                    organizationUser: [...infoGroup.organizationUser, ...rightData],
                }),
            )
            setErrorBoxLeft(false)
            setRightDataSelected([])

            setRightDataUserSelected([])
            setLeftDataUserSelected([])
        } else {
            if (leftDataSelected.length === 0) {
                return
            }

            if (leftDataSelected.length === infoGroup.organizationUser.length) {
                setErrorBoxLeft(true)
            }

            //HANDLE LEFT SELECTED
            dispatch(
                groupActions.setInfoGroup({
                    ...infoGroup,
                    organizationUser: [
                        ...infoGroup.organizationUser?.filter((e: any) => !leftDataSelected.some((i) => i.id === e.user.id)),
                    ],
                }),
            )
            setLeftDataSelected([])

            setRightDataUserSelected([])
            setLeftDataUserSelected([])
        }
    }

    useEffect(() => {
        if (loadingCreateMyGroup === false && showAlert === false && groupCreateData) {
            showSnackBar(true, `グループ「${groupCreateData?.name}」を作成しました。`, 'success')
            navigate(`/group/list/`)
            dispatch(groupActions.setLoadingCreateMyGroup(true))
            dispatch(groupActions.setLoadingMyGroup(false))
        }
    }, [loadingCreateMyGroup])

    useEffect(() => {
        if (loadingUpdateGroup === false && showAlert === false && groupCreateData) {
            navigate(`/group/list/`)
            showSnackBar(true, `グループ「${groupCreateData?.name}」を変更しました。`, 'success')
            dispatch(groupActions.setLoadingUpdateGroup(true))
            dispatch(groupActions.setLoadingMyGroup(false))
        }
    }, [loadingUpdateGroup])

    const handleClickItem = (frame: string, user: any, selected: boolean) => {
        if (frame === 'left') {
            if (selected) {
                setLeftDataSelected((data) => data.filter((e) => e.id !== user.id))
            } else {
                setLeftDataSelected((data) => [...data, user])
            }
        } else if (selected) {
            setRightDataSelected((data) => data.filter((e) => e.id !== user.id))
        } else {
            setRightDataSelected((data) => [...data, user])
        }
    }

    const handleFormSubmit = (data: any) => {
        if (infoGroup.organizationUser.length === 0) {
            showSnackBar(true, '入力エラーがあります。修正して再度実行ボタンをクリックしてください。', 'error')
            setErrorBoxLeft(true)
            return
        }

        const userIds = infoGroup.organizationUser.map((user: any) => {
            return {
                user_id: user.user.id
            }
        })

        let groupData: any = {
            name: data.name,
            user_ids: userIds
        };

        setGroupCreateData(groupData)
        if (gid) {
            groupData = {
                id: Number(gid),
                ...groupData,
            }

            // UPDATE GROUP
            dispatch(groupActions.updateMyGroup(groupData))
        } else {
            //CREAT GROUP
            dispatch(groupActions.createMyGroup(groupData))
        }
    }

    useEffect(() => {
        reset({
            name: '',
        })

        if (gid) {
            dispatch(groupActions.fetchGroupById(Number(gid)))
        } else {
            dispatch(
                groupActions.setInfoGroup({
                    ...infoGroup,
                    name: '',
                    organizationUser: [],
                }),
            )
        }
    }, [gid])

    useEffect(() => {
        if (showAlert && alertMessage !== '') {
            showSnackBar(true, alertMessage, alertSeverity)
        }
    }, [showAlert])

    useEffect(() => {
        if (loadingFetchGroupById === false && gid) {
            reset({
                ...infoGroup,
            })
        }
    }, [loadingFetchGroupById])

    useEffect(() => {
        dispatch(adjustmentActions.fetchOrganizations())
    }, [])

    useEffect(() => {
        dispatch(adjustmentActions.fetchInternalUsers(filterInternalUsers))
    }, [filterInternalUsers])

    const onChangeTextSearchInternalUsers = (text: string) => {
        dispatch(
            adjustmentActions.setFilterInternalUser({
                ...filterInternalUsers,
                text,
            }),
        )
    }

    const schema = yup.object({
        name: yup.string().max(100, '100文字以内で入力してください。').required('必ず入力してください。'),
    })

    const {
        control,
        handleSubmit,
        getValues,
        setError,
        setValue,
        reset,
        formState: { isSubmitting, errors, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
        },
        resolver: yupResolver(schema),
    })

    const [rightDataUserSelected, setRightDataUserSelected] = useState<any>([])
    const [leftDataUserSelected, setLeftDataUserSelected] = useState<any>([])

    const handleChangeRightDataUser = (event: any) => {
        setLeftDataUserSelected([])
        setLeftDataSelected([])
        let options = event.target.options;
        let value = [];
        let user = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                user.push(JSON.parse(options[i].getAttribute('data-user')))
            }
        }
        setRightDataSelected(user)
        setRightDataUserSelected(value)
    }

    const handleChangeLeftDataUser = (event: any) => {
        setRightDataUserSelected([])
        setRightDataSelected([])
        let options = event.target.options;
        let value = [];
        let user = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                user.push(JSON.parse(options[i].getAttribute('data-user')))
            }
        }
        setLeftDataUserSelected(value)
        setLeftDataSelected(user)
    }

    return (
        <Container sx={{ p: '0 !important' }}>
            <Paper elevation={3} sx={styles.paper}>
                <Box component="form" noValidate sx={{  width: '100%' }}>
                    <Grid container spacing={3}>
                        <Grid xs={12}>
                            <Stack direction={'column'} spacing={1}>
                                <Typography sx={styles.label}>グループ名</Typography>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue={getValues('name')}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Box sx={{ position: 'relative' }}>
                                            <TextInput
                                                value={value}
                                                autoComplete="off"
                                                onChange={onChange}
                                                placeholder="例）DYSKEプロジェクトメンバー"
                                                error={!!error}
                                                required
                                            />
                                            <FormHelperText error sx={styles.error}>
                                                {error ? error.message : ''}
                                            </FormHelperText>
                                        </Box>
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid xs={12} sx={{ paddingBottom: '0px'}}>
                                <Typography sx={styles.label}>参加者</Typography>
                            </Grid>
                            <Stack 
                                sx={{width: '100%'}} 
                                direction={{
                                    xs: 'column-reverse',
                                    sm: 'row',
                                }} 
                                spacing={0}
                            >
                                <Grid xs={12} sm={5.5}>
                                    {
                                        !isMobile ? (
                                            <Select
                                                fullWidth
                                                multiple
                                                native
                                                autoFocus={true}
                                                value={leftDataUserSelected}
                                                onChange={handleChangeLeftDataUser}
                                                sx={{
                                                    height: {
                                                        xs: '248px',
                                                        sm: '400px',
                                                    },
                                                    '& .MuiNativeSelect-select[multiple]': {
                                                        overflow: 'auto',
                                                        padding: '0px !important',
                                                        height: '100%',
                                                        borderRadius: '15px',
                                                    },
                                                    ' & option': {
                                                        ':checked, :focus, :active': {
                                                            background: 'linear-gradient(#ffff00,#ffff00) !important',
                                                            boxShadow: '0 0 10px 100px #ffff00 inset !important',
                                                            backgroundColor: '#ffff00 !important',
                                                            color: '#000000 !important',
                                                            WebkitTextFillColor: '#000000 !important',
                                                        },
                                                        ':first-of-type': {
                                                            pt: '10px',
                                                        },
                                                        ':last-of-type': {
                                                            pb: '10px',
                                                        },
                                                        padding: '4px 15px',
                                                        fontSize: '16px',
                                                    },
                                                    '&.MuiInputBase-root': {
                                                        fieldset: {
                                                            border: errorBoxLeft ? '1px solid red' : '1px solid #666',
                                                            borderRadius: '15px',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '1px solid #666',
                                                            borderRadius: '15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                {
                                                    infoGroup.organizationUser.map((item: any) => {
                                                        return (
                                                            <option 
                                                                key={item.user.id} 
                                                                value={item.user.id}
                                                                data-user={JSON.stringify(item.user)}
                                                            >
                                                                {replaceGaroonName(item.user, width) || item.user.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        ) : (
                                            <Box sx={[styles.boxLeft, errorBoxLeft && { border: '1px solid red' }]}>
                                                <Box 
                                                    sx={{ 
                                                        overflow: 'auto', 
                                                        height: {
                                                            xs: '248px',
                                                            sm: '400px',
                                                        }
                                                    }}
                                                >
                                                    {infoGroup.organizationUser.map((item: any) => {
                                                        const selected = leftDataSelected.some((i) => i.id === item.user.id)
                                                        return (
                                                            <Grid
                                                                key={item.user.id}
                                                                sx={[
                                                                    styles.userItem,
                                                                    selected && {
                                                                        backgroundColor: theme.palette.secondary.main,
                                                                    },
                                                                ]}
                                                                tabIndex={0}
                                                                onClick={() => handleClickItem('left', item.user, selected)}
                                                            >
                                                                {replaceGaroonName(item.user, width) || item.user.name}
                                                            </Grid>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    
                                    <FormHelperText error sx={styles.error}>
                                        {errorBoxLeft && '少なくとも 1 人の参加者を追加してください'}
                                    </FormHelperText>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={1}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {
                                                xs: 'row',
                                                sm: 'column',
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                transform: {
                                                    xs: 'rotate(270deg)',
                                                    sm: 'rotate(0deg)',
                                                },
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleArrow('left')}
                                        >
                                            <ArrowLeftIcon />
                                        </Box>
                                        <Box
                                            sx={{
                                                transform: {
                                                    xs: 'rotate(90deg)',
                                                    sm: 'rotate(180deg)',
                                                },
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleArrow('right')}
                                        >
                                            <ArrowLeftIcon />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={5.5}>
                                    <Stack
                                        sx={{
                                            height: '100%',
                                            justifyContent: 'space-between',
                                            flexDirection: 'column',
                                        }}
                                        spacing={2}
                                    >
                                        <SelectCustom
                                            value={filterInternalUsers.organization_id}
                                            onChange={(event: any) => {
                                                dispatch(
                                                    adjustmentActions.setFilterInternalUser({
                                                        ...filterInternalUsers,
                                                        organization_id: event.target.value,
                                                    }),
                                                )
                                                setRightDataSelected([])
                                            }}
                                        >
                                            {updateOrganizationsList.map((organization: any, index: number) => (
                                                <MenuItem key={`${organization.id}`} value={organization.id}>
                                                    <ListItemText primary={organization.name} />
                                                </MenuItem>
                                            ))}
                                        </SelectCustom>
                                        <TextInput
                                            value={filterInternalUsers.text}
                                            autoComplete="off"
                                            onChange={(event: any) => {
                                                onChangeTextSearchInternalUsers(event.target.value)
                                                setRightDataSelected([])
                                            }}
                                            placeholder="ユーザを検索"
                                        />
                                        {
                                            !isMobile ? (
                                                <Box>
                                                    <Select
                                                        fullWidth
                                                        multiple
                                                        native
                                                        value={rightDataUserSelected}
                                                        onChange={handleChangeRightDataUser}
                                                        sx={{
                                                            height: '248px',
                                                            '& .MuiNativeSelect-select[multiple]': {
                                                                overflow: 'auto',
                                                                padding: '0px',
                                                                height: '100%',
                                                                borderRadius: '15px',
                                                            },
                                                            ' & option': {
                                                                ':checked, :focus, :active': {
                                                                    background: 'linear-gradient(#ffff00,#ffff00)',
                                                                    boxShadow: '0 0 10px 100px #ffff00 inset !important',
                                                                    backgroundColor: '#ffff00 !important',
                                                                    color: '#000000 !important',
                                                                    WebkitTextFillColor: '#000000 !important',
                                                                },
                                                                ':first-of-type': {
                                                                    pt: '10px',
                                                                },
                                                                ':last-of-type': {
                                                                    pb: '10px',
                                                                },
                                                                padding: '4px 15px',
                                                                fontSize: '16px',
                                                            },
                                                            '&.MuiInputBase-root': {
                                                                fieldset: {
                                                                    border: '1px solid #666',
                                                                    borderRadius: '15px',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: '1px solid #666',
                                                                    borderRadius: '15px',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {
                                                            internalUsers.filter((internalUsers: any) =>
                                                                !infoGroup.organizationUser.some(
                                                                    (e: any) => e.user.id === internalUsers.id,
                                                                ),).map((item: any) => {
                                                                    return (
                                                                        <option 
                                                                            key={item.id} 
                                                                            value={item.id}
                                                                            data-user={JSON.stringify(item)}
                                                                            className='option-user-right'
                                                                        >
                                                                            {replaceGaroonName(item, width)}
                                                                        </option>
                                                                    )
                                                                })
                                                        }
                                                    </Select>
                                                </Box>
                                            ) : (
                                                <Box sx={styles.boxRight}>
                                                    <Box sx={{ overflow: 'auto', height: '248px' }}>
                                                        {internalUsers
                                                            .filter(
                                                                (internalUsers: any) =>
                                                                    !infoGroup.organizationUser.some(
                                                                        (e: any) => e.user.id === internalUsers.id,
                                                                    ),
                                                            )
                                                            .map((item: any) => {
                                                                const selected = rightDataSelected.some((i) => i.id === item.id)
                                                                return (
                                                                    <Grid
                                                                        key={item.id}
                                                                        sx={[
                                                                            styles.userItem,
                                                                            selected && {
                                                                                backgroundColor: theme.palette.secondary.main,
                                                                            },
                                                                        ]}
                                                                        tabIndex={0}
                                                                        onClick={() => handleClickItem('right', item, selected)}
                                                                    >
                                                                        {replaceGaroonName(item, width)}
                                                                    </Grid>
                                                                )
                                                            })}
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid xs={12} sx={{ mt: 2 }}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <ButtonCustom
                                    child={'一覧に戻る'}
                                    onClick={() => {
                                        navigate('/group/list')
                                    }}
                                />
                                <ButtonCustom
                                    child={'作成'}
                                    isSubmit
                                    endIcon={<ArrowForwardIosIcon sx={{ fontSize: '14px !important' }} />}
                                    onClick={handleSubmit(handleFormSubmit)}
                                    onMouseDown={() => {
                                        if (getValues('name').length === 0 || getValues('name').length > 100) {
                                            showSnackBar(
                                                true,
                                                '入力エラーがあります。修正して再度実行ボタンをクリックしてください。',
                                                'error',
                                            )
                                        }
                                    }}
                                    disabled={loadingMyGroup}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Container>
    )
}

const styles = {
    paper: {
        p: { xs: '20px', sm: '30px', md: 5 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: ' 0px 4px 120px rgba(0, 0, 0, 0.25)',
        color: '#000000',
        marginTop: '28px',
        borderRadius: '20px',
        border: '1px solid transparent',
    },
    targetPeriod: {
        borderRadius: '15px',
        border: '1px solid #666',
        height: '50px',
        width: '100%',
        display: 'flex',
        direction: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'relative',
        '&:focus': {
            borderColor: '#666666',
        },
        '&:hover': {
            borderColor: '#000000',
        },
    },
    boxLeft: {
        border: '1px solid #666',
        height: {
            xs: '248px',
            sm: '400px',
        },
        borderRadius: '15px',
        overflow: 'hidden',
    },
    boxRight: {
        border: '1px solid #666',
        marginTop: '20px',
        borderRadius: '15px',
        overflow: 'hidden',
    },
    arrow: (theme: any) => ({
        width: '64px',
        height: '64px',
    }),
    userItem: {
        ...inputField,
        ':first-of-type': {
            pt: '10px',
        },
        ':last-child': {
            pb: '10px',
        },
        p: '4px 15px',
        cursor: 'pointer',
    },
    dateField: {
        width: '50%',
        fieldset: {
            border: 'none',
        },
        input: {
            ...inputField,
            textAlign: 'center',
        },
        '.MuiInputBase-root': {
            p: '16.5px 0',
            input: {
                pl: 0,
            },
        },
    },
    label: {
        ...labelOfInputAlign,
        ...labelOfInput,
    },
    error: { marginLeft: '7px !important', position: 'absolute' },
    squareBox: {
        width: '14px',
        height: '14px',
        marginRight: '2px',
        marginTop: '1px',
    },
}

export default GroupAdd
