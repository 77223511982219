import { del, get, post, put } from './axiosClient'

const organizationApi = {
    getAll() {
        const url = '/organization'
        return get(url)
    },
    createMyGroup(data: any) {
        const url = '/organization/create-group'
        return post(url, data)
    },
    getGroups(params: any = {}) {
        const url = '/organization/dyske-group/show'
        return get(url, { params })
    },
    deleteGroup(sid: number, data: any = {}) {
        const url = `/organization/delete-group/${sid}`
        return del(url, data)
    },
    updateGroup(sid: number, data: any = {}) {
        const url = `/organization/update-group/${sid}`
        return put(url, data)
    },
    getById(id: number) {
        const url = `/organization/${id}`
        return get(url)
    },
}

export default organizationApi
