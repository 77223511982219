import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../models'

export interface LoginPayload {
    user_name: string
    password: string
    navigate?: any

    // Error handling
    showAlert: boolean
    alertMessage: string
    alertSeverity: string
}

export interface AuthState {
    isLoggedIn: boolean
    logging?: boolean
    currentUser?: User
    error: string,

    // Error handling
    showAlert: boolean
    alertMessage: string
    alertSeverity: string
}

const initialState: AuthState = {
    isLoggedIn: false,
    logging: false,
    currentUser: undefined,
    error: '',

    // Error handling
    showAlert: false,
    alertMessage: '',
    alertSeverity: '',
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action: PayloadAction<LoginPayload>) {
            state.logging = true
        },
        loginSuccess(state, action: any) {
            if (action.payload.success) {
                state.isLoggedIn = true
                state.currentUser = action.payload.user
            }
            state.logging = false
        },
        loginFailed(state, action: any) {
            if (action?.payload?.success === false) {
                state.isLoggedIn = false
                state.error = action.payload.message
            }
            state.logging = false
        },

        setCurrentUser(state, action) {
            state.currentUser = action.payload
            if(state.currentUser){
                state.isLoggedIn = true
            }
        },

        logout(state) {
            localStorage.removeItem('access_token')
            sessionStorage.removeItem('access_token')
            state.isLoggedIn = false
            state.currentUser = undefined
        },

        setError(state, action) {
            state.error = action.payload
        },

        // Error handling
        setErrorGlobal(state, action) {
            state.showAlert = true;
            if (action?.payload?.response?.data?.message.includes('GRN_REST_API_00203')) {
                state.alertMessage = `終了時間は開始時間より後の時間を指定してください。（エラーコード:${action?.payload?.response?.status}）`;
            } else {
                state.alertMessage = `エラーが発生しました。管理者にお問合せください。（エラーコード:${action?.payload?.response?.status}）`;
            }
            state.alertSeverity = 'error';
        },

        cancelHideAlert(state) {
            state.showAlert = false
            state.alertMessage = ''
            state.alertSeverity = ''
        }
    },
})

// actions
export const authActions = authSlice.actions

// selectors
export const selectIsLoggedIn = (state: any) => state.auth.isLoggedIn
export const selectLogging = (state: any) => state.auth.logging
export const selectCurrentUser = (state: any) => state.auth.currentUser
export const selectError = (state: any) => state.auth.error

// reducer
const authReducer = authSlice.reducer
export default authReducer
