import React from "react";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import {
    Box,
    Typography,
} from '@mui/material'

export interface LinearProgressWithLabelProps extends LinearProgressProps {
  value: number;
}

const LinearProgressWithLabel: React.FC<LinearProgressWithLabelProps> = (props) => {
  return (
    <Box sx={{ alignItems: 'center' }}>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Typography sx={{fontSize: '16px', color: '#be39f3', lineHeight: 2}} variant="body2">
                {`${Math.round(props.value)}%`}
            </Typography>
        </Box>
        <Box sx={{ width: '200px', border: '1px solid #be39f3' }}>
            <LinearProgress 
                sx={{
                    height: 10,
                    backgroundColor: 'transparent',
                    '& .MuiLinearProgress-barColorPrimary': {
                        backgroundColor: '#be39f3',
                    }
                }} 
                variant="determinate" 
                {...props} 
            />
        </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;