import { PayloadAction } from '@reduxjs/toolkit'
import { call, delay, fork, take, put, takeLatest } from 'redux-saga/effects'
import { adjustmentActions } from './adjustmentSlice'
import userApi from 'src/api/userApi'
import organizationApi from 'src/api/organizationApi'
import scheduleApi from 'src/api/scheduleApi'
import facilityApi from 'src/api/facilityApi'

function* fetchOrganizations(): any {
    try {
        const response = yield call(organizationApi.getAll)
        yield put(adjustmentActions.fetchOrganizationsSuccess(response))
    } catch (err) {
        console.log('[adjustmentSaga.ts] Fetch organizations users failed: ', err)
        yield put(adjustmentActions.fetchOrganizationsFailed())
    }
}

function* fetchInternalUsers(action: any): any {
    try {
        const params = {
            ...action.payload,
            organization_id: action.payload.organization_id === 0 ? null : action.payload.organization_id,
            orderBy: 'id',
            order: 'asc'
        }
        const response = yield call(userApi.getInternalUsers, params)
        yield put(adjustmentActions.fetchInternalUsersSuccess(response))
    } catch (err) {
        console.log('[adjustmentSaga.ts] Fetch internal users failed: ', err)
        yield put(adjustmentActions.fetchInternalUsersFailed())
    }
}

function* fetchFacilityGroups(): any {
    try {
        const response = yield call(facilityApi.getAll)
        yield put(adjustmentActions.fetchFacilityGroupsSuccess(response))
    } catch (err) {
        console.log('[adjustmentSaga.ts] Fetch Facility Groups failed: ', err)
        yield put(adjustmentActions.fetchFacilityGroupsFailed())
    }
}

function* fetchFacilities(action: any): any {
    try {
        const params = {
            ...action.payload,
            orderBy: 'id',
            order: 'asc'
        }
        const response = yield call(facilityApi.getFacilitiesByGroup, params)
        yield put(adjustmentActions.fetchFacilitiesSuccess(response))
    } catch (err) {
        console.log('[adjustmentSaga.ts] Fetch facilities failed: ', err)
        yield put(adjustmentActions.fetchFacilitiesFailed())
    }
}

function* fetchFacilitiesInUsed(action: any): any {
    try {
        const params = {
            ...action.payload,
        }
        const response = yield call(facilityApi.getFacilitiesInUsed, params)
        yield put(adjustmentActions.fetchFacilitiesInUsedSuccess(response))
    } catch (err) {
        console.log('[adjustmentSaga.ts] Fetch Facilities In Used failed: ', err)
        yield put(adjustmentActions.fetchFacilitiesInUsedFailed())
    }
}

function* fetchExternalUsers(action: any): any {
    try {
        const params = {
            ...action.payload,
        }
        const response = yield call(userApi.getInternalUsers, params)
        yield put(adjustmentActions.fetchInternalUsersSuccess(response))
    } catch (err) {
        console.log('[adjustmentSaga.ts] Fetch internal users failed: ', err)
        yield put(adjustmentActions.fetchInternalUsersFailed())
    }
}

function* fetchScheduleById(action: any): any {
    try {
        const params = {
            id: action.payload,
        }
        const response = yield call(scheduleApi.getById, params)
        yield put(adjustmentActions.fetchScheduleSuccess(response))
    } catch (err) {
        console.log('[adjustmentSaga.ts] Fetch schedule for edit failed: ', err)
    }
}

function* createSchedule(action: any): any {
    try {
        const data = {
            ...action.payload,
        }
        const response = yield call(scheduleApi.create, data)
        if (response.success === false) {
            console.log('[adjustmentSaga.ts] Create schedule failed: ', response.message)
            yield put(adjustmentActions.createScheduleFailed())

            // hide alert after 3 seconds
            yield delay(3000)
            yield put(adjustmentActions.cancelHideAlert())
        } else {
            yield put(adjustmentActions.createScheduleSuccess(response))
        }
    } catch (err) {
        console.log('[adjustmentSaga.ts] Create schedule failed: ', err)
        yield put(adjustmentActions.createScheduleFailed())

        // hide alert after 3 seconds
        yield delay(3000)
        yield put(adjustmentActions.cancelHideAlert())
    }
}

function* updateSchedule(action: any): any {
    try {
        const data = {
            ...action.payload,
        }
        const response = yield call(scheduleApi.update, data)
        if (response.success === false) {
            console.log('[adjustmentSaga.ts] Update schedule failed: ', response.message)
            yield put(adjustmentActions.updateScheduleFailed())

            // hide alert after 3 seconds
            yield delay(3000)
            yield put(adjustmentActions.cancelHideAlert())
        } else {
            yield put(adjustmentActions.updateScheduleSuccess())
        }
    } catch (err) {}
}

export default function* topSaga() {
    yield takeLatest(adjustmentActions.fetchOrganizations, fetchOrganizations)
    yield takeLatest(adjustmentActions.fetchInternalUsers, fetchInternalUsers)
    yield takeLatest(adjustmentActions.fetchFacilityGroups, fetchFacilityGroups)
    yield takeLatest(adjustmentActions.fetchFacilitiesInUsed, fetchFacilitiesInUsed)
    yield takeLatest(adjustmentActions.fetchFacilities, fetchFacilities)
    yield takeLatest(adjustmentActions.fetchExternalUsers, fetchExternalUsers)
    yield takeLatest(adjustmentActions.fetchScheduleById, fetchScheduleById)
    yield takeLatest(adjustmentActions.createSchedule, createSchedule)
    yield takeLatest(adjustmentActions.updateSchedule, updateSchedule)
}
