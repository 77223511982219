import React, { useLayoutEffect, useRef } from "react";
// Ignore the import from under build/esm/index,
// it's due to a codesandbox issue.
import { StickyTableHeader } from 'vh-sticky-table-header';
import {
    Typography
} from '@mui/material'
import useWindowDimensions from "src/hook/useWindowDimensions";

interface TableWithStickyHeaderProps {
    children: React.ReactNode;
}

const TableWithStickyHeader = ({ children }: TableWithStickyHeaderProps) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const tableCloneRef = useRef<HTMLTableElement>(null);
  const { height, width } = useWindowDimensions();

  useLayoutEffect(() => {
    if (tableRef.current && tableCloneRef.current) {
      // Initialize the sticky header.
      const sticky = new StickyTableHeader(
        tableRef.current,
        tableCloneRef.current,
        { max: 74 }
      );
      // Make sure to destory the sticky header once the main table is unmounted.
      return () => sticky.destroy();
    }
  }, [children, width]);

  return (
    <Typography component={'div'}>
        <Typography component={'div'} className="table_container" sx={{overflowX: 'auto',overflowY: 'hidden'}}>
            <table style={{overflowX: 'scroll', marginBottom: 0}} ref={tableRef}>{children}</table>
        </Typography>
        <Typography component={'div'} className="clone_table table_container" 
            sx={{
                top: {
                    xs: '56px !important',
                    sm: '64px !important',
                    md: '74px !important',
                },
                overflowX: 'auto',
                overflowY: 'hidden', 
                marginBottom: 0,
                '& thead': {
                    backgroundColor: '#fff',
                    zIndex: 1000
                }
            }}
        >
            <table ref={tableCloneRef} />
        </Typography>
    </Typography>
  );
};

export default TableWithStickyHeader;
