import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import {
    FormHelperText,
    Stack,
    Typography,
    Container,
    Paper,
    Select,
    ListItemText,
    MenuItem,
    useTheme
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import scheduleApi from 'src/api/scheduleApi'
import adjustmentApi from 'src/api/adjustmentApi'
import dayjs from 'dayjs'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextInput } from 'src/components/TextInput'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded'
import PeopleAltSharpIcon from '@mui/icons-material/PeopleAltSharp'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { CONSTANTS } from 'src/config/constants'
import { tableRowAlign, tableBodyText, tableHeaderText, inputField } from 'src/theme'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { replaceGaroonName } from '../../helpers/helpers'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import { HelpCenterIcon } from 'src/components/Icons'
import ComponentStepper from '../adjustment/components/Stepper';
import { getSelectorsByUserAgent } from 'react-device-detect';
import {
    adjustmentActions,
    selectFacilities,
    selectFacilitiesInUsed,
    selectFacilityGroups,
    selectFilterFacilities,
    selectInfoSchedule,
    selectLoadingFacilitiesInUsed,
} from '../adjustment/adjustmentSlice'
import SelectCustom from 'src/components/SelectCustom';
import { ArrowLeftIcon } from 'src/components/Icons'

const schema = yup.object({
    note: yup
        .string(),
})

const FinalizeStep2 = () => {
    const dispatch = useAppDispatch()
    const { sid } = useParams()
    const { showSnackBar } = useSnackBar()
    const navigate = useNavigate()
    const theme = useTheme()

    const infoSchedule = useAppSelector(selectInfoSchedule)
    const groupFacilityList = useAppSelector(selectFacilityGroups)
    const facilities = useAppSelector(selectFacilities)
    const filterFacility = useAppSelector(selectFilterFacilities)
    const [leftFacilitySelected, setLeftFacilitySelected] = useState<any[]>([])
    const [rightFacilitySelected, setRightFacilitySelected] = useState<any[]>([])
    const [errorFacilityBoxLeft, setErrorFacilityBoxLeft] = useState<boolean>(false)
    const [rightDataFacilitySelected, setRightDataFacilitySelected] = useState<any>([])
    const [leftDataFacilitySelected, setLeftDataFacilitySelected] = useState<any>([])
    const facilitiesInUsed = useAppSelector(selectFacilitiesInUsed);
    const loadingFacilitiesInUsed = useAppSelector(selectLoadingFacilitiesInUsed);

    useEffect(() => {
        dispatch(adjustmentActions.fetchFacilityGroups())
    }, [])

    useEffect(() => {
        dispatch(adjustmentActions.fetchFacilities(filterFacility))
    }, [filterFacility])


    const handleChangeRightDataFacility = (event: any) => {
        setLeftDataFacilitySelected([])
        setLeftFacilitySelected([])
        let options = event.target.options;
        let value = [];
        let facility = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                facility.push(JSON.parse(options[i].getAttribute('data-facility')))
            }
        }
        setRightFacilitySelected(facility)
        setRightDataFacilitySelected(value)
    }

    const handleChangeLeftDataFacility = (event: any) => {
        setRightDataFacilitySelected([])
        setRightFacilitySelected([])
        let options = event.target.options;
        let value = [];
        let facility = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                facility.push(JSON.parse(options[i].getAttribute('data-facility')))
            }
        }
        setLeftDataFacilitySelected(value)
        setLeftFacilitySelected(facility)
    }

    const handleClickFacilityItem = (frame: string, facility: any, selected: boolean) => {
        if (frame === 'left') {
            if (selected) {
                setLeftFacilitySelected((data) => data.filter((e) => e.garoon_id !== facility.garoon_id))
            } else {
                setLeftFacilitySelected((data) => [...data, facility])
            }
        } else if (selected) {
            setRightFacilitySelected((data) => data.filter((e) => e.garoon_id !== facility.garoon_id))
        } else {
            if (facilitiesInUsed.length > 0 && facilitiesInUsed.indexOf(facility.garoon_id) > -1) {
                return;
            }
            setRightFacilitySelected((data) => [...data, facility])
        }
    }

    const handleFacilityArrow = (to: string) => {
        if (to === 'left') {
            if (rightFacilitySelected.length === 0) {
                return
            }
            // add facilities selected right to left
            dispatch(
                adjustmentActions.setInfoSchedule({
                    ...infoSchedule,
                    facilities: [...infoSchedule.facilities, ...rightFacilitySelected],
                }),
            )
            setErrorFacilityBoxLeft(false)
            setRightFacilitySelected([])

            setRightDataFacilitySelected([])
            setLeftDataFacilitySelected([])
        } else {
            if (leftFacilitySelected.length === 0) {
                return
            }
            if (leftFacilitySelected.length === infoSchedule.facilities.length) {
                // setErrorFacilityBoxLeft(true)
            }
            // remove users selected left
            dispatch(
                adjustmentActions.setInfoSchedule({
                    ...infoSchedule,
                    facilities: [
                        ...infoSchedule.facilities.filter((e: any) => !leftFacilitySelected.some((i) => i.garoon_id === e.garoon_id)),
                    ],
                }),
            )
            setLeftFacilitySelected([])

            setRightDataFacilitySelected([])
            setLeftDataFacilitySelected([])
        }
    }

    const onChangeTextSearchFacility = (text: string) => {
        dispatch(
            adjustmentActions.setFilterFacility({
                ...filterFacility,
                text,
            }),
        )
    }

    const [scheduleData, setScheduleData] = useState<any>(null)
    const [notRepondentUsers, setNotRepondentUsers] = useState<any>(null)
    const [checkEventMenu, setCheckEventMenu] = useState(true)
    const { height, width } = useWindowDimensions();
    const location = useLocation();
    const [locationState] = useState(location?.state || {});
    const [selectedAdjustment, setSelectedAdjustment] = useState<any>(null)

    const userAgent = window.navigator.userAgent
    const {isMobile}  = getSelectorsByUserAgent(userAgent)

    const {
        control,
        handleSubmit,
        getValues,
        reset,
        formState: { isSubmitting, errors, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            note: infoSchedule.note ? infoSchedule.note : '',
        },
        values: {
            note: infoSchedule.note ? infoSchedule.note : '',
        },
        resolver: yupResolver(schema),
    })

    const nextStep3 = () => {
        dispatch(adjustmentActions.setInfoSchedule({
            ...infoSchedule,
            note: getValues('note') ? getValues('note') : '',
        }))
        navigate(`/finalize/${sid}/step3`, { state: { adjustmentData: selectedAdjustment, facilities: infoSchedule.facilities, note: getValues('note') ? getValues('note') : '' } })
    }

    useEffect(() => {
        if (locationState?.adjustmentData) {
            setSelectedAdjustment(locationState?.adjustmentData)
        } else {
            navigate('/schedule-list')
        }
    }, [locationState])

    useEffect(() => {
        if (selectedAdjustment) {
            dispatch(adjustmentActions.fetchFacilitiesInUsed({
                start_time: selectedAdjustment.start_time,
                end_time: dayjs(selectedAdjustment.start_time).add(selectedAdjustment.duration, 'minute').toISOString()
            }))
        }
    }, [selectedAdjustment])

    const [scheduleTags, setScheduleTags] = useState<any>([])
    const getListScheduleTags = () => {
        adjustmentApi.getListScheduleTags().then((res: any) => {
            if (res.success) {
                setScheduleTags(res.data);
            }
            return;
        })
        .catch((err) => {
            return;
        })
    }

    useEffect(() => {
        const params = {
            id: Number(sid),
        }
        scheduleApi
            .getById(params)
            .then((res) => {
                let data = res.data

                if (data == undefined || data == null || data.length == 0 ) {
                    showSnackBar(true, `予定が無効です`, 'error')
                    navigate('/schedule-list')
                }

                if (!res.data.event_menu || res.data.event_menu.trim().length === 0) {
                    setCheckEventMenu(false)
                }

                if (data.status !== 'ADJUSTING' || data.adjustment_end_date < dayjs().add(3, 'hour').toISOString()) {
                    showSnackBar(true, `予定が無効です`, 'error')
                    navigate('/schedule-list')
                }
                setScheduleData(data)
                let notRepodent = data.participant.filter((e: any) =>!e.is_confirmed && e.hash != null);
                setNotRepondentUsers(notRepodent);
            })
            .catch((err) => {
                console.log('Finalzied -> scheduleApi.getById failed: ', err)
                showSnackBar(true, `予定が無効です`, 'error')
                navigate('/schedule-list')
            })

        getListScheduleTags()
    }, [])


    if (scheduleData === null) {
        return
    }

    return (
        <Container sx={{ p: '0 !important'}}>
            <Box>
                <Grid container spacing={4}>
                    <Grid xs={12} sx={{ mt: '12px' }}>
                        <ComponentStepper activeStep={1} listSteps={['1.日時確定', '2.施設選択', '3.メール送信']} />
                    </Grid>
                </Grid>
            </Box>
            <Paper elevation={3} sx={styles.paper}>
                <Box component="form" noValidate sx={styles.form}>
                    <Grid container spacing={0} sx={styles.container}>
                        <Box sx={styles.adjustmentTitle}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                gap={1}
                            >
                                <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                    <Stack 
                                        direction={'row'} 
                                        spacing={{ xs: 1, xsm: 2}} 
                                        sx={{
                                            width: {
                                                xs: "130px",
                                                xsm: "150px"
                                            },
                                        }}
                                    >
                                        <InsertDriveFileOutlinedIcon sx={styles.boxAddInviteeIcon}/>
                                        <Typography sx={styles.label}>予定タイトル</Typography>
                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        spacing={{ xs: 1, xsm: 2}}
                                        sx={{
                                            width: {
                                                xs: 'calc(100% - 130px)',
                                                xsm: 'calc(100% - 130px)',
                                            },
                                        }}
                                    >
                                        {
                                            (() => {
                                                if (checkEventMenu) {
                                                    let tag = scheduleTags.find((element: any) => element?.name == scheduleData?.event_menu)
                                                    return (
                                                        <Box 
                                                            sx={[
                                                                styles.boxScheduleEventMenu, 
                                                                {
                                                                    height: '21px',
                                                                    backgroundColor: tag && tag?.color ? tag?.color : '#9F7AEA',
                                                                }
                                                            ]}
                                                        >
                                                            <Typography sx={styles.boxScheduleEventMenuText}>
                                                                {scheduleData.event_menu}
                                                            </Typography>
                                                        </Box>
                                                    )
                                                } else {
                                                    return ''
                                                }
                                            })()
                                        }
                                        <Typography
                                            sx={{ 
                                                lineHeight: 1.4, 
                                                fontSize: '15px',
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            {scheduleData.subject}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                    <Stack 
                                        direction={'row'} 
                                        spacing={{ xs: 1, xsm: 2}} 
                                        sx={{
                                            width: {
                                                xs: "130px",
                                                xsm: "150px"
                                            },
                                        }}
                                    >
                                        <EventNoteRoundedIcon sx={styles.boxAddInviteeIcon}/>
                                        <Typography sx={styles.label}>予定日時</Typography>
                                    </Stack>
                                    <Stack 
                                        direction={'row'}
                                        sx={{
                                            width: {
                                                xs: 'calc(100% - 130px)',
                                                xsm: 'calc(100% - 130px)',
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                lineHeight: 1.4,
                                                fontSize: '15px',
                                                whiteSpace: 'normal'
                                            }}
                                        >
                                            { selectedAdjustment 
                                                && (selectedAdjustment.duration !== 1439 
                                                    ? (<>
                                                        {dayjs(selectedAdjustment.start_time).format(
                                                            `YYYY/M/D(${
                                                                CONSTANTS.DAY_OF_WEEK[dayjs(selectedAdjustment.start_time).day()]
                                                            }) H:mm`,
                                                        )}
                                                        {`〜 `}
                                                        {selectedAdjustment.duration !== 1439 ? `(${selectedAdjustment.duration}分)` : '23:59'}
                                                    </>)
                                                    : (<>
                                                        {dayjs(selectedAdjustment.start_time).format(
                                                            `YYYY/M/D(${
                                                                CONSTANTS.DAY_OF_WEEK[dayjs(selectedAdjustment.start_time).day()]
                                                            })`,
                                                        )}
                                                    </>)
                                                )
                                            }
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                    <Stack 
                                        direction={'row'} 
                                        spacing={{ xs: 1, xsm: 2}} 
                                        sx={{
                                            width: {
                                                xs: "130px",
                                                xsm: "150px"
                                            },
                                        }}
                                    >
                                        <PeopleAltSharpIcon sx={styles.boxAddInviteeIcon}/>
                                        <Typography sx={styles.label}>参加者</Typography>
                                    </Stack>
                                    <Stack 
                                        direction={'row'}
                                        sx={{
                                            width: {
                                                xs: 'calc(100% - 130px)',
                                                xsm: 'calc(100% - 130px)',
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                lineHeight: 1.4, 
                                                fontSize: '15px',
                                                whiteSpace: 'normal'
                                            }}
                                        >
                                            {scheduleData.participant.map((e: any) => replaceGaroonName(e.user, width)).join('、')}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                    <Stack 
                                        direction={'row'} 
                                        spacing={{ xs: 1, xsm: 2}} 
                                        sx={{
                                            width: {
                                                xs: "130px",
                                                xsm: "150px"
                                            },
                                        }}
                                    >   
                                        <HelpCenterIcon styles={{fontSize: '21px', width: '1em', height: '1em'}}/>
                                        <Typography sx={styles.label}>未回答者</Typography>
                                    </Stack>
                                    <Stack 
                                        direction={'row'}
                                        sx={{
                                            marginLeft: 0,
                                            width: {
                                                xs: 'calc(100% - 130px)',
                                                xsm: 'calc(100% - 134px)',
                                            },
                                            display: {
                                                xs: 'flex'
                                            },
                                            flexDirection: {
                                                xs: 'column',
                                                sm: 'row'
                                            }
                                        }}
                                    >
                                        <Stack 
                                            direction={'row'}
                                            sx={{
                                            
                                            }}
                                        >
                                        {notRepondentUsers !== null && notRepondentUsers !== undefined && notRepondentUsers.length > 0?
                                            <Typography
                                                sx={{
                                                    lineHeight: 1.4, 
                                                    fontSize: '15px',
                                                    whiteSpace: 'normal'
                                                }}
                                            >
                                                {notRepondentUsers.map((e: any) => {
                                                    return replaceGaroonName(e.user, width)
                                                }).join('、')}
                                            </Typography>
                                            :
                                            <>-</>
                                        }
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Box>

                        <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed', marginBottom: '24px', marginTop: '24px' }}>
                            <Grid container spacing={3}>
                                <Grid xs={12}>
                                    <Stack direction={'column'} spacing={1}>
                                        <Typography sx={styles.label}>オンラインMTG URL　<Box component={'span'} style={{fontSize: '13px'}}>※任意</Box></Typography>
                                        <Controller
                                            name="note"
                                            control={control}
                                            defaultValue={getValues('note')}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <Box sx={{ position: 'relative' }}>
                                                    <TextInput
                                                        value={value}
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        placeholder=" 例）DYSKE完成レビュー会"
                                                        error={!!error}
                                                        required
                                                    />
                                                    <FormHelperText error sx={styles.error}>
                                                        {error ? error.message : ''}
                                                    </FormHelperText>
                                                </Box>
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid container xs={12}>
                                    <Grid xs={12} sx={{ paddingBottom: '0px'}}>
                                        <Typography sx={styles.label}>施設を選択　<Box component={'span'} style={{fontSize: '13px'}}>※任意</Box></Typography>
                                    </Grid>
                                    <Stack 
                                        sx={{width: '100%'}} 
                                        direction={{
                                            xs: 'column-reverse',
                                            sm: 'row',
                                        }} 
                                        spacing={0}
                                    >
                                        <Grid xs={12} sm={5.5}>
                                            {
                                                !isMobile ? (
                                                    <Select
                                                        fullWidth
                                                        multiple
                                                        native
                                                        autoFocus={true}
                                                        value={leftDataFacilitySelected}
                                                        onChange={handleChangeLeftDataFacility}
                                                        sx={{
                                                            marginBottom: {
                                                                xs: '0px',
                                                                sm: '22px',
                                                            },
                                                            height: {
                                                                xs: '248px',
                                                                sm: '400px',
                                                            },
                                                            '& .MuiNativeSelect-select[multiple]': {
                                                                overflow: 'auto',
                                                                padding: '0px !important',
                                                                height: '100%',
                                                                borderRadius: '15px',
                                                            },
                                                            ' & option': {
                                                                ':checked, :focus, :active': {
                                                                    background: 'linear-gradient(#ffff00,#ffff00) !important',
                                                                    boxShadow: '0 0 10px 100px #ffff00 inset !important',
                                                                    backgroundColor: '#ffff00 !important',
                                                                    color: '#000000 !important',
                                                                    WebkitTextFillColor: '#000000 !important',
                                                                },
                                                                ':first-of-type': {
                                                                    pt: '10px',
                                                                },
                                                                ':last-of-type': {
                                                                    pb: '10px',
                                                                },
                                                                padding: '4px 15px',
                                                                fontSize: '16px',
                                                            },
                                                            '&.MuiInputBase-root': {
                                                                fieldset: {
                                                                    border: errorFacilityBoxLeft ? '1px solid red' : '1px solid #666',
                                                                    borderRadius: '15px',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: '1px solid #666',
                                                                    borderRadius: '15px',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {
                                                            infoSchedule.facilities.map((item: any) => {
                                                                return (
                                                                    <option 
                                                                        key={item.id} 
                                                                        value={item.garoon_id}
                                                                        data-facility={JSON.stringify(item)}
                                                                    >
                                                                        {item.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                ) : (
                                                    <Box sx={[styles.boxLeft, errorFacilityBoxLeft && { border: '1px solid red' }]}>
                                                        <Box 
                                                            sx={{ 
                                                                overflow: 'auto', 
                                                                height: {
                                                                    xs: '248px',
                                                                    sm: '400px',
                                                                }
                                                            }}
                                                        >
                                                            {infoSchedule.facilities.map((item: any) => {
                                                                const selected = leftFacilitySelected.some((i) => i.garoon_id === item.garoon_id)
                                                                return (
                                                                    <Grid
                                                                        key={item.garoon_id}
                                                                        sx={[
                                                                            styles.userItem,
                                                                            selected && {
                                                                                backgroundColor: theme.palette.secondary.main,
                                                                            },
                                                                        ]}
                                                                        tabIndex={0}
                                                                        onClick={() => handleClickFacilityItem('left', item, selected)}
                                                                    >
                                                                        {item.name}
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Box>
                                                    </Box>
                                                )
                                            }
                                            <FormHelperText error sx={styles.error}>
                                                {errorFacilityBoxLeft && '少なくとも1つの施設を追加してください'}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            sm={1}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: {
                                                        xs: 'row',
                                                        sm: 'column',
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        transform: {
                                                            xs: 'rotate(270deg)',
                                                            sm: 'rotate(0deg)',
                                                        },
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleFacilityArrow('left')}
                                                >
                                                    <ArrowLeftIcon />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        transform: {
                                                            xs: 'rotate(90deg)',
                                                            sm: 'rotate(180deg)',
                                                        },
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleFacilityArrow('right')}
                                                >
                                                    <ArrowLeftIcon />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} sm={5.5}>
                                            <Stack
                                                sx={{
                                                    height: '100%',
                                                    justifyContent: 'space-between',
                                                    flexDirection: 'column',
                                                }}
                                                spacing={2}
                                            >
                                                <SelectCustom
                                                    value={filterFacility.groupId}
                                                    onChange={(event: any) => {
                                                        dispatch(
                                                            adjustmentActions.setFilterFacility({
                                                                ...filterFacility,
                                                                groupId: event.target.value,
                                                            }),
                                                        )
                                                        setRightFacilitySelected([])
                                                    }}
                                                >
                                                    {groupFacilityList.map((groupFacility: any, index: number) => (
                                                        <MenuItem key={`${groupFacility.id}`} value={groupFacility.garoon_group_id}>
                                                            <ListItemText primary={groupFacility.name} />
                                                        </MenuItem>
                                                    ))}
                                                </SelectCustom>
                                                <TextInput
                                                    value={filterFacility.text}
                                                    autoComplete="off"
                                                    onChange={(event: any) => {
                                                        onChangeTextSearchFacility(event.target.value)
                                                        setRightFacilitySelected([])
                                                    }}
                                                    placeholder="施設を探す"
                                                />
                                                {
                                                    !isMobile ? (
                                                        <Box>
                                                            <Select
                                                                fullWidth
                                                                multiple
                                                                native
                                                                value={rightDataFacilitySelected}
                                                                onChange={handleChangeRightDataFacility}
                                                                sx={{
                                                                    height: '248px',
                                                                    '& .MuiNativeSelect-select[multiple]': {
                                                                        overflow: 'auto',
                                                                        padding: '0px',
                                                                        height: '100%',
                                                                        borderRadius: '15px',
                                                                    },
                                                                    ' & option': {
                                                                        ':checked, :focus, :active': {
                                                                            background: 'linear-gradient(#ffff00,#ffff00)',
                                                                            boxShadow: '0 0 10px 100px #ffff00 inset !important',
                                                                            backgroundColor: '#ffff00 !important',
                                                                            color: '#000000 !important',
                                                                            WebkitTextFillColor: '#000000 !important',
                                                                        },
                                                                        ':first-of-type': {
                                                                            pt: '10px',
                                                                        },
                                                                        ':last-of-type': {
                                                                            pb: '10px',
                                                                        },
                                                                        padding: '4px 15px',
                                                                        fontSize: '16px',
                                                                    },
                                                                    '&.MuiInputBase-root': {
                                                                        fieldset: {
                                                                            border: '1px solid #666',
                                                                            borderRadius: '15px',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            border: '1px solid #666',
                                                                            borderRadius: '15px',
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                {!loadingFacilitiesInUsed ?
                                                                    facilities.filter((facilities: any) =>
                                                                        !infoSchedule.facilities.some(
                                                                            (e: any) => e.garoon_id === facilities.garoon_id,
                                                                        ),).map((item: any) => {
                                                                            return (
                                                                                <option 
                                                                                    disabled={facilitiesInUsed.length > 0 && facilitiesInUsed.indexOf(item.garoon_id) > -1}
                                                                                    key={item.id} 
                                                                                    value={item.garoon_id}
                                                                                    data-facility={JSON.stringify(item)}
                                                                                    style={facilitiesInUsed.length > 0 && facilitiesInUsed.indexOf(item.garoon_id) == -1 ? {paddingLeft: '35px'} : {}}
                                                                                    className='warning'
                                                                                >
                                                                                    {facilitiesInUsed.length > 0 ? facilitiesInUsed.indexOf(item.garoon_id) > -1 ? '⚠ ' : "" : ''}{item.name}
                                                                                </option>
                                                                            )
                                                                        })
                                                                    :
                                                                    <option style={{pointerEvents: 'none'}}>読み込み中..</option>
                                                                }
                                                            </Select>
                                                            <Box sx={{display: 'flex',mt: 0.5, pl: 2, fontSize: '12px'}}>
                                                                ⚠予定日時に空いていない施設
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        <Box>
                                                            <Box sx={styles.boxRight}>
                                                                <Box sx={{ overflow: 'auto', height: '248px' }}>
                                                                    {!loadingFacilitiesInUsed ?
                                                                        facilities
                                                                            .filter(
                                                                                (facilities: any) =>
                                                                                    !infoSchedule.facilities.some(
                                                                                        (e: any) => e.garoon_id === facilities.garoon_id,
                                                                                    ),
                                                                            )
                                                                            .map((item: any) => {
                                                                                const selected = rightFacilitySelected.some((i) => i.garoon_id === item.garoon_id)
                                                                                return (
                                                                                    <Grid
                                                                                        key={item.garoon_id}
                                                                                        sx={[
                                                                                            styles.userItem,
                                                                                            selected && {
                                                                                                backgroundColor: theme.palette.secondary.main,
                                                                                            },
                                                                                            facilitiesInUsed.length > 0 && facilitiesInUsed.indexOf(item.garoon_id) == -1 ? {paddingLeft: '35px'} : {},
                                                                                            facilitiesInUsed.length > 0 && facilitiesInUsed.indexOf(item.garoon_id) > -1 ? {color: 'light-dark(graytext, rgb(170, 170, 170))'} : {}

                                                                                        ]}
                                                                                        tabIndex={0}
                                                                                        onClick={() => handleClickFacilityItem('right', item, selected)}
                                                                                    >
                                                                                        {facilitiesInUsed.length > 0 ? facilitiesInUsed.indexOf(item.garoon_id) > -1 ? '⚠ ' : "" : ''}{item.name}
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                        :
                                                                        <Grid
                                                                            sx={[
                                                                                styles.userItem,
                                                                                {pointerEvents: 'none'}
                                                                            ]}
                                                                            tabIndex={0}
                                                                        >
                                                                            読み込み中..
                                                                        </Grid>
                                                                    }
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{display: 'flex',mt: 0.5, pl: 2, fontSize: '12px'}}>
                                                                ⚠予定日時に空いていない施設
                                                            </Box>
                                                        </Box>
                                                    
                                                    )
                                                }
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid
                            xs={12}
                            sx={{ display: 'flex', marginTop: '20px', width: '100%' }}
                            justifyContent={'space-between'}
                        >
                            <ButtonCustom sx={{fontSize: {sm: '15px', xs: '13px'}}} child={'戻る'} onClick={() => navigate(`/finalize/${sid}/step1`)} />
                            <ButtonCustom
                                child={'メール送信'}
                                onClick={() => {
                                    nextStep3()
                                }}
                                sx={{ minWidth: '164px !important', fontSize: {sm: '15px', xs: '13px'}}}
                                isSubmit
                                endIcon={<ArrowForwardIosIcon sx={{ fontSize: '14px !important' }} />}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Container>
    )
}

export default FinalizeStep2

const styles = {
    paper: {
        p: { xs: '20px', sm: '30px', md: 5 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: ' 0px 4px 120px rgba(0, 0, 0, 0.25)',
        color: '#000000',
        marginTop: '28px',
        borderRadius: '20px',
        border: '1px solid transparent',
    },
    form: {
        // mt: 2,
        // alignItems: 'center',
        // display: 'flex',
        // justifyContent: 'center',
        // alignSelf: 'center',
        // width: '100%',
        // p: { xs: '20px', sm: '30px', md: 5 },
        // background: 'rgba(255, 255, 255, 0.9)',
        // boxShadow: '0px 4px 120px rgba(0, 0, 0, 0.25)',
        // borderRadius: 5,
    },
    label: {
        lineHeight: 1.4,
        fontSize: '15px',
        height: '21px',
        margin: 'auto 0',
    },
    container: {
        alignSelf: 'center',
    },
    title: {
        fontWeight: '500',
        fontSize: '15px',
        alignItem: 'center',
        whiteSpace: 'nowrap',
        height: '21px',
        margin: 'auto 0',
    },
    adjustmentTitle: {
        pb: '20px',
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
        height: '100%',
        whiteSpace: 'nowrap',
        borderRadius: '20px',
    },
    adjustmentInfo: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '7px',
        paddingLeft: {
            xs: '0px',
            sm: '20px',
        },
    },
    boxScheduleEventMenu: {
        color: '#FFF',
        borderRadius: '10px',
        p: '0px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    boxScheduleEventMenuText: {
        fontSize: '12px',
        fontWeight: '500',
    },
    boxAddInvitee: {
        display: 'flex',
        cursor: 'pointer',
        gap: '8px',
    },
    boxAddInviteeIcon: {
        fontSize: '21px',
    },
    tableContainer: {
        pt: '20px',
    },
    table: {
        th: {
            ...tableHeaderText,
            ...tableRowAlign,
            borderBottom: '1px solid #666666',
        },
        'tbody > tr': {
            cursor: 'pointer',
        },
        td: {
            ...tableBodyText,
            ...tableRowAlign,
            borderBottom: '1px solid #56577A',
        },
        marginBottom: 0,
    },
    modalBoxWrapper: {
        maxHeight: '90vh',
        overflowY: 'auto',
        p: { xs: '20px', sm: '30px', md: 5 },
        borderRadius: '16px',
        width: '700px',
        bgcolor: '#fff',
        margin: 'auto',
        border: 'none',
        '@media (max-width: 768px)': {
            width: '90%',
        },
    },
    footerModal: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row' },
    error: { marginLeft: '7px !important', position: 'absolute' },
    boxLeft: {
        border: '1px solid #666',
        height: {
            xs: '248px',
            sm: '400px',
        },
        borderRadius: '15px',
        overflow: 'hidden',
    },
    boxRight: {
        border: '1px solid #666',
        marginTop: '20px',
        borderRadius: '15px',
        overflow: 'hidden',
    },
    arrow: (theme: any) => ({
        width: '64px',
        height: '64px',
    }),
    userItem: {
        ...inputField,
        ':first-of-type': {
            pt: '10px',
        },
        ':last-child': {
            pb: '10px',
        },
        p: '4px 15px',
        cursor: 'pointer',
    },
}
