import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { buttonStyle, buttonSubmitStyle } from './../../theme'
import ButtonCustom from './ButtonCustom'
export interface ModalConfirmProps {
    type?: string
    openModalConfirm: boolean
    isLoading: boolean
    closeModalConfirm: () => void
    onConfirm: () => void
    title: string
    message: string,
    btnLeft?: string,
    btnRight?: string,
}

export default function ModalConfirm({
    type = 'confirm',
    openModalConfirm,
    isLoading,
    closeModalConfirm,
    onConfirm,
    title,
    message,
    btnLeft = '戻る',
    btnRight = '確認',
}: ModalConfirmProps) {
    const [open, setOpen] = useState(openModalConfirm)

    useEffect(() => {
        setOpen(openModalConfirm)
    }, [openModalConfirm])

    const handleClose = () => {
        closeModalConfirm()
    }

    const handleConfirm = () => {
        onConfirm()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '20px',
                    padding: { xs: '20px', sm: '30px', md: '40px' },
                },
                '& .MuiPaper-root': {
                    bgcolor: '#FFFFFF',
                },
            }}
        >
            <DialogTitle
                sx={{
                    color: type == 'confirm' ? '#000000' : 'red',
                    padding: '0px 0px 20px 0px',
                }}
            >
                {title}
            </DialogTitle>
            <DialogContent sx={{ padding: '20px 0px' }}>
                <DialogContentText sx={{ color: '#000000' }}>{message}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', padding: '0px', paddingTop: '20px' }}>
                <ButtonCustom child={btnLeft} onClick={handleClose} />

                {type == 'confirm' && <ButtonCustom onClick={handleConfirm} child={btnRight} isSubmit disabled={isLoading} />}
            </DialogActions>
        </Dialog>
    )
}
