import {
    Box,
    Grid,
    Modal,
    Pagination,
    Typography,
    TableBody,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { formatDate, formatTargetDatePeriod } from '../../../helpers/formatDate'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import {
    selectFilterSchedules,
    selectFilterSchedulesAdmin,
    selectMetaSchedulesAdmin,
    selectSchedulesAdmin,
    topActions,
    selectLoadingSchedulesAdmin
} from '../topSlice'
import { limitCharacter } from '../../../helpers/string'
import { Order, Status } from 'src/models'
import { useNavigate } from 'react-router-dom'
import { tableHeaderText, tableRowAlign, tableBodyText, labelOfInput, labelOfInputAlign } from 'src/theme'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import { CheckCircleIcon } from '../../../components/Icons'
import ChevronDownIcon from 'src/components/Icons/ChevronDownIcon'
import { cutString, handleSortByStartTime, replaceGaroonName } from '../../../helpers/helpers'
import { selectCurrentUser } from 'src/pages/Auth/authSlice'
import LinearProgressWithLabel from 'src/components/Common/LinearProgressWithLabel'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import adjustmentApi from 'src/api/adjustmentApi'
import dayjs from 'dayjs'
import { CONSTANTS } from 'src/config/constants'
import { useSnackBar } from 'src/components/Common/SnackBarContext'

function AdminTable() {
    const dispatch = useAppDispatch()
    const filter = useAppSelector(selectFilterSchedulesAdmin)
    const schedules = useAppSelector(selectSchedulesAdmin)
    const metaSchedules = useAppSelector(selectMetaSchedulesAdmin)
    const filterSchedulesList = useAppSelector(selectFilterSchedules)
    const loadingSchedulesAdmin = useAppSelector(selectLoadingSchedulesAdmin)
    const currentUser = useAppSelector(selectCurrentUser)
    const navigate = useNavigate()
    const { showSnackBar } = useSnackBar()

    const [order, setOrder] = useState<any>({
        id: 'asc',
        subject: 'asc',
        start_time: 'asc',
        create_at: 'asc',
    })

    const [width, setWidth] = useState<number>(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        dispatch(topActions.setSchedulesAdmin([]))
    }, [])

    useEffect(() => {
        if (!localStorage.getItem('access_token') && !sessionStorage.getItem('access_token')) return;
        dispatch(topActions.fetchSchedulesAdmin(filter))
    }, [filter])

    const handleGoToFinalize = (schedule: any) => {
        navigate(`/finalize/${schedule.id}/step1`)
    }

    const formatTargetDateTimePeriod = (schedule: any) => {
        const startTimeString = schedule.start_time
        const endTimeString = schedule.end_time

        if (!startTimeString || !endTimeString) {
            if (!schedule.adjustment_start_date || !schedule.adjustment_end_date) {
                return []
            }

            const formatDate = formatTargetDatePeriod(schedule.adjustment_start_date, schedule.adjustment_end_date)
            const convertDate = formatDate.split(' ')
            if (convertDate[0].slice(0, -1) == convertDate[1]) {
                return [convertDate[0].slice(0, -1)];
            }
            return [convertDate[0].slice(0, -1), '〜' + convertDate[1]]
        }

        const startTime = new Date(startTimeString)
        const endTime = new Date(endTimeString)
        const startDateString = `${startTime.getFullYear()}/${(startTime.getMonth() + 1)
            .toString()
            }/${startTime.getDate().toString()}`

        const endDateString = `${endTime.getFullYear()}/${(endTime.getMonth() + 1)
            .toString()
            }/${endTime.getDate().toString()}`

        let formattedTimeRange = []

        if (startDateString === endDateString) {
            formattedTimeRange = [
                `${startDateString}`,
                `${startTime.getHours().toString()}:${startTime
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')} 〜 ${endTime.getHours().toString()}:${endTime
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`,
            ]
        } else {
            formattedTimeRange = [`${startDateString}`, `〜${endDateString}`]
        }
        return formattedTimeRange
    }

    const handleSortBy = (key: string) => {
        setOrder({
            ...order,
            [key]: order[key] === 'desc' ? 'asc' : 'desc',
        })

        if (key === 'start_time') {
            key = handleSortByStartTime(filter['status'])
        }

        dispatch(
            topActions.setFilterSchedulesAdmin({
                ...filter,
                orderBy: key,
                order: filter.order === Order.DESC ? Order.ASC : Order.DESC,
            }),
        )
    }

    const handleShowAllAdmin = () => {
        navigate('/schedule-list', {state: {admin_id: currentUser.id, participants: [], status: Status.ADJUSTING}})
    }

    const [progress, setProgress] = useState(0);
    useEffect(() => {
        // set progress loading
        if (loadingSchedulesAdmin) {
            setProgress(1);
            dispatch(topActions.setSchedulesAdmin([]))
            return;
        }

        let i = 1;
        let total = schedules.length > 0 ? schedules.length : 1
        const timer = setInterval(() => {
            if (i > total) {
                clearInterval(timer);
                setProgress(0);
                return;
            }
            setProgress((i / total) * 100)
            i++;
        }, 100);

        return () => clearInterval(timer);
    }, [loadingSchedulesAdmin])

    const handleCopy = (hash: string, scheduleId: number) => {
        const url = `${process.env.REACT_APP_EXTERNAL_URL}${hash}`;    
        adjustmentApi
                            .getAdjustmentTimeByScheduleId(scheduleId)
                            .then((res: any) => {
                                if (res?.success) {
                                    const data = res.data;
                                    const textToCopy = `
候補日時:
${data.map((value: any) => {
    return `${dayjs(value.start_time).format(
        `YYYY/M/D(${
            CONSTANTS.DAY_OF_WEEK[dayjs(value.start_time).day()]
        }) ${dayjs(value.start_time).format('HH:mm')}～（${
            value.duration
                ? value.duration === 1439
                    ? '終日'
                    : value.duration + '分間'
                : ''
        }）`
    )}\n`;
}).join('')} 
日程調整URL:
${url}
                                    `;
                                    navigator.clipboard.writeText(textToCopy.trim());
                                }
                            })
                            .catch((err: any) => {
                                navigator.clipboard.writeText(url.trim());
                                console.log(err)
                            })

        showSnackBar(true, `候補日と調整URLをコピーしました。`, 'success')
    };

    return (
        <Box sx={styles.container}>
            <TableContainer sx={{ overflowX: progress <= 0 ? 'auto' : 'hidden' }}>
                <Table sx={styles.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                onClick={() => handleSortBy('id')}
                                sx={{ position: 'relative', cursor: 'pointer' }}
                            >
                                No
                                <Box sx={{ position: 'absolute', top: '9px', left: '38px' }}>
                                    <OrderComponent order={order.id} orderThis={filter.orderBy === 'id'} />
                                </Box>
                            </TableCell>
                            <TableCell
                                onClick={() => handleSortBy('subject')}
                                sx={{ position: 'relative', cursor: 'pointer' }}
                            >
                                予定タイトル
                                <Box sx={{ position: 'absolute', top: '9px', left: '108px' }}>
                                    <OrderComponent order={order.subject} orderThis={filter.orderBy === 'subject'} />
                                </Box>
                            </TableCell>
                            <TableCell
                                sx={{ width: '132px', position: 'relative', cursor: 'pointer' }}
                                onClick={() => handleSortBy('start_time')}
                            >
                                調整日時
                                <Box sx={{ position: 'absolute', top: '9px', left: '78px' }}>
                                    <OrderComponent
                                        order={order.start_time}
                                        orderThis={
                                            filter.orderBy === 'start_time' ||
                                            filter.orderBy === 'adjustment_start_date'
                                        }
                                    />
                                </Box>
                            </TableCell>
                            <TableCell>参加者</TableCell>
                            <TableCell>調整者</TableCell>
                            <TableCell>招待者</TableCell>
                            <TableCell
                                onClick={() => handleSortBy('created_at')}
                                sx={{ position: 'relative', cursor: 'pointer' }}
                            >
                                登録日時
                                <Box sx={{ position: 'absolute', top: '9px', left: '78px' }}>
                                    <OrderComponent
                                        order={order.created_at}
                                        orderThis={filter.orderBy === 'created_at'}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        (() => {
                            if (progress <= 0) {
                                return (
                                    <TableBody>
                                        {
                                            (() => {
                                                if (schedules.length > 0) {
                                                    return (
                                                        schedules.map((schedule: any, index: number) => {
                                                            let internalUsers: any[] = []
                                                            let externalUsers: any[] = []
                                                            schedule.participant.map((user: any) => {
                                                                if (user.user.is_employee) {
                                                                    internalUsers.push(user)
                                                                } else {
                                                                    externalUsers.push(user)
                                                                }
                                                            })
                                                            return (
                                                                <TableRow
                                                                    key={schedule.id}
                                                                    tabIndex={0}
                                                                    hover
                                                                    sx={[
                                                                        {
                                                                            cursor:
                                                                                !schedule.is_cancelled &&
                                                                                filter.status === 'ADJUSTING'
                                                                                    ? 'pointer'
                                                                                    : 'unset',
                                                                            transition: '0.25s linear',
                                                                        },
                                                                    ]}
                                                                    onClick={() => {
                                                                        if (
                                                                            !schedule.is_cancelled &&
                                                                            filter.status === 'ADJUSTING'
                                                                        ) {
                                                                            handleGoToFinalize(schedule)
                                                                        }
                                                                    }}
                                                                >
                                                                    <TableCell>{schedule.id}</TableCell>
                                                                    <TableCell sx={styles.subjectCell}>
                                                                        <Box sx={styles.overlayItem}>
                                                                            <Typography
                                                                                sx={{
                                                                                    textDecoration:
                                                                                        !schedule.is_cancelled &&
                                                                                        filter.status === 'ADJUSTING'
                                                                                            ? 'underline'
                                                                                            : 'none',
                                                                                }}
                                                                            >
                                                                                {schedule.subject}
                                                                            </Typography>
                                                                        </Box>
                                                                        {schedule.hash && (
                                                                                <Box sx={[styles.overlayItem, styles.externalUrl]}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        handleCopy(schedule.hash, schedule.id);
                                                                                    }}
                                                                                >
                                                                                    <Typography sx={{ fontSize: '14px' }}>{'候補日と調整URLをコピー'}</Typography>
                                                                                    <IconButton size="small">
                                                                                        <ContentCopyRoundedIcon sx={styles.externalUrlIcon} />
                                                                                    </IconButton>
                                                                                </Box>
                                                                            )}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {formatTargetDateTimePeriod(schedule).map((item) => (
                                                                            <Grid sx={{ whiteSpace: 'nowrap' }} key={item}>
                                                                                {item}
                                                                            </Grid>
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid container direction="column">
                                                                            {internalUsers.length > 0 &&
                                                                                internalUsers.map((internalUser: any, index: number) => (
                                                                                    <Typography
                                                                                        key={internalUser.user.id}
                                                                                        sx={{ whiteSpace: 'nowrap' }}
                                                                                    >
                                                                                        {limitCharacter(replaceGaroonName(internalUser.user, width))}
                                                                                    </Typography>
                                                                                ))}
                                                                        </Grid>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography sx={{ whiteSpace: 'nowrap' }}>
                                                                            {limitCharacter(replaceGaroonName(schedule?.user, width))}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid>
                                                                            {externalUsers.length > 0 ? (
                                                                                externalUsers.map((externalUser: any, index: number) => (
                                                                                    <Typography
                                                                                        sx={{
                                                                                            display: 'flex',
                                                                                            direction: 'row',
                                                                                            position: 'relative',
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        key={`${externalUser.user_id}${index}`}
                                                                                        noWrap
                                                                                        component={'div'}
                                                                                    >
                                                                                        <Grid item>{cutString(externalUser.user.name)} </Grid>
                                                                                        {externalUser.is_confirmed && (
                                                                                            <Box sx={{ ml: '10px', mt: '3px' }}>
                                                                                                <CheckCircleIcon />
                                                                                            </Box>
                                                                                        )}
                                                                                    </Typography>
                                                                                ))
                                                                            ) : (
                                                                                <Typography>ー</Typography>
                                                                            )}
                                                                        </Grid>
                                                                    </TableCell>
                                                                    <TableCell>{formatDate(new Date(schedule.created_at))}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    )
                                                } else {
                                                    return (
                                                        <TableRow>
                                                            <TableCell sx={{borderBottom: 'none !important'}} colSpan={7}>
                                                                <Typography>調整中の予定はありません。</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            })()
                                        }
                                    </TableBody>
                                )
                            }
                        })()
                    }
                </Table>
            </TableContainer>
            {
                (() => {
                    if (progress > 0) {
                        return (
                            <Box sx={{ width: '200px', margin: '0 auto' }}>
                                <LinearProgressWithLabel value={progress} />
                            </Box>
                        )
                    }
                })()
            }
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: "24px" }}>
                <ButtonCustom child={'全て見る'} onClick={handleShowAllAdmin} />
                {
                    (() => {
                        let countPage = Math.floor((metaSchedules - 1) / filter.limit) + 1
                        if (schedules.length > 0 && progress <=0 && countPage > 1) {
                            return (
                                <Pagination
                                    count={countPage}
                                    page={filter.page}
                                    showFirstButton={false}
                                    showLastButton={false}
                                    hidePrevButton={filter.page === 1}
                                    hideNextButton={filter.page === countPage}
                                    onChange={(event, value) => {
                                        dispatch(
                                            topActions.setFilterSchedulesAdmin({
                                                ...filter,
                                                page: value,
                                            }),
                                        )
                                    }}
                                    siblingCount={0}
                                    boundaryCount={width < 450 ? 0 : 1}
                                    sx={styles.pagination}
                                />
                            )
                        }
                    })()
                }
            </Box>
        </Box>
    )
}

const OrderComponent = ({ order, orderThis }: any) => {
    return (
        <Box
            sx={{
                transform: order === Order.DESC ? 'rotate(180deg)' : 'rotate(0)',
            }}
        >
            <ChevronDownIcon stroke={orderThis ? '#000' : '#A0AEC0'} />
        </Box>
    )
}

export default AdminTable

const styles = {
    container: { width: '100%', display: 'table', tableLayout: 'fixed' },
    table: {
        marginBottom: 0,
        th: {
            ...tableHeaderText,
            ...tableRowAlign,
            borderBottom: '1px solid #666666',
        },
        tr: {
            verticalAlign: 'top',
        },
        td: {
            ...tableBodyText,
            ...tableRowAlign,
            borderBottom: '1px solid #56577A',
        },
    },
    overlay: {
        cursor: 'pointer',
    },
    overlayItem: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: '5px',
    },
    cancelBadge: {
        border: '1px solid red',
        borderRadius: '3px',
        color: 'red',
        padding: '2px',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        ml: '4px',
    },
    pagination: {
        '& ul': {
            justifyContent: 'end',
        },
        '& li': {
            width: '36px',
            height: '36px',
            mr: '5px',
        },
        'button:hover': {
            border: 'none',
            bgcolor: '#FFFF00 !important',
        },
        '.Mui-selected': {
            border: 'none',
            color: '#000',
            bgcolor: '#FFFF00 !important',
        },
        button: {
            width: '100%',
            height: '100%',
            border: '1px solid #666666',
            borderRadius: '50%',
            color: '#000000',
        },
        mt: '6px',
    },
    externalUrl: {
        whiteSpace: 'nowrap',
        border: '1px solid',
        paddingLeft: '5px',
        borderRadius: '4px',
        height: '26px',
        maxWidth: '204px',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    externalUrlIcon: {
        fontSize: '18px',
        color: '#000000',
    },
    subjectCell: {
        minWidth: '240px',
    }
}
