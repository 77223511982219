import { createSlice } from '@reduxjs/toolkit'
import { Meta, Order } from '../../models'
import dayjs from 'dayjs'
import { RootState } from 'src/app/store'

export interface FilterCondition {
    page?: number
    limit?: number
    order?: Order
    orderBy?: string
}

export interface MemberIDInfo {
    user_id?: number
}

export interface GroupState {
    //Group List
    loadingFetchMyGroup: boolean
    myGroupList: any[]
    metaMyGroup: Meta
    filterMyGroup: FilterCondition

    loadingFetchGroupById: boolean
    infoGroup: {
        name: string
        organizationUser: any[]
    }

    //Group Add
    loadingCreateMyGroup: boolean
    myGroupCreatedData: {}
    dataCreateGroup: {
        name: string
        user_ids: MemberIDInfo[]
    }

    //Group Edit
    loadingUpdateGroup: boolean

    groupNewUpdate: any

    loadingMyGroup: boolean

    // Error handling
    showAlert: boolean
    alertMessage: string
    alertSeverity: string
}

const initialState: GroupState = {
    metaMyGroup: {
        pageIndex: 1,
        pageSize: 10,
        totalItems: 10,
        totalPage: 4,
        hasPreviousPage: false,
        hasNextPage: false,
    },
    loadingFetchMyGroup: true,
    myGroupList: [],
    filterMyGroup: {
        page: 1,
        limit: 1000,
        order: Order.ASC,
        orderBy: 'id',
    },


    loadingFetchGroupById: true,
    infoGroup: {
        name: '',
        organizationUser: [],
    },

    loadingCreateMyGroup: true,
    myGroupCreatedData: {},
    dataCreateGroup: {
        name: '',
        user_ids: [],
    },

    loadingUpdateGroup: true,
    groupNewUpdate: undefined,

    loadingMyGroup: false,

    showAlert: false,
    alertMessage: '',
    alertSeverity: 'success',
}

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        fetchMyGroup(state, action) {
            state.loadingFetchMyGroup = true
        },
        fetchMyGroupSuccess(state, action) {
            state.myGroupList = action.payload.data
            state.loadingFetchMyGroup = false
        },
        setFilter(state, action) {
            state.filterMyGroup = action.payload
        },
        setGroupList(state, action) {
            state.myGroupList = action.payload
        },

        fetchGroupById(state, action) {
            state.loadingFetchGroupById = true
        },
        fetchGroupByIdSuccess(state, action) {
            state.infoGroup = {
                ...state.infoGroup,
                name: action.payload.name,
                organizationUser: action.payload.organizationUser,
            }
            state.loadingFetchGroupById = false
        },
        setInfoGroup(state, action) {
            state.infoGroup = action.payload
        },

        createMyGroup(state, action) {
            state.myGroupList = action.payload
            state.loadingCreateMyGroup = true
            state.showAlert = false
            state.alertMessage = ''
            state.loadingMyGroup = true
        },
        createGroupSuccess(state, action) {
            state.myGroupCreatedData = action.payload.data
            state.groupNewUpdate = action.payload.data
            state.loadingCreateMyGroup = false
            state.loadingMyGroup = false
        },
        createGroupFailed(state) {
            state.loadingCreateMyGroup = false
            state.showAlert = true;
            state.alertMessage = 'グループ名が他のグループ名と重複しています。';
            state.alertSeverity = 'error';
            state.loadingMyGroup = false
        },
        setLoadingCreateMyGroup(state, action) {
            state.loadingCreateMyGroup = action.payload
        },
        setLoadingMyGroup(state, action) {
            state.loadingMyGroup = action.payload
        },
        setDetailGroup(state, action) {
            state.dataCreateGroup = action.payload
        },

        updateMyGroup(state, action) {
            state.loadingUpdateGroup = true
            state.showAlert = false;
            state.alertMessage = '';
            state.loadingMyGroup = true
        },
        updateMyGroupSuccess(state, action) {
            state.groupNewUpdate = action.payload.data
            state.loadingUpdateGroup = false
            state.loadingMyGroup = false
        },
        setMyGroupNewUpdate(state) {
            state.groupNewUpdate = undefined
        },
        updateMyGroupFailed(state) {
            state.loadingUpdateGroup = false
            state.showAlert = true;
            state.alertMessage = 'グループ名が他のグループ名と重複しています。';
            state.alertSeverity = 'error';
            state.loadingMyGroup = false
        },
        setLoadingUpdateGroup(state, action) {
            state.loadingUpdateGroup = action.payload
        },
        cancelHideAlert(state) {
            state.showAlert = false
            state.alertMessage = ''
        }
    },
})
// actions
export const groupActions = groupSlice.actions

// selectors
export const selectMyGroups = (state: RootState) => state.group.myGroupList
export const selectLoadingFetchMyGroup = (state: RootState) => state.group.loadingFetchMyGroup
export const selectFilterMyGroup = (state: RootState) => state.group.filterMyGroup
export const selectDetailGroup = (state: any) => state.group.dataCreateGroup

export const selectLoadingFetchGroupById = (state: any) => state.group.loadingFetchGroupById
export const selectInfoGroup = (state: any) => state.group.infoGroup

export const selectLoadingCreateMyGroup = (state: RootState) => state.group.loadingCreateMyGroup
export const selectMyGroupCreatedData = (state: RootState) => state.group.myGroupCreatedData
export const selectGroupNewUpdate = (state: RootState) => state.group.groupNewUpdate

export const selectLoadingUpdateGroup = (state: any) => state.group.loadingUpdateGroup
export const selectLoadingMyGroup = (state: any) => state.group.loadingMyGroup

export const selectAlertMessage = (state: RootState) => state.group.alertMessage
export const selectAlertSeverity = (state: RootState) => state.group.alertSeverity
export const selectShowAlert = (state: RootState) => state.group.showAlert

// reducer
const groupReducer = groupSlice.reducer
export default groupReducer
